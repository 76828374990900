import { Component, OnInit } from '@angular/core';
import { PitComplianceService } from '../../pit-compliance.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewPreviousTradingComponent } from '../view-previous-trading/view-previous-trading.component';
import { DeletePreviousTradingComponent } from '../delete-previous-trading/delete-previous-trading.component';
import { ActivatedRoute } from '@angular/router';
import { PreFormCComponentComponent } from '../pre-form-ccomponent/pre-form-ccomponent.component';
import { TradingAlertPopupComponent } from '../trading-alert-popup/trading-alert-popup.component';

@Component({
  selector: 'app-pre-clearance-trading',
  templateUrl: './pre-clearance-trading.component.html',
  styleUrls: ['./pre-clearance-trading.component.scss']
})
export class PreClearanceTradingComponent implements OnInit {
 page = 1;
  pageSize = 10;
  filtered = [];
  listPreviousPreClear = [];
  filterString = "";
  pcId: any;
  PcEditId: any;
  precleranceEditView: any;
  alertpopup = false;
  pdfSelect: string;
  verifiedpreClearanceList: any;
  constructor(private pitService: PitComplianceService, private modalService: NgbModal, private activateRouter:ActivatedRoute) { }

  ngOnInit(): void {
    this.activateRouter.params.subscribe(data =>{
      this.pcId = {'id' : data['id']}      
    })
    if(this.pcId['id'] !==''){
      this.pitService.preClearanceView(this.pcId)
      .toPromise().then(result =>{ 
        this.PcEditId = result.data[0].id;   
        this.precleranceEditView = result.data[0];
        let application_for = result.data[0].application_for;

        if(application_for == "Self"){
            this.pdfSelect = 'Form C';
        }else{
          this.pdfSelect = 'Form D';
        }
        if(this.precleranceEditView.transactionisproposed > 1000000){
          this.alertpopup = true;
          // const modalRef = this.modalService.open(TradingAlertPopupComponent,
          //   {
          //     scrollable: true,
          //     windowClass: 'myCustomModalClass',
          //     // keyboard: false,
          //     // backdrop: 'static'
          //   });
          // modalRef.componentInstance.preClearancTradingAlert = this.precleranceEditView.transactionisproposed;
          // modalRef.componentInstance.title = 'Preclearance Mail Template';
          // modalRef.result.then(
          //   (result) => {
          //     console.log(result);
          //   }, (reason) => {
          //     console.log(reason);
          //   });
        }else{
          this.alertpopup = false;
        }



        this.pitService.listPreviousPreClear(null).subscribe(
          res => {
                let listPreviousPreClear = res['data'];
              let remainingtradingpreclearance = [];
              this.listPreviousPreClear = [];
                listPreviousPreClear.map(data =>{
                  if(data.preclearance_id === this.PcEditId){              
                  
                  this.listPreviousPreClear.push(data);
                  }else{
                    remainingtradingpreclearance.push(data);                   
                  }
                  this.filtered = this.listPreviousPreClear;
                })
                

                
          });
          let verifiedPreclearance = {'id': this.PcEditId}
          this.pitService.verifiedpreClearanceList(verifiedPreclearance).subscribe(
            res => {
              this.verifiedpreClearanceList = res.data;
            
            }); 
       });
    }
  }
  onFilterChange() {
    this.filtered = this.listPreviousPreClear?.filter(res =>{      
     return res.name.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || res.no_of_securirities.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || res.bought_sold_subscribed.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || res.dp_client_id.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) ||  res.date_of_transaction.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) ||  res.price.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) ||  res.created_by.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase())
    }) 
   
  }

  isMatch(item) {
    if (item instanceof Object) {
      return Object.keys(item).some((k) => this.isMatch(item[k]));
    } else {
      return item?.toString().indexOf(this.filterString) > -1
    }
  }
  openModal(id:any) {   
    if (id) {
      let preId = {'id' : id};
      this.pitService.viewPreviousPreClear(preId).subscribe(
        ({data}) =>{
        const modalRef = this.modalService.open(ViewPreviousTradingComponent,
          {
            scrollable: true,
            windowClass: 'myCustomModalClass',
            // keyboard: false,
            // backdrop: 'static'
          });
        modalRef.componentInstance.viewPreviousTradingList = data[0];
        modalRef.result.then(
          (result) => {
            console.log(result);
          }, (reason) => {
            console.log(reason);
          });
      })
    }    
    
  } 
  openModalDelete(id:any) {
    if (id) {
      let preId = {'id' : id};
      this.pitService.viewPreviousPreClear(preId).subscribe(
        ({data}) =>{
		const modalRef = this.modalService.open(DeletePreviousTradingComponent,
    {
      scrollable: true,
      windowClass: 'myCustomModalClass',
      // keyboard: false,
      // backdrop: 'static'
    });
		modalRef.componentInstance.deletePreviousPreClearanceList = data[0];
    modalRef.result.then((result) => {
   ;
    }, (reason) => {
    });
  })
}
	}
  openModalFormC(id:any) {   
  
    if (id) {
      let preId = {'id' : id};
      this.pitService.viewPreviousPreClear(preId).subscribe(
      ({data}) =>{
      const modalRef = this.modalService.open(PreFormCComponentComponent,
        {
          scrollable: true,
          windowClass: 'myCustomModalClass',
          // keyboard: false,
          // backdrop: 'static'
        });
      modalRef.componentInstance.pdfTradingFormList = data[0];
      modalRef.componentInstance.precleranceEditView = this.precleranceEditView;
      modalRef.result.then(
        (result) => {
          console.log(result);
        }, (reason) => {
          console.log(reason);
        });
    })
    }    
    
    } 

}
