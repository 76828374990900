import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyService } from 'src/app/master/company/company.service';

@Component({
  selector: 'app-preclearance-desclaimer',
  templateUrl: './preclearance-desclaimer.component.html',
  styleUrls: ['./preclearance-desclaimer.component.scss']
})
export class PreclearanceDesclaimerComponent implements OnInit {
  @Input() preClearancTradingAlert;
  @Input() title;
  myDate: Date;
  predaycounts: any;
  //predaycounts: any;
  emptyMessage = true;
  countMessage = false;
  constructor(public activeModal: NgbActiveModal, public router: Router, private companyService: CompanyService) {
    this.myDate = new Date();
   }

  ngOnInit(): void {

    if(this.title == 'Empty'){
        this.emptyMessage = true;
        this.countMessage = false;
    }else{
      this.emptyMessage = false;
      this.countMessage = true;
    }
      

  }
  closeModal(sendData) {
    if(this.title == 'Empty'){
      this.router.navigate(['pit-compliance/list-pre-clearance']);
      this.activeModal.close(sendData);
    }else{
      if(sendData == 'dismiss'){
        this.activeModal.close(sendData);
      }else{
        this.router.navigate(['pit-compliance/list-pre-clearance']);
        this.activeModal.close(sendData);

      }
    }
  }

}
