import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PitComplianceService {
  baseUrl = environment.apiUrl;

  constructor(private http:HttpClient) { }

  preClearanceCreate(userObj:any):Observable<any> {
  
    return this.http.post(this.baseUrl + 'preClearance/create', userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
    })
  }
 
  preClearanceUpdate(userObj:any):Observable<any> {
  
    return this.http.post(this.baseUrl + 'preClearance/update', userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
    })
  }
  preClearanceduplicate(userObj:any):Observable<any> {
  
    return this.http.post(this.baseUrl + 'preClearance/preclearanceduplicate', userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
    })
  }
  updatenotradetransaction(userObj:any):Observable<any> {
  
    return this.http.post(this.baseUrl + 'preClearance/notradeupdate', userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
    })
  }
  preClearanceList(userObj:any):Observable<any> {
  
    return this.http.post(this.baseUrl + 'preClearance/list', userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
    })
  }
  verifiedpreClearanceList(userObj:any):Observable<any> {
  
    return this.http.post(this.baseUrl + 'preClearance/verifiedpreClearanceList', userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
    })
  }
  preClearanceView(userObj:any):Observable<any> {
  
    return this.http.post(this.baseUrl + 'preClearance/edit',userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
    })
  }
  preClearancedelete(userObj:any):Observable<any> {
  
    return this.http.post(this.baseUrl + 'preClearance/delete', userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
    })
  }
  preClearanceSatusApproval(userObj:any):Observable<any> {
  
    return this.http.post(this.baseUrl + 'preClearance/statusapproval', userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
    })
  }
  createPreviousPreClear(userObj:any):Observable<any> {   
    return this.http.post(this.baseUrl + 'preClearance/previousTradesPreClearance',userObj, {  
     headers: {'Authorization': sessionStorage.getItem('access_token')},
  
   });
  }
  listPreviousPreClear(userObj:any):Observable<any> {   
    return this.http.post(this.baseUrl + 'preClearance/previousTradesPreClearancelist',userObj, {  
     headers: {'Authorization': sessionStorage.getItem('access_token')},
  
   });
  }
  countPreviousPreClear(userObj:any):Observable<any> {   
    return this.http.post(this.baseUrl + 'preClearance/previousTradesPreClearancecount',userObj, {  
     headers: {'Authorization': sessionStorage.getItem('access_token')},
  
   });
  }
  viewPreviousPreClear(userObj:any):Observable<any> {   
    return this.http.post(this.baseUrl + 'preClearance/previousTradesPreClearanceedit',userObj, {  
     headers: {'Authorization': sessionStorage.getItem('access_token')},
  
   });
  }
  deletePreviousPreClear(userObj:any):Observable<any> {
  
    return this.http.post(this.baseUrl + 'preClearance/previousTradesPreClearancedelete', userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
    })
  }
  preClearanceSharesValuesList(userObj:any):Observable<any> {
  
    return this.http.post(this.baseUrl + 'preClearance/preclearancesharesvalueslist', userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
    })
  }
  //trading window closure
  tradingWindowClosureCreate(userObj:any):Observable<any> {
  
    return this.http.post(this.baseUrl + 'tradingWindow/create', userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
    })
  }
  tradingWindowClosureEdit(userObj:any):Observable<any> {
  
    return this.http.post(this.baseUrl + 'tradingWindow/update', userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
    })
  }
  tradingWindowClosureList(userObj:any):Observable<any> {
  
    return this.http.post(this.baseUrl + 'tradingWindow/list', userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
    })
  }
  tradingWindowClosureView(userObj:any):Observable<any> {
  
    return this.http.post(this.baseUrl + 'tradingWindow/edit', userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
    })
  }
  tradingWindowClosuredelete(userObj:any):Observable<any> {
  
    return this.http.post(this.baseUrl + 'tradingWindow/delete', userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
    })
  }
  tradingWindowClosureMailler(userObj:any):Observable<any> {
  
    return this.http.post(this.baseUrl + 'tradingWindow/tradingmail', userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
    })
  }
//Excel report
  sendJSONToServer(userObj:any) {
   
     return this.http.post(this.baseUrl + 'tradingWindow/benpassreport',userObj, {  
      headers: {'Authorization': sessionStorage.getItem('access_token')},
   
    });
  }
  comparisonbenpass(userObj:any) {   
    return this.http.post(this.baseUrl + 'tradingWindow/comparisonbenpass',userObj, {  
     headers: {'Authorization': sessionStorage.getItem('access_token')},
  
   });
   
 }
  benposTradesMail(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "tradingWindow/benposTradesMail",userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')}
  })
}
 //benpos Date list
 benposdatelist(userObj:any) {   
  return this.http.post(this.baseUrl + 'tradingWindow/benposdatelist',userObj, {  
   headers: {'Authorization': sessionStorage.getItem('access_token')},

 });
}
//formC and FormD
createFormCandD(userObj:any):Observable<any> {   
  return this.http.post(this.baseUrl + 'tradingWindow/tradingformcreate',userObj, {  
   headers: {'Authorization': sessionStorage.getItem('access_token')},

 });
}

listFormCandD(userObj:any):Observable<any> {   
  return this.http.post(this.baseUrl + 'tradingWindow/tradingformlist',userObj, {  
   headers: {'Authorization': sessionStorage.getItem('access_token')},

 });
}
viewFormCandD(userObj:any):Observable<any> {   
  return this.http.post(this.baseUrl + 'tradingWindow/tradingformedit', userObj, {  
   headers: {'Authorization': sessionStorage.getItem('access_token')},

 });
}
updateFormCandD(userObj:any):Observable<any> {   
  return this.http.post(this.baseUrl + 'tradingWindow/tradingformupdate',userObj, {  
   headers: {'Authorization': sessionStorage.getItem('access_token')},

 });
}
deleteFormCandD(userObj:any):Observable<any> {   
  return this.http.post(this.baseUrl + 'tradingWindow/tradingformdelete',userObj, {  
   headers: {'Authorization': sessionStorage.getItem('access_token')},

 });
}
preclearancemailconfig(userObj:any):Observable<any> {  
  return this.http.post(this.baseUrl + 'preClearance/preclearancemailconfig', userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
  })
}
statusapprovalmailconfig(userObj:any):Observable<any> {  
  return this.http.post(this.baseUrl + 'preClearance/statusapprovalmailconfig', userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
  })
}
previousmailconfig(userObj:any):Observable<any> {  
  return this.http.post(this.baseUrl + 'preClearance/previousmailconfig', userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
  })
}
notrademailconfig(userObj:any):Observable<any> {  
  return this.http.post(this.baseUrl + 'preClearance/notrademailconfig', userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
  })
}
companypreClearanceshares(userObj:any):Observable<any> {  
  return this.http.post(this.baseUrl + 'preClearance/companypreClearanceshares', userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
  })
}
dpInsiderrecipient(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "tradingWindow/bothlist", userObj, {
   headers: {'Authorization': sessionStorage.getItem('access_token')}
 })
}
}
