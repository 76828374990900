<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 ">
            <div class="card">
                <div class="card-header">
                    <h5>Add New Trading Preclearance</h5>
                    <a [routerLink]="['/pit-compliance/trading-pre-clearance', PcEditId ]"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                
                </div>
                <div class="card-body">        
                    <!-- *ngIf="tradingAllowed"             -->
                    <div class="classFormC" >         
                        <form [formGroup]="previouspreClearanceForm" class="needs-validation" (ngSubmit)="onSubmit()">
                            <div class="row">   
                                <div class="col-md-10">
                                    <div class="row mb-3 row1">
                                        <div class="col-md-5">
                                            <label for="name">Name of holder </label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-group">                    
                                                <div class="form-group">                    
                                                    <input type="text" class="form-control" id="name" placeholder="Enter the Name of holder" disabled autocomplete="off" formControlName="name" [(ngModel)]="dpNameFilter"
                                                    required [ngClass]="{ 'is-invalid': (submitted || previouspreClearanceForm?.controls.name.touched) && previouspreClearanceForm.controls.name.errors }"> 
                                                    <div *ngIf="(submitted || previouspreClearanceForm.controls.name.touched) && previouspreClearanceForm.controls.name.errors?.required"
                                                        class="text text-danger">
                                                        Name of holder is required.
                                                    </div> 
                                                </div>  
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-10">
                                    <div class="row mb-3 row1">
                                        <div class="col-md-5">
                                            <label for="no_of_securirities">No of securities dealt with </label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-group">                    
                                                <div class="form-group">                    
                                                    <input type="text" class="form-control" id="no_of_securirities" placeholder="Enter the No of securities dealt" autocomplete="off" formControlName="no_of_securirities" 
                                                    required [ngClass]="{ 'is-invalid': (submitted || previouspreClearanceForm?.controls.no_of_securirities.touched) && previouspreClearanceForm.controls.no_of_securirities.errors }"> 
                                                    <div *ngIf="(submitted || previouspreClearanceForm.controls.no_of_securirities.touched) && previouspreClearanceForm.controls.no_of_securirities.errors?.required"
                                                        class="text text-danger">
                                                        No of securities dealt with is required.
                                                    </div> 
                                                    <div class="text text-danger" *ngIf="previouspreClearanceForm.controls.no_of_securirities.errors?.pattern">Please, Enter Numbers only.</div>
                                                </div>  
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-10">
                                    <div class="row mb-3 row1">
                                        <div class="col-md-5">
                                            <label for="price">Date of transaction</label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-group">                    
                                                <div class="form-group">                    
                                                    <input type="date" class="form-control" id="price" placeholder="DD-MM-YYYY" autocomplete="off" formControlName="date_of_transaction" 
                                                    required [ngClass]="{ 'is-invalid': (submitted || previouspreClearanceForm?.controls.date_of_transaction.touched) && previouspreClearanceForm.controls.date_of_transaction.errors }"> 
                                                    <div *ngIf="(submitted || previouspreClearanceForm.controls.date_of_transaction.touched) && previouspreClearanceForm.controls.date_of_transaction.errors?.required"
                                                        class="text text-danger">
                                                        Date of transaction is required.
                                                    </div> 
                                                </div>  
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-10">
                                    <div class="row mb-3 row1">
                                        <div class="col-md-5">
                                            <label for="bought">Sell/ Buy / Subscription</label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-group">                    
                                                <div class="form-group">                    
                                                    <select class="form-select" class="form-control form-select" id="bought_sold_subscribed" disabled [(ngModel)]="nature_of_proposed" formControlName="bought_sold_subscribed" required [ngClass]="{ 'is-invalid': (submitted || previouspreClearanceForm?.controls.bought_sold_subscribed.touched) && previouspreClearanceForm.controls.bought_sold_subscribed.errors }">
                                                        <option value="" disabled selected>--Choose One --</option>
                                                        <option value="Sell (disposal of shares)">Sell (disposal of shares)</option>
                                                        <option value="Buy (Acquisition of shares)">Buy (Acquisition of shares)</option>                                    
                                                        <option value="Subscription (Allotment)">Subscription (Allotment)</option>    
                                                    </select> 
                                                    <div *ngIf="(submitted || previouspreClearanceForm.controls.bought_sold_subscribed.touched) && previouspreClearanceForm.controls.bought_sold_subscribed.errors?.required"
                                                        class="text text-danger">
                                                        Bought/ sold/ subscribed is required.
                                                    </div> 
                                                </div>  
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-10">
                                    <div class="row mb-3 row1">
                                        <div class="col-md-5">
                                            <label for="dpid">DP/ Client ID</label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-group">                    
                                                <div class="form-group">                    
                                                    <input type="text" class="form-control" id="dp_client_id" [(ngModel)]="dp_client_id" disabled placeholder="Enter the DP/ Client ID/ Folio No" autocomplete="off" formControlName="dp_client_id" 
                                                    required [ngClass]="{ 'is-invalid': (submitted || previouspreClearanceForm?.controls.no_of_securirities.touched) && previouspreClearanceForm.controls.no_of_securirities.errors }"> 
                                                    <div *ngIf="(submitted || previouspreClearanceForm.controls.no_of_securirities.touched) && previouspreClearanceForm.controls.no_of_securirities.errors?.required"
                                                        class="text text-danger">
                                                        DP/ Client ID is required.
                                                    </div> 
                                                </div>  
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-10">
                                    <div class="row mb-3 row1">
                                        <div class="col-md-5">
                                            <label for="price">Price in Rs. </label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-group">                    
                                                <div class="form-group">                    
                                                    <input type="text" class="form-control" id="price" placeholder="Enter the price" autocomplete="off" formControlName="price" 
                                                    required [ngClass]="{ 'is-invalid': (submitted || previouspreClearanceForm?.controls.price.touched) && previouspreClearanceForm.controls.price.errors }"> 
                                                    <div *ngIf="(submitted || previouspreClearanceForm.controls.price.touched) && previouspreClearanceForm.controls.price.errors?.required"
                                                        class="text text-danger">
                                                        Price is required.
                                                    </div> 
                                                    <div class="text text-danger" *ngIf="previouspreClearanceForm.controls.price.errors?.pattern">Please, Enter Numbers only.</div>
                                                </div>  
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-10">
                                    <div class="row mb-3 row1">
                                        <div class="col-md-5">
                                            <label for="name">Place from which this disclosure is being Made</label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-group">                    
                                                <div class="form-group">                    
                                                    <input type="text" class="form-control" id="name" placeholder="Place from which this disclosure is being Made" autocomplete="off" formControlName="tradingplace"
                                                    required [ngClass]="{ 'is-invalid': (submitted || previouspreClearanceForm?.controls.tradingplace.touched) && previouspreClearanceForm.controls.tradingplace.errors }"> 
                                                    <div *ngIf="(submitted || previouspreClearanceForm.controls.tradingplace.touched) && previouspreClearanceForm.controls.tradingplace.errors?.required"
                                                        class="text text-danger">
                                                        Place from which this disclosure is being Made is required.
                                                    </div> 
                                                </div>  
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>

                                <div class="submit-btn">
                                    <button class="btn btn-primary" type="submit" [disabled]="isLoading"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>   
                                    <button class="btn btn-warning" type="btn" (click)="resetForm()" >Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- <div *ngIf="tradingNotAllowed" class="tradingNotAllowed">
                        <p>Trading Transaction Date closed. You Can not Trading Transaction.</p>
                    </div> -->
                </div>
            </div>                       
        </div>
    </div>
</div>
<!-- Container-fluid Ends -->