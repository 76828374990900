import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { AddProjectComponent } from './project/add-project/add-project.component';
import { EditProjectComponent } from './project/edit-project/edit-project.component';
import { ListProjectComponent } from './project/list-project/list-project.component';
import { DeleteProjectComponent } from './project/delete-project/delete-project.component';
import { AddCompanyComponent } from './company/add-company/add-company.component';
import { EditCompanyComponent } from './company/edit-company/edit-company.component';
import { DeleteCompanyComponent } from './company/delete-company/delete-company.component';
import { RolePermissionComponent } from './role-permission/role-permission/role-permission.component';
import { PermissionListComponent } from './role-permission/permission-list/permission-list.component';
import { AddRolesComponent } from './role-permission/add-roles/add-roles.component';
import { DeleteRolesComponent } from './role-permission/delete-roles/delete-roles.component';
import { EditRolesComponent } from './role-permission/edit-roles/edit-roles.component';
import { ViewProjectComponent } from './project/view-project/view-project.component';
 import { ActivityLogComponent } from './activity-log/activity-log.component';
import { CompanyListComponent } from './company/company-list/company-list.component';
import { CompanyViewComponent } from './company/company-view/company-view.component';
import { UpsiActivityLogComponent } from './view-activity-logs/upsi-activity-log/upsi-activity-log.component';
import { InsiderActivityLogComponent } from './view-activity-logs/insider-activity-log/insider-activity-log.component';
import { DpActivityLogComponent } from './view-activity-logs/dp-activity-log/dp-activity-log.component';
import { AddMailTemplateComponent } from './mail-template/add-mail-template/add-mail-template.component';
import { ListMailTemplateComponent } from './mail-template/list-mail-template/list-mail-template.component';
import { EditMailTemplateComponent } from './mail-template/edit-mail-template/edit-mail-template.component';
import { DeleteMailTempateComponent } from './mail-template/delete-mail-tempate/delete-mail-tempate.component';
import { ViewMailTemplateComponent } from './mail-template/view-mail-template/view-mail-template.component';
import { DeletedProjectModuleComponent } from './project/deleted-project-module/deleted-project-module.component';
import { AddCompanyrulesComponent } from './company-rules/add-companyrules/add-companyrules.component';
import { ListedCompanyComponent } from './list-company/listed-company/listed-company.component';
import { ListCompanyrulesComponent } from './company-rules/list-companyrules/list-companyrules.component';
import { EditCompanyRulesComponent } from './company-rules/edit-company-rules/edit-company-rules.component';
import { AddMailSettingComponent } from './mail-setting/add-mail-setting/add-mail-setting.component';
import { UserLogComponent } from './user-log/user-log/user-log.component';
import { SystemInfoComponent } from './system-info/system-info/system-info.component';
import { UsersListComponent } from './users/users-list/users-list.component';
import { CronAnnualConfiramationComponent } from './cron-ac-msg/cron-annual-confiramation/cron-annual-confiramation.component';
import { ListActemplateComponent } from './annualconformationtemplate/list-actemplate/list-actemplate.component';
import { EditActemplateComponent } from './annualconformationtemplate/edit-actemplate/edit-actemplate.component';

const routes: Routes = [
  {
    path: '',   
    children: [ 
      {
        path: '',
        component: ListProjectComponent,
        data: {
          title: "Projects List",
          breadcrumb: "Projects/Events List"
        }
      }, 
      {
        path: 'project-list',
        component: ListProjectComponent,
        data: {
          title: "Projects List",
          breadcrumb: "Projects/Events List"
        }
      },  
      {
        path: 'project-add',
        component: AddProjectComponent,
        data: {
          title: "Project/Event",
          breadcrumb: "Projects/Events / New"
        }
      },
      {
        path: 'project-edit/:id',
        component: EditProjectComponent,
        data: {
          title: "Project",
          breadcrumb: "Projects/Events / Update"
        }
      },
      {
        path: 'project-view',
        component: ViewProjectComponent,
        data: {
          title: "Project",
          breadcrumb: "Projects/Events / View"
        }
      },
      {
        path: 'project-delete/:id',
        component: DeleteProjectComponent,
        data: {
          title: "Project",
          breadcrumb: "Projects/Events / Delete"
        }
      },
      {
        path: 'deleted-project',
        component: DeletedProjectModuleComponent,
        data: {
          title: "Deleted Projects List",
          breadcrumb: "Projects / Deleted Project/Event List"
        }
      },
      {
        path: 'company-profile',
        component: CompanyListComponent,
        data: {
          title: "Company Profile",
          breadcrumb: "Company Profile"
        }
      },  
      {
        path: 'company-add',
        component: AddCompanyComponent,
        data: {
          title: "Company Profile",
          breadcrumb: "Company Profile / New"
        }
      },
      {
        path: 'company-edit/:id',
        component: EditCompanyComponent,
        data: {
          title: "Edit Company Profile",
          breadcrumb: "Company Profile / Update"
        }
      },
      {
        path: 'company-view',
        component: CompanyViewComponent,
        data: {
          title: "Company Profile",
          breadcrumb: "Company Profile / View"
        }
      },
      {
        path: 'company-delete/:id',
        component: DeleteCompanyComponent,
        data: {
          title: "Company Profile",
          breadcrumb: "Company Profile / Delete"
        }
      },
      {
        path: 'role-permission',
        component: RolePermissionComponent,
        data: {
          title: "Roles & Permissions",
          breadcrumb: "Roles & Permissions"
        }
      },
      {
        path: 'role-permission-list',
        component: PermissionListComponent,
        data: {
          title: "Roles & Permissions",
          breadcrumb: "Roles & Permissions"
        }
      },
      {
        path: 'role-permission-add',
        component: AddRolesComponent,
        data: {
          title: "Roles & Permissions",
          breadcrumb: "Roles & Permissions / New"
        }
      },
      {
        path: 'role-permission-edit/:id',
        component: EditRolesComponent,
        data: {
          title: "Role & Permission",
          breadcrumb: "Roles & Permissions / Edit"
        }
      },
      {
        path: 'role-permission-delete',
        component: DeleteRolesComponent,
        data: {
          title: "Role & Permission",
          breadcrumb: "Roles & Permissions / Delete"
        }
      },
      {
        path: 'activity-log',
        component: ActivityLogComponent,
        data: {
          title: "Change Log",
          breadcrumb: "Change Logs"
        }
      },
      {
        path: 'upsi-view-activity-log/:id',
        component: UpsiActivityLogComponent,
        data: {
          title: " Activity Log",
          breadcrumb: "UPSI Activity Log"
        }
      },
      {
        path: 'insider-view-activity-log/:id',
        component: InsiderActivityLogComponent,
        data: {
          title: " Activity Log",
          breadcrumb: "Insider Activity Log"
        }
      },
      {
        path: 'dp-view-activity-log/:id',
        component: DpActivityLogComponent,
        data: {
          title: " Activity Log",
          breadcrumb: "DP Activity Log"
        }
      },
      //Mail-template
      {
        path: 'list-mail-template',
        component: ListMailTemplateComponent,
        data: {
          title: "List Mail Template",
          breadcrumb: "List Mail Template"
        }
      },
      {
        path: 'add-mail-template',
        component: AddMailTemplateComponent,
        data: {
          title: "Mail Template / New",
          breadcrumb: "Mail Template / New"
        }
      },
      {
        path: 'edit-mail-template/:id',
        component: EditMailTemplateComponent,
        data: {
          title: "Mail Template",
          breadcrumb: "Mail Template / Update"
        }
      },
      {
        path: 'delete-mail-template/:id',
        component: DeleteMailTempateComponent,
        data: {
          title: "Delete Mail Template",
          breadcrumb: "Mail Template / Delete"
        }
      },
      {
        path: 'view-mail-template',
        component: ViewMailTemplateComponent,
        data: {
          title: "View Mail Template",
          breadcrumb: "Mail Template / View"
        }
      },
      {
        path: 'list-ac-template',
        component: ListActemplateComponent,
        data: {
          title: "View Annual Confirmation Template",
          breadcrumb: "Annual Confirmation Template / View"
        }
      },
      {
        path: 'edit-ac-template/:id',
        component: EditActemplateComponent,
        data: {
          title: "Annual Confirmation Template",
          breadcrumb: "Annual Confirmation Template / Update"
        }
      },
    
      {
        path: 'companys-list',
        component: ListedCompanyComponent,
        data: {
          title: "company's list",
          breadcrumb: "company's list"
        }
      },
      //rules router
      {
        path: 'add-rules',
        component: AddCompanyrulesComponent,
        data: {
          title: "Add Rules",
          breadcrumb: "Add Rules"
        }
      },
      {
        path: 'edit-rules/:id',
        component: EditCompanyRulesComponent,
        data: {
          title: "Edit Rules",
          breadcrumb: "Edit Rules"
        }
      },
      {
        path: 'rules-list',
        component: ListCompanyrulesComponent,
        data: {
          title: "List of Rules",
          breadcrumb: "List of Rules"
        }
      },
      {
        path: 'mail-setting',
        component: AddMailSettingComponent,
        data: {
          title: "Mail Setting",
          breadcrumb: "Mail Setting"
        }
      },
      {
        path: 'user-log',
        component: UserLogComponent,
        data: {
          title: "User Log",
          breadcrumb: "User Log"
        }
      },
      {
        path: 'system-info',
        component: SystemInfoComponent,
        data: {
          title: "System Info",
          breadcrumb: "System Info"
        }
      },
      {
        path: 'users-list',
        component: UsersListComponent,
        data: {
          title: "Users List",
          breadcrumb: "Users List"
        }
      },
      {
        path: 'annual-confirmation-email',
        component: CronAnnualConfiramationComponent,
        data: {
          title: "Email Template",
          breadcrumb: "Email Template"
        }
      },
   
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class masterRoutingModule { }
