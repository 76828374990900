<!-- Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <div class="col-md-6 mb-3">                      
                        <div class="form-group">
                        <input type="text" class="form-control" id="filterString" autocomplete="off" [(ngModel)]="filterString" (ngModelChange)="onFilterChange()"  placeholder="Search by Template Name and Subject" >
                                                                         
                    </div>
                        </div>
                    <div class="list-btn">
                        <a routerLink="/dashboard"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                        <!-- <a routerLink="/setup/add-mail-template"><button class="btn btn-primary me-1" >New</button></a> -->

                    </div>
                   
                </div>
                <div class="card-body custom-datatable noscroll">
                    <div class="label-form" *ngIf="isSelected">
                        <div class="row" >
                            <div class="row dplabel">                                   
                                    <div class="col-md-4 mb-3 selectLabelbtn buttonalign">
                                    <button class="btn btn-primary me-1" [disabled]="isLoading" (click)="onSubmitLabel()" type="submit"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>
                                    </div>
                                
                                </div>
                            </div>                      
                    </div>
                    <div class="custom-datatable">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col" sortable="s.no">S.No.</th>
                                    <th scope="col" sortable="radio"></th>
                                    <th scope="col" sortable="templateName">Template Name</th>
                                    <th scope="col" sortable="subject">Subject</th>
                                    <!-- <th scope="col" sortable="contents">Body Content</th> -->
                                    <th scope="col" sortable="action">Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor =" let item of filtered | slice: (page-1) * pageSize : page * pageSize; let i=index ">
                                <td scope="row" data-content="S.No.">{{(i+1) + (page-1) * pageSize }}</td>
                                <td scope="row" data-content=""><input type="checkbox" #label (click)="isSelectLabel($event, item)" [(ngModel)]="item.checked"></td>
                                <td scope="row" data-content="Template Name">{{item.templatename}}</td>
                                <td scope="row" data-content="Subject">{{item.subject}}</td>
                                <!-- <td class="table-overlap" data-content="Body Content" scope="row" [innerHTML]="item.bodycontent"></td> -->
                                <td data-content="Actions">
                                    <button class="edit-icon" (click)="openModal(item.id)" ><i class="fa fa-eye"></i></button>
                                    <a [routerLink] = "['/setup/edit-ac-template', item.id]"><button class="edit-icon"><i class="fa fa-pencil-square-o"></i></button></a>
                                    <!-- <button class="edit-icon" (click)="openModalDelete(item.id)" title="Delete"><i class="fa fa-trash"></i></button> -->
                                </td>
                                  
                            </tr>
                            </tbody>
                        </table>

                        <div class="list-btn2" *ngIf="filtered?.length =='' ">
                            <p>No Data Found</p>
                        </div>
                        
                      <div class="d-flex justify-content-between p-2">
                        <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="filtered?.length"></ngb-pagination>
                        </div>

                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends -->

