<!--Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 ">
            <div class="card">
                <div class="card-header">
                    <h5>Add New Trading Window Closure</h5>
                    <a routerLink="/pit-compliance/list-twc"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                </div>
                <div class="card-body">
                    <form [formGroup]="twcCreateForm" class="needs-validation" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-md-6 mb-3">
                                <label for="subject">Start Date</label>
                                <div class="form-group">                    
                                    <input type="date" class="form-control" id="startDate"  autocomplete="off" formControlName="start_date"> 
                                    <div *ngIf="(submitted || twcCreateForm.controls.start_date.touched) && twcCreateForm.controls.start_date.errors?.required"
                                    class="text text-danger">
                                    Start Date is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="subject">End Date</label>
                                <div class="form-group">                    
                                    <input type="date" class="form-control" id="startDate"  autocomplete="off" formControlName="end_date">
                                    <div *ngIf="(submitted || twcCreateForm.controls.end_date.touched) && twcCreateForm.controls.end_date.errors?.required"
                                    class="text text-danger">
                                    End Date is required.
                                    </div> 
                                </div>
                                <div class="text text-danger" *ngIf="twcCreateForm.controls.end_date.touched && twcCreateForm.controls.end_date.invalid">Please add a valid from and To date </div>

                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="projectName">Sender Name</label>
                                <div class="form-group">
                                    <!-- <ng-multiselect-dropdown #senderDataList [placeholder]="'Select Sender'" 
                                        [settings]="dropdownSettings"
                                        [data]="senderList"
                                        [(ngModel)]="selectedSenderItems"
                                        (onSelect)="onItemSelect($event,'sender')"
                                        (onDeSelect)="onItemDeSelect($event,'sender')"
                                        (onSelectAll)="onSelectAll($event,'sender')"
                                        (onDeSelectAll)="onDeSelectAll($event,'sender')"
                                                                               
                                         formControlName="sender"
                                        >
                                    </ng-multiselect-dropdown> -->
                                    <ng-select [items]="senderList"
                                                bindLabel="" #senderDataList
                                                placeholder="Select Sender"
                                                [(ngModel)]="selectedSenderItems" formControlName="sender"                                     
                                                [ngClass]="{ 'is-invalid': (submitted || twcCreateForm.controls.sender.touched) && twcCreateForm.controls.sender.errors }" required>
                                            </ng-select>
                                    <div *ngIf="(submitted || twcCreateForm.controls.sender.touched) && twcCreateForm.controls.sender.errors?.required"
                                        class="text text-danger">
                                        Sender Name is required.
                                        </div>
                                </div>
                            </div>
                            <!-- (focusout)="closeDropdown($event)" -->
                            <div class="col-md-6 mb-3">
                                <div class="cols check-line">
                                    <label for="nationality">Name of Recipient</label>
                                    <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                        <div class="radio radio-primary">
                                            <input type="radio" name="radio4" id="radioinline7" value="Insider"
                                                formControlName="recipientvalue" (click)="radioSelectRep('Insider')">
                                            <label for="radioinline7" class="mb-0">Insider</label>
                                        </div>
                                        <div class="radio radio-primary">
                                            <input type="radio" name="radio4" id="radioinline8" value="DP"
                                                formControlName="recipientvalue" (click)="radioSelectRep('DP')">
                                            <label for="radioinline8" class="mb-0">DP</label>
                                        </div>
                                        <div class="radio radio-primary">
                                            <input type="radio" name="radio4" id="radioinline9" value="Both"
                                                formControlName="recipientvalue" (click)="radioSelectRep('Both')">
                                            <label for="radioinline9" class="mb-0">Both</label>
                                        </div>
                                    </div>

                                </div>
                                <div class="sellected-option">
                                    <div class="bottom-field" *ngIf="isSelectedRep('Insider')">
                                        <div class="row">
                                            <div class="form-group">
                                                <ng-multiselect-dropdown [placeholder]="'Select Recipient Insider'"
                                                    [settings]="dropdownSettings"
                                                    [data]="recipentInsiderList"
                                                    [(ngModel)]="selectedRecipentInsiderItems"
                                                    (onSelect)="onItemSelect($event,'sender')"
                                                    (onDeSelect)="onItemDeSelect($event,'sender')"
                                                    (onSelectAll)="onSelectAll($event,'sender')"
                                                    (onDeSelectAll)="onDeSelectAll($event,'sender')" formControlName="recipient"
                                                    >
                                                </ng-multiselect-dropdown>
                                                <div *ngIf="(submitted || twcCreateForm.controls.recipient.touched) && twcCreateForm.controls.recipient.errors?.required"
                                        class="text text-danger">
                                        Recipient Name is required.
                                        </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="bottom-field" *ngIf="isSelectedRep('DP')">
                                        <div class="row">
                                            <div class="form-group">
                                                <ng-multiselect-dropdown [placeholder]="'Select Recipient Dp'"
                                                    [settings]="dropdownSettings"
                                                    [data]="recipientDpList"
                                                    [(ngModel)]="selectedRecipentDpItems"
                                                    (onSelect)="onItemSelect($event,'sender')"
                                                    (onDeSelect)="onItemDeSelect($event,'sender')"
                                                    (onSelectAll)="onSelectAll($event,'sender')"
                                                    (onDeSelectAll)="onDeSelectAll($event,'sender')" formControlName="recipient"
                                                    >
                                                </ng-multiselect-dropdown>
                                                <div *ngIf="(submitted || twcCreateForm.controls.recipient.touched) && twcCreateForm.controls.recipient.errors?.required"
                                                class="text text-danger">
                                                Recipient Name is required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-field" *ngIf="isSelectedRep('Both')">
                                        <div class="row">
                                            <div class="form-group">
                                                <ng-multiselect-dropdown [placeholder]="'Select Recipient Both'"
                                                    [settings]="dropdownSettings"
                                                    [data]="recipientBothList"
                                                    [(ngModel)]="selectedRecipentbothItems"
                                                    (onSelect)="onItemSelect($event, 'both')"
                                                    (onDeSelect)="onItemDeSelect($event, 'both')"
                                                    (onSelectAll)="onSelectAll($event, 'both')"
                                                    (onDeSelectAll)="onDeSelectAll($event, 'both')" formControlName="recipient"
                                                    >
                                                </ng-multiselect-dropdown>
                                                <div *ngIf="(submitted || twcCreateForm.controls.recipient.touched) && twcCreateForm.controls.recipient.errors?.required"
                                                class="text text-danger">
                                                Recipient Name is required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="subject">Period</label>
                                <div class="form-group">                    
                                    <input type="text" class="form-control" id="Period" placeholder="Enter the Period" autocomplete="off" formControlName="period"> 
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="subject">Subject</label>
                                <div class="form-group">                    
                                    <input type="text" class="form-control" id="subject" placeholder="Enter the Subject" autocomplete="off" formControlName="subject"> 
                                    <div *ngIf="(submitted || twcCreateForm.controls.subject.touched) && twcCreateForm.controls.subject.errors?.required"
                                    class="text text-danger">
                                    Subject is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3" *ngIf="companyApplication == 'Intermediary' && is_access_write == true">
                                <label for="subject">Trading Company Name</label>
                                <div class="form-group">
                                    <ng-multiselect-dropdown [placeholder]="'Select Company Name'"
                                        [settings]="dropdownSettings"
                                        [data]="tradingCompanyNameList"
                                        [(ngModel)]="selectedtradingCompanyNameList"
                                        (onSelect)="onItemSelect($event, 'company')"
                                        (onDeSelect)="onItemDeSelect($event, 'company')"
                                        (onSelectAll)="onSelectAll($event, 'company')"
                                        (onDeSelectAll)="onDeSelectAll($event, 'company')" formControlName="tradingcompanyname"
                                        >
                                    </ng-multiselect-dropdown>
                                   
                                </div>
                            </div>
                            <div class="col-md-12 mb-3">
                                <label for="bodyContent">Body Content</label>
                                <div class="form-group">                    
                                    <angular-editor [(ngModel)]="htmlContent" [config]="config" formControlName="htmlcontents"></angular-editor>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3" *ngIf="openTwcAttachment == true">
                                <label for="dateOfSharing">Attachement</label>
                                <div class="form-group">
                                    <input #attachments type="file" class="custom-file-input form-control"
                                        accept=".pdf, image/png, image/jpeg, .xlsx, .docx, .txt, .xls" id="attachment"
                                        formControlName="agreement_attachment_file" #fileInput
                                        (change)="onFileChanged($event)" multiple="true">
                                    <p class="text text-danger">*Upload Files: .pdf, .png, .jpeg, .xlsx, .docx, .txt,
                                        .xls</p>
                                </div>
                                <div *ngFor="let selected of selectedFileName; let index = index" class="itemArray">
                                    <h3 class="listItemhead">{{ selected }}</h3>
                                    <button (click)="removeSelectedFile(index)" type="button" class="selectedBtn"><img
                                            src="assets/images/close.png" class="closeIcon"></button>
                                </div>
                                
                            </div>

                            <div class="submit-btn">
                                <button class="btn btn-primary" [disabled]="isLoading" type="submit"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>   
                                <button class="btn btn-warning" type="btn" (click)="cancelTwcTemplate()" >Cancel</button>
                             </div>
                        </div>
                    </form>

                </div>
            </div>                       
        </div>
    </div>
</div>
<!-- Container-fluid Ends -->

