<div class="modal-header">
    <h5 class="modal-title">{{name}} </h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
               
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable1">
                        <!-- <h4 style="font-size: 19px;">Annual Confirmation Financial Year FY-{{financialYearEntry}} Data is not updated. Do you wish to update the details?</h4> -->
                        <h4 style="font-size: 19px;margin-bottom: 1rem;">We Can see that Annual Confirmation of DP is not recorded in the system. We request you as compliance officer to seek annual confirmation from all DP's.</h4>
                        <p class="meg">To draft a new notice of upcoming annual confirmation. <a herf="javascript:void(0)" (click)="closeModal('Draft')"><span class="blink">click here</span></a> </p>
                        <p class="meg">To review the confirmation email template and trigger the emails to the respective details. <a herf="javascript:void(0)" (click)="closeModal('Send')"><span class="blink">click here</span></a></p>
                        <p class="meg">To view all saved / draft email templates, <a herf="javascript:void(0)" (click)="closeModal('View')"><span class="blink">click here</span> </a></p>
                        <!-- <div class="btn-bottom" *ngIf="hideButton">
                            <button class="btn btn-primary me-1" (click)="confirmDetailsId()" [disabled]="isLoading"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>
                            <button class="btn btn-primary me-1" (click)="open()" >Reject</button>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
 </div>
</div>
<!-- <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('Draft')">Draft</button>
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('Send')">Send</button>
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('View')">View</button>
</div> -->
