import { Component, OnInit } from '@angular/core';
import { MasterService } from '../../master.service';
import { ViewMailTemplateComponent } from '../view-mail-template/view-mail-template.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteMailTempateComponent } from '../delete-mail-tempate/delete-mail-tempate.component';
import { ToastrService } from 'ngx-toastr';
import { ViewActemplateComponent } from '../../annualconformationtemplate/view-actemplate/view-actemplate.component';
import { DeleteActemplateComponent } from '../../annualconformationtemplate/delete-actemplate/delete-actemplate.component';
import { DpModuleService } from 'src/app/dp-module/dp-module.service';
import { distinct, from, tap } from 'rxjs';

@Component({
  selector: 'app-list-mail-template',
  templateUrl: './list-mail-template.component.html',
  styleUrls: ['./list-mail-template.component.scss']
})
export class ListMailTemplateComponent implements OnInit {
  listMailTemplate: any;
  filtered: any = [];
  filtered2: any = [];
  page = 1;
  pageSize = 10;
  filterString = "";
  filterString2 = "";
  public active1 = 1;
  public active2 = 3;
  listACMailTemplate: any;
  financialYearEntry: any;
  isSelected: boolean;
  submitted: boolean;
  isLoading = false;
  button = 'Send';
  selectedRecipentDpItems = [];
  recipientDpList = [];
  selected_sender: any;
  dropdownSettings = {};

  constructor(private masterService: MasterService, private modalService: NgbModal,private toastr: ToastrService,
    private dpService:DpModuleService
  ) { }

  ngOnInit(): void {

    this.masterService.mailTemplateList(null).subscribe(
      res => {
     this.filtered = [];
      this.listMailTemplate = res;
      this.listMailTemplate?.data.map(item =>{
        if(item.templatename == 'Otp' || item.templatename == 'Login' || item.templatename =='Forgot Password'){
        }else{
          this.filtered?.push(item);

        }
      })
        },
        err => {
            console.log(err);
        }
    );

    this.masterService.acTemplateList(null).subscribe(
      res => {
     
      this.listACMailTemplate = res;

      this.filtered2 = this.listACMailTemplate?.data;
        },
        err => {
            console.log(err);
        }
    );

      //Financial Year Start
      let d = new Date();
      this.financialYearEntry = d.getMonth() < 3 ? d.getFullYear() - 1 : d.getFullYear();
      var fiscalyear_ = "";
      var today = new Date();
      if ((today.getMonth() + 1) <= 3) {
        fiscalyear_ = ((today.getFullYear() - 1) + "") + "-" + (today.getFullYear() + "").slice(-2)
      } else {
        fiscalyear_ = (today.getFullYear() + "") + "-" + ((today.getFullYear() + 1) + "").slice(-2)
      }
      this.financialYearEntry = fiscalyear_
      //Financial Year End

      this.dropdownSettings = {
        singleSelection: false,
        idField: 'item_id',
        textField: 'item_text',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 2,
        allowSearchFilter: true,
        defaultOpen: false,
      };

      //Dp List
      this.dpService.dpModuleList(null).subscribe(
        res => {
          this.recipientDpList = [];
          
          // this.selectedRecipentbothItems = [];
        let listDpModule = res['data']
        // const senderList2 = this.listDpModule.map(res =>res.email);
        // const senderList3 = senderList2.filter(result => result)
          const insiderList = listDpModule.map(({id,email}) =>({item_id: id,item_text: email}));
      let dropdownListRep = [];
      insiderList.forEach(result =>dropdownListRep.push(result))
      
         //recipent Dp
         from(dropdownListRep)
         .pipe(
           tap(item => item),
           distinct(d => d.item_text)
         )
         .subscribe(r => this.recipientDpList.push(r));
          },
          err => {
              console.log(err);
          }
      );
  }
//Filter
onFilterChange() {
  this.filtered = this.listMailTemplate.data?.filter(res =>{    
   return res.subject.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || res.templatename.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase());
  }) 
 
}

isMatch(item) {
  if (item instanceof Object) {
    return Object.keys(item).some((k) => this.isMatch(item[k]));
  } else {
    return item?.toString().indexOf(this.filterString) > -1
  }
}
  openModal(id:any) {
    if (id) {
      let mailId = {'id' : id};
      this.masterService.mailTemplateView(mailId).subscribe(
        ({data}) =>{
		const modalRef = this.modalService.open(ViewMailTemplateComponent,
    {
      scrollable: true,
      windowClass: 'myCustomModalClass',
      // keyboard: false,
      // backdrop: 'static'
    });		
    modalRef.componentInstance.viewMailTemplateList = data[0];
    modalRef.result.then((result) => {
      console.log(result);
    }, (reason) => {
    });
  })
}
	}
  openModalDelete(id:any) {
    if (id) {
      let mailId = {'id' : id};
      this.masterService.mailTemplateView(mailId).subscribe(
        ({data}) =>{
		const modalRef = this.modalService.open(DeleteMailTempateComponent,
    {
      scrollable: true,
      windowClass: 'myCustomModalClass',
      // keyboard: false,
      // backdrop: 'static'
    });		
    modalRef.componentInstance.viewMailTemplateList = data[0];
    modalRef.result.then((result) => {
      console.log(result);
    }, (reason) => {
    });
  })
}
	}
//user mail template
//Filter
onFilterChange2() {
  this.filtered2 = this.listACMailTemplate.data?.filter(res =>{    
   return res.subject.toLocaleLowerCase().match(this.filterString2.toLocaleLowerCase()) || res.templatename.toLocaleLowerCase().match(this.filterString2.toLocaleLowerCase());
  }) 
 
}
isMatch2(item) {
  if (item instanceof Object) {
    return Object.keys(item).some((k) => this.isMatch2(item[k]));
  } else {
    return item?.toString().indexOf(this.filterString2) > -1
  }
}
get result() {     
  return this.listACMailTemplate?.data?.filter(item => item.checked);      
}
isSelectLabel(e,curItem){
  let checkedItemsArr = []
  this.filtered.map((item) => {
    if(e.target.checked){
      checkedItemsArr.push(curItem);
    } else if(item && item.checked && item.id !== curItem.id){
      checkedItemsArr.push(item);
    }
  });
  if(checkedItemsArr.length > 0){
    this.isSelected = true;
  }else{
    this.isSelected = false;
  }
 
}
onSubmitLabel(userObj:any){
  
  this.submitted = true;
if(this.result.length > 1){
  this.toastr.warning("Please Select the one Template.");
  return false;
}else{
  this.result[0]['type'] = 'View';
  this.result[0]['recipient'] = this.selectedRecipentDpItems;
  this.result[0]['financialyear'] = this.financialYearEntry;
   this.isLoading = true;
    this.button = 'Processing'
  this.dpService.annualconfirmpopupentryupdate(this.result[0]).subscribe(data =>{ 
        this.isLoading = false;
        this.button = 'Send';  
        this.toastr?.success("Annual Confirmation Mail Successfully Send");        
         window.location.reload();
  });
}

}
  openModal2(id:any) {
    if (id) {
      let mailId = {'id' : id};
      this.masterService.mailTemplateView(mailId).subscribe(
        ({data}) =>{
		const modalRef = this.modalService.open(ViewActemplateComponent,
    {
      scrollable: true,
      windowClass: 'myCustomModalClass',
      // keyboard: false,
      // backdrop: 'static'
    });		
    modalRef.componentInstance.viewMailTemplateList = data[0];
    modalRef.result.then((result) => {
      console.log(result);
    }, (reason) => {
    });
  })
}
	}
  openModalDelete2(id:any) {
    if (id) {
      let mailId = {'id' : id};
      this.masterService.mailTemplateView(mailId).subscribe(
        ({data}) =>{
		const modalRef = this.modalService.open(DeleteActemplateComponent,
    {
      scrollable: true,
      windowClass: 'myCustomModalClass',
      // keyboard: false,
      // backdrop: 'static'
    });		
    modalRef.componentInstance.viewMailTemplateList = data[0];
    modalRef.result.then((result) => {
      console.log(result);
    }, (reason) => {
    });
  })
}
	}
// Multi Select
onItemSelect(item: any,page) {
  
  if(page == "sender"){
   if(!this.selected_sender?.includes(item)){
     this.selected_sender?.push(item);
   }
 }
}

onItemDeSelect(item: any,page) {

 if(page == "sender"){
   if(this.selected_sender.includes(item)){
     this.selected_sender.splice(this.selected_sender.indexOf(item), 1);
   }
 }
}
onSelectAll(items: any,page) {
if(page == "sender"){
   this.selected_sender=items;
 }
 
}
onDeSelectAll(items: any,page) {
if(page == "sender"){
     this.selected_sender=[];
   }
}
}
