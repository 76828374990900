import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, } from '@angular/core';
import { distinct, from, Observable, Subscriber, Subscription, tap } from 'rxjs';
import { COMPANYDB, CompanyDB } from '../../shared/data/tables/company';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { TableService } from 'src/app/shared/services/table.service';
import { DpModuleService } from '../dp-module.service';
import { NgbModal, NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { PopupModelDpComponent } from '../popup-model-dp/popup-model-dp.component';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { BulkActionPopupdpComponent } from '../bulk-action-popupdp/bulk-action-popupdp.component';
import { HttpClient } from '@angular/common/http';
import { LabelPopupComponent } from '../label-popup/label-popup.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Item } from 'angular2-multiselect-dropdown';
import { Input, on } from 'hammerjs';
import { DbModuleDeleteComponent } from '../db-module-delete/db-module-delete.component';
import { SharedDataService } from '../shared-data.service';
import { DisclaimerexcelComponent } from 'src/app/master/popup/disclaimerexcel/disclaimerexcel.component';

export interface dp{
  id: number;
  dpName: string;
  dpDesignation: string;
  confidentiality_agreement_entered: string;
  email: string;
}
@Component({
  selector: 'app-db-module-list',
  templateUrl: './db-module-list.component.html',
  styleUrls: ['./db-module-list.component.scss']
})
export class DbModuleListComponent implements OnInit, OnDestroy {
  @ViewChild('auto') auto;
  @ViewChild('label') label!: ElementRef;
  //@Input() dpLabelListArr;

  public selected = [];
  baseUrl = environment.apiUrl;
  page = 1;
  pageSize = 10;
  isDisabled = true;
  listDpModule: any[];
  userPermission = 'none';
  accessSuccess: string;
  deletedDpModule: any;
  filterString = "";
  filtered;
  is_access_read=false;
  is_access_write=false;
  is_access_no=false;
  user_roles;

  hidelist = [{"id":1,"name":"ID No."},{"id":2,"name":"Name"},{"id":3,"name":"Designation"},{"id":5,"name":"Email ID"},{"id":6,"name":"Contact Number"},{"id":7,"name":"Address"},{"id":8,"name":"ID Proof No."},{"id":9,"name":"Educational Institute Name"},{"id":10,"name":"Company Name of Previous Job"},{"id":11,"name":"Remarks"}];
  selectedhideItems = [];
  selected_contactNo:any;
  selected_address:any;  
  show_id = true;
  show_dpName = true;
  show_dpDesignation = true;
  show_confidentiality_agreement_entered = true;
  show_email = true;
  show_contact=false;
  show_address=false;
  show_identifier = false;
  show_dpEducationalInstitute = false;
  show_dpPastEmp = false;
  show_remarks = false;
  dropdownSettings1:IDropdownSettings;
  visible: boolean = false
  visible2: boolean = false
  visible3: boolean = false
  visible4: boolean = false
  visible5: boolean = false
  visible6: boolean = false
  visible7: boolean = false
  visible8: boolean = false
  nameFilterString= '';
  designationFilterString= '';
  emailFilterString= '';
  contactFilterString= '';
  addressFilterString = '';
  identifierFilterString = '';
  educationalFilterString = '';
  pastEmpFilterString = '';
  keyword = 'dpName';
  keyword2 = 'dpDesignation';
  keyword3 = 'email';
  keyword4 = 'dpContactNo';
  keyword5 = 'dpAddress';
  keyword6 = 'DocumentType';
  keyword7 = 'dpEducationalInstitute';
  keyword8 = 'dpPastEmp' ; 
  

  dropdownSettings2 = {};
  labelDataList = [];
  labelDataListArr = [];
  labelDataListfilter = [];
  labelDataList1 = [];
  selectedLabelDataItems = [];
  selectedLabelDataItemsfilter = []
  selected_label = [];
  selected_labelfilter = [];
  isSelected: boolean = false;
  dbLabelCreateForm: FormGroup;
  toastr: any;
  dpNameFilter: any[];
  dpDesignationFilter: any[];
  dpEmailFilter: any[];
  dpContactNoFilter: any[];
  dpAddressFilter: any[];
  documentTypeFilter: any[];
  dpEducationalInstituteFilter: any[];
  dpPastEmpFilter: any[];
  subscription: Subscription;
  openDpLabel = false;
  isLoading = false;
  button = 'Apply';
  submitted: boolean = false;
  bulkuploadfolder:boolean = false;
  constructor(private fb: FormBuilder, private http:HttpClient, private dpService:DpModuleService, config: NgbPaginationConfig, public router: Router,  private modalService: NgbModal,
    private service: SharedDataService) {
    this.userPermission = sessionStorage.getItem('sdd_user_permission');
    this.accessSuccess = sessionStorage.getItem('access_success');
    
    if(this.accessSuccess !== '1'){
      
      this.router.navigate(['auth/login']);      
    }
    
  }
//MultiSelect HideShow
onItemSelect(item: any,page) {
  if(page == "Contact Number"){
   if(!this.selected_contactNo.includes(item)){
     this.selected_contactNo.push(item);
   }
 }else if(page == "Address"){
   if(!this.selected_address?.includes(item)){
     this.selected_address.push(item);
   }
 }else if(page == "column"){
  if(this.selectedhideItems.length > 8){   
    document.getElementById('formTable').classList.add('tablescroll');
  }else{
    document.getElementById('formTable').classList.remove('tablescroll');
  }
  if(item.id == 1){
    this.show_id = true;
  }else if(item.id == 2){
    this.show_dpName = true;
  }else if(item.id == 3){
    this.show_dpDesignation = true;
  }else if(item.id == 4){
    this.show_confidentiality_agreement_entered = true;
  }else if(item.id == 5){
    this.show_email = true;
  }else if(item.id == 6){
     this.show_contact = true;
   }else if(item.id == 7){
     this.show_address = true;
   }else if(item.id == 8){
    this.show_identifier = true;
  }else if(item.id == 9){
    this.show_dpEducationalInstitute = true;
  }else if(item.id == 10){
    this.show_dpPastEmp = true;
  }else if(item.id == 11){
    this.show_remarks = true;
  }
 }
}

onItemDeSelect(item: any,page) {

if(page == "Contact Number"){
   if(this.selected_contactNo.includes(item)){
     this.selected_contactNo.splice(this.selected_contactNo.indexOf(item), 1);
   }
 }else if(page == "Address"){
   if(this.selected_address.includes(item)){
     this.selected_address.splice(this.selected_address.indexOf(item), 1);
   }
 }else if(page == "column"){
  if(this.selectedhideItems.length > 8){   
    document.getElementById('formTable').classList.add('tablescroll');
  }else{
    document.getElementById('formTable').classList.remove('tablescroll');
  }
  if(item.id == 1){
    this.show_id = false;
  }else if(item.id == 2){
    this.show_dpName = false;
  }else if(item.id == 3){
    this.show_dpDesignation = false;
  }else if(item.id == 4){
    this.show_confidentiality_agreement_entered = false;
  }else if(item.id == 5){
    this.show_email = false;
  }else if(item.id == 6){
     this.show_contact = false;
   }else if(item.id == 7){
     this.show_address = false;
   }else if(item.id == 8){
    this.show_identifier = false;
  }else if(item.id == 9){
    this.show_dpEducationalInstitute = false;
  }else if(item.id == 10){
    this.show_dpPastEmp = false;
  }else if(item.id == 11){
    this.show_remarks = false;
  }
 }
 
}
onSelectAll(items: any,page) {
 if(page == "Contact Number"){
   this.selected_contactNo=items;
 }else if(page == "Address"){
   this.selected_address=items;
 }else if(page == "column"){
  if(items.length >8){   
    document.getElementById('formTable').classList.add('tablescroll');
  }else{
    document.getElementById('formTable').classList.remove('tablescroll');
  }
  this.show_id = true;
  this.show_dpName = true;
  this.show_dpDesignation = true;
  this.show_confidentiality_agreement_entered = true;
  this.show_email = true;
  this.show_contact=true;
  this.show_address=true;
  this.show_identifier = true;
  this.show_dpEducationalInstitute = true;
  this.show_dpPastEmp = true;
  this.show_remarks = true; 
 }
 
}
onDeSelectAll(items: any,page) {
if(page == "Contact Number"){
   this.selected_contactNo=[];
 }else if(page == "Address"){
   this.selected_address=[];
 }else if(page == "column"){
  if(items.length > 8){   
    document.getElementById('formTable').classList.add('tablescroll');
  }else{
    document.getElementById('formTable').classList.remove('tablescroll');
  }
  this.show_id = false;
  this.show_dpName = false;
  this.show_dpDesignation = false;
  this.show_confidentiality_agreement_entered = false;
  this.show_email = false;
  this.show_contact=false;
  this.show_address=false;
  this.show_identifier = false;
  this.show_dpEducationalInstitute = false;
  this.show_dpPastEmp = false;
  this.show_remarks = false;
 }
}
// Multi Select
onItemSelect1(item: any,page) {
  
  if(page == "label"){
   if(!this.selected_label.includes(item)){
     this.selected_label.push(item);
   }
 }else if(page == "labelfilter"){
  // if(!this.selected_labelfilter.includes(item)){
  //   this.selected_labelfilter.push(item);
  // }
  let filteredItemsLabel = [];
  let filteredItemsLabels = [];
  if(this.selectedLabelDataItemsfilter.length > 0){
    this.selectedLabelDataItemsfilter?.map(res =>{      
      this.listDpModule?.map(data =>{

        if(data.label?.toLocaleLowerCase().match(res.item_text?.toLocaleLowerCase())) {
          filteredItemsLabels.push(data);
          
        }
      });
    
    });
  } else {
    filteredItemsLabels = this.listDpModule
    }
    from(filteredItemsLabels)
    .pipe(
    tap(item => item),
    distinct(d => d)
    )
    .subscribe(r => filteredItemsLabel.push(r));
  this.filtered = filteredItemsLabel;
}
}

onItemDeSelect1(item: any,page) {

 if(page == "label"){
   if(this.selected_label.includes(item)){
     this.selected_label.splice(this.selected_label.indexOf(item), 1);
   }
 }else if(page == "labelfilter"){
 
  // if(this.selected_labelfilter.includes(item)){
  //   this.selected_labelfilter.splice(this.selected_label.indexOf(item), 1);
  // }
  let filteredItemsLabel = [];
  let filteredItemsLabels = [];
  if(this.selectedLabelDataItemsfilter.length > 0){
    this.selectedLabelDataItemsfilter?.map(res =>{      
      this.listDpModule?.map(data =>{
        if(data.label?.toLocaleLowerCase().match(res.item_text?.toLocaleLowerCase())) {
          filteredItemsLabels.push(data);
          
        }
      });
    });
  } else {
    filteredItemsLabels = this.listDpModule
    }
    from(filteredItemsLabels)
    .pipe(
    tap(item => item),
    distinct(d => d)
    )
    .subscribe(r => filteredItemsLabel.push(r));
  this.filtered = filteredItemsLabel;
  
}
}
onSelectAll1(items: any,page) {
if(page == "label"){
   this.selected_label=items;
 }else if(page == "labelfilter"){
  this.filtered = this.listDpModule;
}
 
}
onDeSelectAll1(items: any,page) {
if(page == "label"){
     this.selected_label=[];
   }else if(page == "labelfilter"){
    this.filtered = this.listDpModule;
  }
}

  ngOnInit(): void {

    this.dbLabelCreateForm = this.fb.group({
      groupLabelName: new FormControl('', Validators.required),
      dpCheckedList: new FormControl('')
    });



    this.user_roles = JSON.parse(atob(sessionStorage.getItem('sdd_access_roles')));
    if(this.user_roles.dpname_read == '2'){
      this.is_access_write=true;
      this.is_access_read=true;
      this.is_access_no=true;
    }else if(this.user_roles.dpname_read == '1'){
      this.is_access_write=false;
      this.is_access_read=true;
      this.is_access_no=true;
    }

    let accessData = sessionStorage.getItem('model_access_controller');    
    let accessModelController = JSON.parse(accessData);
    if(accessModelController.dplabel_access == true || accessModelController.dplabel_access == 1){
       this.openDpLabel = true;
    }else{
       this.openDpLabel = false;
    }
    if(accessModelController.bulkuploadfolder_access == true || accessModelController.bulkuploadfolder_access == 1){
       this.bulkuploadfolder = true;
    }else{
       this.bulkuploadfolder = false;
    }

    this.subscription = this.dpService.dpModuleList(null).subscribe(
      res => {
        if(res['message'] == 'Invalid User.'){
          this.router.navigate(['/auth/login']);
        }else{
      this.listDpModule = res['data'];
      //Dp name
      let dpName = this.listDpModule.map(res => res.dpName)
      this.dpNameFilter = [];
      from(dpName)
    .pipe(
      tap(item => item),
      distinct(d => d)
    )
    .subscribe(r => this.dpNameFilter.push(r));

    //Designation
    let dpDesignation = this.listDpModule.map(res => res.dpDesignation)
      this.dpDesignationFilter = [];
      from(dpDesignation)
    .pipe(
      tap(item => item),
      distinct(d => d)
    )
    .subscribe(r => this.dpDesignationFilter.push(r));

    //Email
    let dpEmail= this.listDpModule.map(res => res.email)
    this.dpEmailFilter = [];
    from(dpEmail)
  .pipe(
    tap(item => item),
    distinct(d => d)
  )
  .subscribe(r => this.dpEmailFilter.push(r));

  //dpContactNo
  let dpContactNo= this.listDpModule.map(res => res.dpContactNo)
  this.dpContactNoFilter = [];
  from(dpContactNo)
.pipe(
  tap(item => item),
  distinct(d => d)
)
.subscribe(r => this.dpContactNoFilter.push(r));

//dpAddress
let dpAddress= this.listDpModule.map(res => res.dpAddress)
this.dpAddressFilter = [];
from(dpAddress)
.pipe(
tap(item => item),
distinct(d => d)
)
.subscribe(r => this.dpAddressFilter.push(r));

//DocumentType
let documentType= this.listDpModule.map(res => (res.DocumentType + ' - ' + res.dpPanNo))
this.documentTypeFilter = [];
from(documentType)
.pipe(
tap(item => item),
distinct(d => d)
)
.subscribe(r => this.documentTypeFilter.push(r));

//dpEducationalInstitute
let dpEducationalInstitute= this.listDpModule.map(res => res.dpEducationalInstitute)
this.dpEducationalInstituteFilter = [];
from(dpEducationalInstitute)
.pipe(
tap(item => item),
distinct(d => d)
)
.subscribe(r => this.dpEducationalInstituteFilter.push(r));

//dpPastEmp
let dpPastEmp= this.listDpModule.map(res => res.dpPastEmp)
this.dpPastEmpFilter = [];
from(dpPastEmp)
.pipe(
tap(item => item),
distinct(d => d)
)
.subscribe(r => this.dpPastEmpFilter.push(r));

        this.onFilterChange();
        }
        },
        err => {
            console.log(err);
        }
    );

    this.selectedhideItems = [{"id":1,"name":"ID No."},{"id":2,"name":"Name"},{"id":3,"name":"Designation"},{"id":5,"name":"Email ID"}];

    //Multiselect HideShow
    this.dropdownSettings1 = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 2,
      allowSearchFilter: true
    };
    this.labelDataList = [];
    this.selectedLabelDataItems = [];
    this.selectedLabelDataItemsfilter = [];
    //Multi select box
  // LabelMultiselect
  this.dropdownSettings2 = {
    // singleSelection: false,
    // idField: 'item_id',
    // textField: 'item_text',
    // selectAllText: 'Select All',
    // unSelectAllText: 'UnSelect All',
    // itemsShowLimit: 2,
    // allowSearchFilter: true,
    singleSelection: false,
        idField: 'item_id',
        textField: 'item_text',
        enableCheckAll: true,
        selectAllText: 'Select All',
        unSelectAllText: 'Unselect All',
        allowSearchFilter: true,
        limitSelection: -1,
        clearSearchFilter: true,
        maxHeight: 197,
        itemsShowLimit: 3,
        closeDropDownOnSelection: false,
        showSelectedItemsAtTop: false,
        defaultOpen: false,
  
  };

  // this.dpService.listDpLabelModule().subscribe(
  //   res => {
      
  //   // const listDpLabelModule = res.body['data'];
  //   const labelDataListres = res.body['data']; 
  //   const labelDatas = labelDataListres?.map(({id,dp_labelname}) =>({item_id: id,item_text: dp_labelname}));
  //   this.labelDataList = [];
  //   this.labelDataListfilter = [];
  //   labelDatas.forEach(result =>this.labelDataList.push(result))
  //   from(this.labelDataList)
  //     .pipe(
  //     tap(item => item),
  //     distinct(d => d.item_text)
  //     )
  //     .subscribe(r => this.labelDataListfilter.push(r));
  //     console.log("labelDataList1", this.labelDataListfilter)
  //     },
  //     err => {
  //         console.log(err);
  //     }
  // );

  //dpLabel List
  this.service.listDpLabelModule.subscribe(
    res => {
      this.labelDataList = [];
      let labelDataList1 = [];
      this.labelDataListfilter = [];
      if( res != null){
        labelDataList1 = res?.map(({id,dp_labelname}) =>({id,dp_labelname,item_id: id,item_text: dp_labelname}));
      }

      from(labelDataList1)
      .pipe(
      tap(item => item),
      distinct(d => d.item_text)
      )
      .subscribe(r => this.labelDataList.push(r));
      from(labelDataList1)
      .pipe(
      tap(item => item),
      distinct(d => d.item_text)
      )
      .subscribe(r => this.labelDataListfilter.push(r));
     
      
    }
  );
  
     }

     get result() {         
    
       return this.listDpModule?.filter(item => item.checked);      
    }
    isSelectLabel(e, curItem){
      let checkedItemsArr = []
      this.filtered.map((item) => {
        if(e.target.checked){
          checkedItemsArr.push(curItem);
        } else if(item && item.checked && item.id !== curItem.id){
          checkedItemsArr.push(item);
        }
      });

      //const checkedLabel = this.label.nativeElement.value;
      if(checkedItemsArr.length > 0){
        this.isSelected = true;
      }else{
        this.isSelected = false;
      }
    }
    onSubmitLabel(userObj:any){
      this.submitted = true;
      this.dbLabelCreateForm.value.dpCheckedList = this.result;

    if(this.dbLabelCreateForm.status !== 'INVALID'){
        this.isLoading = true;
          this.button = 'Processing';
      this.dpService.updateDpLabelModule(this.dbLabelCreateForm.value).subscribe(data =>{   
        this.isLoading = false;
      this.button = 'Apply';  
        this.toastr?.success("Label grouping Successfully");        
         window.location.reload();
       
      }) 
    }else{
      if(this.dbLabelCreateForm.get('groupLabelName').value == ''){
        this.toastr.warning("Please fill the DP Label.");
        return false;
      }
    }
    }
//colum filter
selectEvent(item) {
  // do something with selected item
  this.filtered = this.listDpModule?.filter(dpItem => {
    return dpItem.dpName.toLocaleLowerCase().match(item.toLocaleLowerCase());

  })

  this.visible = false;
}
selectEvent2(item) {
  // do something with selected item
  this.filtered = this.listDpModule?.filter(dpItem => {
    return dpItem.dpDesignation.toLocaleLowerCase().match(item.toLocaleLowerCase());

  })

  this.visible2 = false;
}
selectEvent3(item) {
  // do something with selected item
  this.filtered = this.listDpModule?.filter(dpItem => {
    return dpItem.email.toLocaleLowerCase().match(item.toLocaleLowerCase());

  })

  this.visible3 = false;
}
selectEvent4(item) {
  // do something with selected item
  this.filtered = this.listDpModule?.filter(dpItem => {
    return dpItem.dpContactNo.toLocaleLowerCase().match(item.toLocaleLowerCase());

  })

  this.visible4 = false;
}
selectEvent5(item) {
  // do something with selected item
  this.filtered = this.listDpModule?.filter(dpItem => {
    return dpItem.dpAddress.toLocaleLowerCase().match(item.toLocaleLowerCase());

  })

  this.visible5 = false;
}
selectEvent6(item) {
  // do something with selected item
  this.filtered = this.listDpModule?.filter(dpItem => {
    let panItem = dpItem.DocumentType + ' - ' + dpItem.dpPanNo ;
    return panItem.toLocaleLowerCase().match(item.toLocaleLowerCase());

  })

  this.visible6 = false;
}
selectEvent7(item) {
  // do something with selected item
  this.filtered = this.listDpModule?.filter(dpItem => {
    return dpItem.dpEducationalInstitute.toLocaleLowerCase().match(item.toLocaleLowerCase());

  })

  this.visible7 = false;
}
selectEvent8(item) {
  // do something with selected item
  this.filtered = this.listDpModule?.filter(dpItem => {
    return dpItem.dpPastEmp.toLocaleLowerCase().match(item.toLocaleLowerCase());

  })

  this.visible8 = false;
}
// 
onChangeSearch(search: string) {
  // fetch remote data from here
  // And reassign the 'data' which is binded to 'data' property.
  console.log("String: " + search);
  
}

onFocused(e) {
  // do something
}
onCleared(e) {
  //console.log('input cleared');
  this.filtered = this.listDpModule;
}

onBlur(e) {
  this.visible = false;
  this.visible2 = false;
  this.visible3 = false;
  this.visible4 = false;
  this.visible5 = false;
  this.visible6 = false;
  this.visible7 = false;
  this.visible8 = false;
}
onScrolledEnd() {
  // this.auto.close();
}
filterData() {
  this.visible = !this.visible;
  this.visible2 = false;
  this.visible3 = false;
  this.visible4 = false;
  this.visible5 = false;
  this.visible6 = false;
  this.visible7 = false;
  this.visible8 = false;
}
filterData2() {
  this.visible2 = !this.visible2;
  this.visible = false;
  this.visible3 = false;
  this.visible4 = false;
  this.visible5 = false;
  this.visible6 = false;
  this.visible7 = false;
  this.visible8 = false;

}
filterData3() {
  this.visible3 = !this.visible3;
  this.visible = false;
  this.visible2 = false;
  this.visible4 = false;
  this.visible5 = false;
  this.visible6 = false;
  this.visible7 = false;
  this.visible8 = false;
}
filterData4() {
  this.visible4 = !this.visible4;
  this.visible = false;
  this.visible2 = false;
  this.visible3 = false;
  this.visible5 = false;
  this.visible6 = false;
  this.visible7 = false;
  this.visible8 = false;
}
filterData5() {
  this.visible5 = !this.visible5;
  this.visible = false;
  this.visible2 = false;
  this.visible3 = false;
  this.visible4 = false;
  this.visible6 = false;
  this.visible7 = false;
  this.visible8 = false;
}
filterData6() {
  this.visible6 = !this.visible6;
  this.visible = false;
  this.visible2 = false;
  this.visible3 = false;
  this.visible4 = false;
  this.visible5 = false;
  this.visible7 = false;
  this.visible8 = false;
}
filterData7() {
  this.visible7 = !this.visible7;
  this.visible = false;
  this.visible2 = false;
  this.visible3 = false;
  this.visible4 = false;
  this.visible5 = false;
  this.visible6 = false;
  this.visible8 = false;
}
filterData8() {
  this.visible8 = !this.visible8;
  this.visible = false;
  this.visible2 = false;
  this.visible3 = false;
  this.visible4 = false;
  this.visible5 = false;
  this.visible6 = false;
  this.visible7 = false;
  
}
//column filter
     onFilterChange() {
      this.filtered = this.listDpModule?.filter(res =>{    
       return res.dpName.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) 
       || res.email.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) 
       || res.dpDesignation.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) 
       || res.confidentiality_agreement_entered.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) 
       || res.dpContactNo.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) 
       || res.dpAddress.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) 
       || res.DocumentType.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) 
       || res.dpPanNo.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) 
       || res.dpEducationalInstitute.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) 
       || res.dpPastEmp.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase())
       || res.Record.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase());
      }) 
     
    }
  
    isMatch(item) {
      if (item instanceof Object) {
        return Object.keys(item).some((k) => this.isMatch(item[k]));
      } else {
        return item?.toString().indexOf(this.filterString) > -1
      }
    }

     toggleDisabled() {
      this.isDisabled = !this.isDisabled;
    }


    onFilter1() {
     
      let queryString = '';
      let url = this.baseUrl + 'dpexcel?';
      let tokenStr = sessionStorage.getItem('access_token');
      if(tokenStr){
        tokenStr = tokenStr.replace('Bearer ', '');
      }
      queryString = 'authToken='+btoa(tokenStr);
      var element = document.createElement('a');
      element.setAttribute('href', url + queryString);
      element.setAttribute('id', 'filter1');
      element.setAttribute('download', 'dpdetails.xls');
  
      element.style.display = 'none';
      document.body.appendChild(element);
  
      element.click();
  
      document.body.removeChild(element);
      
// var self = this;
//        setTimeout(function(){
//         self.onFilter2();
//       },1500);
    } 

    onFilter2() {
     
      let queryString = '';
      let url2 = this.baseUrl + 'dpimmediateexcel?';
      let tokenStr = sessionStorage.getItem('access_token');
      if(tokenStr){
        tokenStr = tokenStr.replace('Bearer ', '');
      }
      queryString = 'authToken='+btoa(tokenStr);
      var element2 = document.createElement('a');
      element2.setAttribute('href', url2 + queryString);
      element2.setAttribute('id', 'filter2');
      element2.setAttribute('download', 'dpimmediaterelative.xls');
  
      element2.style.display = 'none';
      document.body.appendChild(element2);
  
      element2.click();
  
      document.body.removeChild(element2);
      
    } 
    onFilter(){
      this.onFilter1();
      // this.onFilter2();
      var self = this;
       setTimeout(function(){
        self.onFilter2();
      },3000);
    }
    // onFilter() {
    //  const urls = [this.baseUrl + 'dpexcel', this.baseUrl + 'dpimmediateexcel']
    //   urls.forEach(url => {
    //     let iframe = document.createElement('iframe');
    //     iframe.style.visibility = 'collapse';
    //     document.body.append(iframe);
  
    //     iframe.contentDocument.write(
    //       `<form action="${url.replace(/\"/g, '"')}" method="GET"></form>`
    //     );
    //     iframe.contentDocument.forms[0].submit();
  
    //     setTimeout(() => iframe.remove(), 2000);
    //   });
    // }
    openModal(id:any) {   
      
      if (id) {
        let dpId = {'id' : id};
        this.dpService.viewDpModule(dpId).subscribe(
          ({data}) =>{
          const modalRef = this.modalService.open(PopupModelDpComponent,
            {
              scrollable: true,
              windowClass: 'myCustomModalClass',
              // keyboard: false,
              // backdrop: 'static'
            });
          modalRef.componentInstance.viewDbList = data[0];
          modalRef.componentInstance.dpId = id;
          modalRef.result.then(
            (result) => {
              console.log(result);
            }, (reason) => {
              console.log(reason);
            });
        })
      }    
      
    } 

    open() {
      const modalRef = this.modalService.open(BulkActionPopupdpComponent,
      {
        scrollable: true,
        windowClass: 'myCustomModalClass',
        // keyboard: false,
        // backdrop: 'static'
      });
      modalRef.componentInstance.name = 'Bulk Upload';
      modalRef.result.then((result) => {
        console.log(result);
      }, (reason) => {
      });
    }
    openLabelModel() {
      const modalRef = this.modalService.open(LabelPopupComponent,
      {
        scrollable: true,
        windowClass: 'myCustomModalClass',
        // keyboard: false,
        // backdrop: 'static'
      });
      modalRef.componentInstance.name = 'Label';
      modalRef.result.then((result) => {
      
      }, (reason) => {
      });
      
      modalRef.componentInstance.onSubmitEntry.subscribe((receivedEntry) => {
        // console.log(receivedEntry);
        this.labelDataList = receivedEntry?.map(({id,dp_labelname}) =>({id,dp_labelname,item_id: id,item_text: dp_labelname}));
      })
      
    }

    openModalDelete(id:any) {   
      
      if (id) {
        let dpId = {'id' : id};
        this.dpService.viewDpModule(dpId).subscribe(
          ({data}) =>{
          const modalRef = this.modalService.open(DbModuleDeleteComponent,
            {
              scrollable: true,
              windowClass: 'myCustomModalClass',
              // keyboard: false,
              // backdrop: 'static'
            });
          modalRef.componentInstance.viewDbList = data[0];
          modalRef.result.then(
            (result) => {
              console.log(result);
            }, (reason) => {
              console.log(reason);
            });
        })
      }    
      
    } 
    openExcelDisclaimer() {

      const modalRef = this.modalService.open(DisclaimerexcelComponent,
        {
          scrollable: true,
          windowClass: 'myCustomModalClass',
        });
        modalRef.componentInstance.name = 'Alert';
        modalRef.componentInstance.title = 'Designated Persons (DP)';
        modalRef.componentInstance.onSubmitEntry.subscribe((receivedData) => {
        if(receivedData == 'Yes'){
          this.onFilter();
        }
      }, (reason) => {
      });
        
  }
    ngOnDestroy():void{      
      this.subscription.unsubscribe();
    }
}
