<!--Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 ">
            <div class="card">
                <div class="card-header">
                    <h5>Annual Confiramation Email Template</h5>
                    <a routerLink="/dashboard"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                </div>
                <div class="card-body">
                    <form [formGroup]="annualconfirmationentryform" class="needs-validation" (ngSubmit)="onSubmit()">
                        <div class="row">
                         
                            <!-- (focusout)="closeDropdown($event)" -->
                            <!-- <div class="col-md-6 mb-3">
                                <div class="cols check-line">
                                    <label for="nationality">Name of Recipient</label>
                                    <div class="form-group">
                                        <ng-multiselect-dropdown [placeholder]="'Select Recipient Dp'"
                                            [settings]="dropdownSettings"
                                            [data]="recipientDpList"
                                            [(ngModel)]="selectedRecipentDpItems"
                                            (onSelect)="onItemSelect($event,'sender')"
                                            (onDeSelect)="onItemDeSelect($event,'sender')"
                                            (onSelectAll)="onSelectAll($event,'sender')"
                                            (onDeSelectAll)="onDeSelectAll($event,'sender')" formControlName="recipient"
                                            >
                                        </ng-multiselect-dropdown>
                                        <div *ngIf="(submitted || annualconfirmationentryform.controls.recipient.touched) && annualconfirmationentryform.controls.recipient.errors?.required"
                                        class="text text-danger">
                                        Recipient Name is required.
                                        </div>
                                    </div>

                                </div>
                             
                            </div> -->
                            <div class="col-md-6 mb-3">
                                <label for="subject">Name of DP</label>
                                <div class="form-group">                    
                                    <ng-multiselect-dropdown [placeholder]="'Select Name OF DP'"
                                    [settings]="dropdownSettings"
                                    [data]="recipientDpList"
                                    [(ngModel)]="selectedRecipentDpItems"
                                    (onSelect)="onItemSelect($event,'sender')"
                                    (onDeSelect)="onItemDeSelect($event,'sender')"
                                    (onSelectAll)="onSelectAll($event,'sender')"
                                    (onDeSelectAll)="onDeSelectAll($event,'sender')" formControlName="recipient"
                                    >
                                </ng-multiselect-dropdown>
                                <div *ngIf="(submitted || annualconfirmationentryform.controls.recipient.touched) && annualconfirmationentryform.controls.recipient.errors?.required"
                                class="text text-danger">
                                Recipient Name is required.
                                </div>                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="subject">Template Name</label>
                                <div class="form-group">                    
                                    <input type="text" class="form-control" id="templatename" placeholder="Enter the Template Name" autocomplete="off" formControlName="templatename"> 
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="subject">Period</label>
                                <div class="form-group">                    
                                    <input type="text" class="form-control" id="Period" placeholder="Enter the Period" autocomplete="off" formControlName="period"> 
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="subject">Subject</label>
                                <div class="form-group">                    
                                    <input type="text" class="form-control" id="subject" placeholder="Enter the Subject" autocomplete="off" formControlName="subject"> 
                                    <div *ngIf="(submitted || annualconfirmationentryform.controls.subject.touched) && annualconfirmationentryform.controls.subject.errors?.required"
                                    class="text text-danger">
                                    Subject is required.
                                    </div>
                                </div>
                            </div>                  
                            <div class="col-md-12 mb-3">
                                <label for="bodyContent">Body Content</label>
                                <div class="form-group">                    
                                    <angular-editor [(ngModel)]="htmlContent" [config]="config" formControlName="htmlcontents"></angular-editor>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3" >
                                <label for="dateOfSharing">Attachement</label>
                                <div class="form-group">
                                    <input #attachments type="file" class="custom-file-input form-control"
                                        accept=".pdf, image/png, image/jpeg, .xlsx, .docx, .txt, .xls" id="attachment"
                                        formControlName="agreement_attachment_file" #fileInput
                                        (change)="onFileChanged($event)" multiple="true">
                                    <p class="text text-danger">*Upload Files: .pdf, .png, .jpeg, .xlsx, .docx, .txt,
                                        .xls</p>
                                </div>
                                <div *ngFor="let selected of selectedFileName; let index = index" class="itemArray">
                                    <h3 class="listItemhead">{{ selected }}</h3>
                                    <button (click)="removeSelectedFile(index)" type="button" class="selectedBtn"><img
                                            src="assets/images/close.png" class="closeIcon"></button>
                                </div>
                                
                            </div>

                            <div class="submit-btn">
                                <button class="btn btn-primary" [disabled]="isLoading" type="submit"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>   
                                <button class="btn btn-warning" type="btn" (click)="cancelEmailTemplate()" >Cancel</button>
                             </div>
                        </div>
                    </form>

                </div>
            </div>                       
        </div>
    </div>
</div>
<!-- Container-fluid Ends -->

