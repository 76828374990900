import { Component, OnInit } from '@angular/core';
import { PitComplianceService } from '../../pit-compliance.service';

@Component({
  selector: 'app-view-shares-details',
  templateUrl: './view-shares-details.component.html',
  styleUrls: ['./view-shares-details.component.scss']
})
export class ViewSharesDetailsComponent implements OnInit {
  is_access_read = false;
  is_access_write = false;
  is_access_no = false;
  user_roles;
  public active1 = 1;
  preClearanceSharesValues: any = [];
  accessUser: any;
  filterString = "";
  filtered = [];
  constructor(private pitService: PitComplianceService) {
    this.accessUser = sessionStorage.getItem('access_user');
   }

  ngOnInit(): void {

    this.user_roles = JSON.parse(atob(sessionStorage.getItem('sdd_access_roles')));
    if (this.user_roles.preclearance_read == '2') {
      this.is_access_write = true;
      this.is_access_read = true;
      this.is_access_no = true;
    } else if (this.user_roles.preclearance_read == '1') {
      this.is_access_write = false;
      this.is_access_read = true;
      this.is_access_no = true;
    }
  let accessUser = {'name' : this.accessUser}
     this.pitService.preClearanceSharesValuesList(accessUser).subscribe(
          res => {
            this.filtered = [];
            this.preClearanceSharesValues = res.data;   
            this.filtered = res.data
          })
  }

  onFilterChange() {
    this.filtered = this.preClearanceSharesValues?.filter(res =>{    
     return res.tradingcompanyname.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase());
    }) 
   
  }

  isMatch(item) {
    if (item instanceof Object) {
      return Object.keys(item).some((k) => this.isMatch(item[k]));
    } else {
      return item?.toString().indexOf(this.filterString) > -1
    }
  }

}
