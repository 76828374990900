import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControlName, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
// import { Input } from 'hammerjs';
import { control } from 'leaflet';
import { ToastrService } from 'ngx-toastr';
import { AuthlogService } from '../authlog.service';
import { PasswordStrengthValidator } from "../../password-strength.validators"
@Component({
  selector: 'app-confirm-password',
  templateUrl: './confirm-password.component.html',
  styleUrls: ['./confirm-password.component.scss']
})
export class ConfirmPasswordComponent implements OnInit {
  
  @Input() msg = '';
  public newUser = false;
  public forgetPasswordForm: FormGroup;
  paramQuery: any;
  passwordIsValid = false;
  button = 'Update';
  isLoading = false;
  fieldTextType: boolean;
  fieldTextType2: boolean;
  hideForm: boolean =true;
  successMessage:boolean = false; 
  constructor(private fb: FormBuilder, private toastr: ToastrService, private authlog: AuthlogService, public router: Router, private activatedRoute: ActivatedRoute) {
   
   }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.paramQuery = params.authToken
    
    });


    this.forgetPasswordForm = this.fb.group({
      password: ['', Validators.compose([
        Validators.required, Validators.minLength(8), PasswordStrengthValidator])],
      conformPasswords: ['', Validators.compose([
        Validators.required, Validators.minLength(8), PasswordStrengthValidator])],
      auth_token: [this.paramQuery]
    },
   
    );
  }
get f(){
  return this.forgetPasswordForm.controls
}
  // MustMatch(controlName:string, matchingControlName: string){
  //   return(formGroup:FormGroup) =>{
  //     const control = formGroup.controls[controlName]
  //     const matchingControl = formGroup.controls[matchingControlName];
  //     if(matchingControl.errors && !matchingControl.errors.MustMatch){
  //       return;
  //     }
  //     if(control.value !== matchingControl.value ){
  //       matchingControl.setErrors({MustMatch:true});
  //     }else{
  //       matchingControl.setErrors(null);
  //     }
  //   }

  // }
onSubmit(){
  this.isLoading = true;
  this.button = 'Processing';
  if(this.forgetPasswordForm.status !== 'INVALID'){
  if(this.forgetPasswordForm.get('password').value === this.forgetPasswordForm.get('conformPasswords').value ){
    this.authlog.forgetChangePassword(this.forgetPasswordForm.value).subscribe(data =>{
     if(data.success !== 0){
      this.isLoading = false;
      this.button = 'Update';
      this.hideForm = false
      this.successMessage = true;

      // this.toastr.success("Password Changed Successfully!");  
      // this.router.navigate(['auth/login']);
     } else{
      this.toastr.error("Invalid User!"); 
      this.hideForm = true
      this.successMessage = false;
      this.isLoading = false;
      this.button = 'Update';
     }
    })
  }else{
    this.toastr.warning("Password Didn't Match");
    this.hideForm = true
    this.successMessage = false;
    this.isLoading = false;
    this.button = 'Update';
}
}else{
  if(this.forgetPasswordForm.get('password').value == ''){
    window.alert("Please fill the Password.");
    return false;
  }else if(this.forgetPasswordForm.get('conformPasswords').value == ''){
    window.alert("Please fill the Conform Password.");
    return false;
  }
}


}
passwordValid(event) {
  this.passwordIsValid = event;
 
}
toggleFieldTextType() {
  this.fieldTextType = !this.fieldTextType;
}
toggleFieldTextType2() {
  this.fieldTextType2 = !this.fieldTextType2;
}
}
