import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddTradingWindowClosureComponent } from './trading-window-closure/add-trading-window-closure/add-trading-window-closure.component';
import { DeleteTradingWindowClosureComponent } from './trading-window-closure/delete-trading-window-closure/delete-trading-window-closure.component';
import { EditTradingWindowClosureComponent } from './trading-window-closure/edit-trading-window-closure/edit-trading-window-closure.component';
import { ListTradingWindowClosureComponent } from './trading-window-closure/list-trading-window-closure/list-trading-window-closure.component';
import { ViewTradingWindowClosureComponent } from './trading-window-closure/view-trading-window-closure/view-trading-window-closure.component';
import { pitRoutingModule } from './pit-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartistModule } from 'ng-chartist';
import { NgChartsModule } from 'ng2-charts';
import { CountToModule } from 'angular-count-to';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AddPreClearanceComponent } from './pre-clearance/add-pre-clearance/add-pre-clearance.component';
import { EditPreClearanceComponent } from './pre-clearance/edit-pre-clearance/edit-pre-clearance.component';
import { ViewPreClearanceComponent } from './pre-clearance/view-pre-clearance/view-pre-clearance.component';
import { ListPreClearanceComponent } from './pre-clearance/list-pre-clearance/list-pre-clearance.component';
import { DeletePreClearanceComponent } from './pre-clearance/delete-pre-clearance/delete-pre-clearance.component';
import { ExcelReportComponent } from './excel-report/excel-report.component';
import { FileDirective } from './excel-report/file.directive';
import { FileDropDirective } from './excel-report/file-drop.directive';
import { MailComponentComponent } from './trading-window-closure/mail-component/mail-component.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { ExcelMultiReportComponent } from './excel-multi-report/excel-multi-report.component';
//drag-drop
import {A11yModule} from '@angular/cdk/a11y';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {PortalModule} from '@angular/cdk/portal';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {MatPaginatorModule} from '@angular/material/paginator';
import { NgSelectModule } from '@ng-select/ng-select';
import { BenposDashboardComponent } from './benpos-dashboard/benpos-dashboard.component';
import { AddBenposReportComponent } from './add-benpos-report/add-benpos-report.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { FormCAndFormDComponent } from './formCandD/form-c-and-form-d/form-c-and-form-d.component';
import { ListFormCAndFormDComponent } from './formCandD/list-form-c-and-form-d/list-form-c-and-form-d.component';
import { EditFormCAndFormDComponent } from './formCandD/edit-form-c-and-form-d/edit-form-c-and-form-d.component';
import { ViewFormCAndFormDComponent } from './formCandD/view-form-c-and-form-d/view-form-c-and-form-d.component';
import { DeleteFormCAndFormDComponent } from './formCandD/delete-form-c-and-form-d/delete-form-c-and-form-d.component';
import { PdfViewFormatComponent } from './formCandD/pdf-view-format/pdf-view-format.component';
import { PreClearanceMailTemplateComponent } from './pre-clearance/pre-clearance-mail-template/pre-clearance-mail-template.component';
import { BenposMailConfigComponent } from './benpos-mail-config/benpos-mail-config.component';
import { PrepdfViewFormatComponent } from './pre-clearance/prepdf-view-format/prepdf-view-format.component';
import { PreClearanceStatusComponent } from './pre-clearance/pre-clearance-status/pre-clearance-status.component';
import { PreClerranceRejectComponent } from './pre-clearance/pre-clerrance-reject/pre-clerrance-reject.component';
import { PreClearanceReApproveComponent } from './pre-clearance/pre-clearance-re-approve/pre-clearance-re-approve.component';
import { PreClearanceTradingComponent } from './pre-clearance/pre-clearance-trading/pre-clearance-trading.component';
import { AddPreviousTradingComponent } from './pre-clearance/add-previous-trading/add-previous-trading.component';
import { ViewPreviousTradingComponent } from './pre-clearance/view-previous-trading/view-previous-trading.component';
import { DeletePreviousTradingComponent } from './pre-clearance/delete-previous-trading/delete-previous-trading.component';
import { DisclosurePreclearanceComponent } from './pre-clearance/disclosure-preclearance/disclosure-preclearance.component';
import { DuplicatePreclearanceComponent } from './pre-clearance/duplicate-preclearance/duplicate-preclearance.component';
import { PreFormCComponentComponent } from './pre-clearance/pre-form-ccomponent/pre-form-ccomponent.component';
import { ApprovedPdfComponent } from './pre-clearance/approved-pdf/approved-pdf.component';
import { DisclosureExistTradingComponent } from './pre-clearance/disclosure-exist-trading/disclosure-exist-trading.component';
import { TradingAlertPopupComponent } from './pre-clearance/trading-alert-popup/trading-alert-popup.component';
import { IntimationAlertPopupComponent } from './pre-clearance/intimation-alert-popup/intimation-alert-popup.component';
import { ViewSharesDetailsComponent } from './pre-clearance/view-shares-details/view-shares-details.component';

@NgModule({
  declarations: [
    AddTradingWindowClosureComponent,
    DeleteTradingWindowClosureComponent,
    EditTradingWindowClosureComponent,
    ListTradingWindowClosureComponent,
    ViewTradingWindowClosureComponent,
    AddPreClearanceComponent,
    EditPreClearanceComponent,
    ViewPreClearanceComponent,
    ListPreClearanceComponent,
    DeletePreClearanceComponent,
    ExcelReportComponent,
    FileDirective,
    FileDropDirective,
    MailComponentComponent,
    ExcelMultiReportComponent,
    BenposDashboardComponent,
    AddBenposReportComponent,
    FormCAndFormDComponent,
    ListFormCAndFormDComponent,
    EditFormCAndFormDComponent,
    ViewFormCAndFormDComponent,
    DeleteFormCAndFormDComponent,
    PdfViewFormatComponent,
    PreClearanceMailTemplateComponent,
    BenposMailConfigComponent,
    PrepdfViewFormatComponent,
    PreClearanceStatusComponent,
    PreClerranceRejectComponent,
    PreClearanceReApproveComponent,
    PreClearanceTradingComponent,
    AddPreviousTradingComponent,
    ViewPreviousTradingComponent,
    DeletePreviousTradingComponent,
    DisclosurePreclearanceComponent,
    DuplicatePreclearanceComponent,
    PreFormCComponentComponent,
    ApprovedPdfComponent,
    DisclosureExistTradingComponent,
    TradingAlertPopupComponent,
    IntimationAlertPopupComponent,
    ViewSharesDetailsComponent,
  
    
  ],
  imports: [
    CommonModule,
    pitRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    AngularEditorModule,
    AngularMultiSelectModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgbModule,
    AutocompleteLibModule,
    A11yModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    PortalModule,
    ScrollingModule,
    MatPaginatorModule,
    DragDropModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    PdfViewerModule,
    NgSelectModule,
    ChartistModule,
    NgChartsModule,
    CountToModule,
    NgxChartsModule,
    MatProgressBarModule
  ],
  bootstrap: [BenposDashboardComponent]
})
export class PitComplianceModule { }
