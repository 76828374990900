import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from '../../master.service';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { EditReasonComponent } from '../../project/edit-reason/edit-reason.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-edit-mail-template',
  templateUrl: './edit-mail-template.component.html',
  styleUrls: ['./edit-mail-template.component.scss']
})
export class EditMailTemplateComponent implements OnInit {
  mailEditForm: FormGroup;
  mailOldEditForm: FormGroup;
  mailTemplateId: any;
  mailTemplateEditId: any;
  isLoading = false;
  button = 'Update';
  submitted: boolean = false; 
  editor = ClassicEditor;
  data: any;
  showButton: boolean;
  editReason: any;
  variablename = [];
  templatename: any;
  constructor(private fb: FormBuilder, private router:Router, private modalService: NgbModal, private toastr: ToastrService, private masterService: MasterService, private activateRouter:ActivatedRoute) { }

  ngOnInit(): void {
    this.activateRouter.params.subscribe(data =>{
      this.mailTemplateId = {'id' : data['id']};      
    })
    if(this.mailTemplateId['id'] !==''){
      
      this.masterService.mailTemplateEdit(this.mailTemplateId)
      .toPromise().then(result =>{ 
        this.mailTemplateEditId = result.data[0].id;
        let templatename = result.data[0].templatename;
        if(templatename == 'Annual Confirmation Email'){ 
          this.variablename = [
            {name: 'Name' , value :'$dpName'},
            {name: 'Designation' , value :'$dpDesignation'},
            {name: 'Email' , value :'$email'},
            {name: 'Identifier Type' , value :'$DocumentType'},
            {name: 'Identifier No' , value :'$dpPanNo'},
            {name: 'Address' , value :'$dpAddress'},
            {name: 'Educational Insitution of Dp' , value :'$dpEducationalInstitute'},
            {name: 'Past Employers of DP' , value :'$dpPastEmp'},
          ];
        }else if(templatename == 'UPSI Sender'){
          this.variablename = [
            {name: 'Sender Name' , value :'$sender_name'},
            {name: 'Sender PAN No' , value :'$sender_pan'},
            {name: 'Sender Email' , value :'$sender_email'},
            {name: 'Sender Address' , value :'$sender_address'},
            {name: 'Recipient Name' , value :'$recipient_name'},
            {name: 'Recipient PAN No' , value :'$recipient_pan'},
            {name: 'Recipient Email' , value :'$recipient_email'},
            {name: 'Recipient Address' , value :'$recipient_address'},
            {name: 'Description' , value :'$remarks'},
            {name: 'Purpose of sharing' , value :'$purpose_of_sharing'},
            {name: 'Nature of UPSI' , value :'$nature_of_upsi'},
            {name: 'Indicated Date of Sharing' , value :'$date_of_sharing'},
            {name: 'Date when it will be made public' , value :'$to_date'},
            {name: 'Company Name' , value :'$Company_name'},
            {name: 'Company Email' , value :'$Company_email'},
          ];
        }else if(templatename == 'UPSI Recipient'){
          this.variablename = [
            {name: 'Recipient Name' , value :'$recipient_name'},
            {name: 'Recipient PAN No' , value :'$recipient_pan'},
            {name: 'Recipient Email' , value :'$recipient_email'},
            {name: 'Recipient Address' , value :'$recipient_address'},
            {name: 'Sender Name' , value :'$sender_name'},
            {name: 'Sender PAN No' , value :'$sender_pan'},
            {name: 'Sender Email' , value :'$sender_email'},
            {name: 'Sender Address' , value :'$sender_address'},
            {name: 'Description' , value :'$remarks'},
            {name: 'Purpose of sharing' , value :'$purpose_of_sharing'},
            {name: 'Nature of UPSI' , value :'$nature_of_upsi'},
            {name: 'Indicated Date of Sharing' , value :'$date_of_sharing'},
            {name: 'Date when it will be made public' , value :'$to_date'},
            {name: 'Company Name' , value :'$Company_name'},
            {name: 'Company Email' , value :'$Company_email'},
          ];
        }
        this.mailEditForm?.patchValue(result.data[0]); 
        this.mailOldEditForm?.patchValue(result.data[0]); 

        this.mailEditForm.statusChanges.subscribe(status => {
          const isEqual = this.areObjectsEqual(this.mailEditForm.value, this.mailOldEditForm.value);
          if(!isEqual){
            this.showButton = true;
          }else{
            this.showButton = false;
          }
        });
  
      })
    this.mailEditForm = this.fb.group({
      templatename: new FormControl('',[Validators.required,Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')]),
      subject: new FormControl('',[Validators.required,Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')]),
      contents: new FormControl ('',[Validators.required,Validators.pattern(/^(\\'|[^'])*$/)]),
      
    });
    this.mailOldEditForm = this.fb.group({
      templatename: new FormControl('',[Validators.required,Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')]),
      subject: new FormControl('',[Validators.required,Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')]),
      contents: new FormControl ('',[Validators.required,Validators.pattern(/^(\\'|[^'])*$/)]),
      
    });
  }
  // this.variablename = [
  //   {name: 'Name' , value :'$dpName'},
  //   {name: 'Designation' , value :'$dpDesignation'},
  //   {name: 'Email' , value :'$email'},
  //   {name: 'Identifier Type' , value :'$DocumentType'},
  //   {name: 'Identifier No' , value :'$dpPanNo'},
  //   {name: 'Address' , value :'$dpAddress'},
  //   {name: 'Educational Insitution of Dp' , value :'$dpEducationalInstitute'},
  //   {name: 'Past Employers of DP' , value :'$dpPastEmp'},
  // ];
  }
  cancelMailTemplate(){
    this.router.navigate(['/setup/list-mail-template']);
    this.mailEditForm.reset();
  }
  areObjectsEqual(a: any, b: any): boolean {
    const aString = JSON.stringify(a);
    const bString = JSON.stringify(b);
  
    return aString === bString;
  }
  findChangedKeyValues(objA: any, objB: any): any {
    const resultA: any = {};
    const resultB: any = {};
  
    for (const key in objA) {
      if(key != 'id'){
        if (objA.hasOwnProperty(key) && objB.hasOwnProperty(key)) {
          if (JSON.stringify(objA[key]) !== JSON.stringify(objB[key])) {
            resultA[key] = objA[key];
            resultB[key] = objB[key];
          }
        } else {
          
            resultA[key] = objA[key];
          
        }
      }
    }
  
    for (const key in objB) {
      if (objB.hasOwnProperty(key) && !objA.hasOwnProperty(key)) {
        resultB[key] = objB[key];
      }
    }
  
    return {
      "oldData": resultA,
      "newData": resultB
    };
  }
  openModalEditReason() {
   
      const emptyFileds = Object.keys(this.mailEditForm.controls).filter(key =>this.mailEditForm.controls[key].errors !== null)
      if(emptyFileds.length > 1){
        this.toastr.warning("Please fill all Mandatory fields.");
        return false;
      } else{
        if(this.mailEditForm.get('templatename').value == ''){
          this.toastr.warning("Please fill the Template Name.");
          return false;
        }else if(this.mailEditForm.get('subject').value == ''){
          this.toastr.warning("Please fill the Subject.");
          return false;
        }else if(this.mailEditForm.get('contents').value == ''){
          this.toastr.warning("Please fill the Body Content.");
          return false;
        }
      }
    const isEqual = this.areObjectsEqual(this.mailEditForm.value, this.mailOldEditForm.value);
    if(!isEqual){
      const modalRef = this.modalService.open(EditReasonComponent,
        {
          scrollable: true,
          windowClass: 'myCustomModalClass',
        });
      modalRef.result.then((result) => {
        this.editReason=result;
        if(this.editReason != 'dismiss'){
          this.onSubmit();
        }
      }, (reason) => {
      });
    }
    
  }

  onSubmit(){
    const changedKeyValues = this.findChangedKeyValues(this.mailOldEditForm.value,this.mailEditForm.value);
    this.mailEditForm.value.id = this.mailTemplateEditId;

      let reqObj = {
        data:changedKeyValues,
        id:this.mailEditForm.value.id,
        editReason:this.editReason
      }
      this.submitted = true;  
    const emptyFileds = Object.keys(this.mailEditForm.controls).filter(key =>this.mailEditForm.controls[key].errors !== null)
    if(this.mailEditForm.status !== 'INVALID'){
      this.isLoading = true;
    this.button = 'Processing';
    this.masterService.mailTemplateUpdate(reqObj).subscribe(data =>{   
      this.toastr.success("Mail Template successfully Updated");
      this.isLoading = false;
      this.button = 'Update';
        this.router.navigate(['/setup/list-mail-template']);
     
    })
  }else{
    this.toastr.warning("Invalid Field....");
  }
  }


  //Angular Editor Setting
  htmlContent = '';

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

}
