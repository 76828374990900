<!-- Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 ">
            <div class="card">
                <div class="card-header">
                    <h5>ADD NEW DP</h5>
                    <a routerLink="/dp/dp-list"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                </div>
                <div class="card-body">
                    <form [formGroup]="dbCreateForm" class="needs-validation" (ngSubmit)="onSubmit()">
                        <div class="row">

                            <div class="col-sm-12 col-xl-12">
                                <div class="card">                                  
                                  <div class="card-body">
                                    <ul ngbNav #nav="ngbNav" [(activeId)]="active1"  class="nav-tabs">
                                      <li [ngbNavItem]="1">
                                        <a ngbNavLink>DP Details</a>
                                        <ng-template ngbNavContent>
                                          <div class="row">
                                            <div class="col-sm-12">
                                              <div class="tab-pane fade show " id="list-home" role="tabpanel"
                                                aria-labelledby="list-home-list">

                                                <div class="col-sm-12 col-xl-12">
                                                    <div class="card">                                                     
                                                      <div class="card-body">
                                                        <ngb-accordion [closeOthers]="true" activeIds="static-1">
                                                          <ngb-panel id="static-1">
                                                            <ng-template ngbPanelTitle>
                                                                <span>DP Basic Details</span>
                                                              </ng-template>
                                                            <ng-template ngbPanelContent>
                                                              <div class="container-fluid">
                                                                <div class="row">
                                                                    
                                                            <div class="col-md-6 mb-3">
                                                                <label for="name">Name <span class="manditary-filed">*</span></label>
                                                                <div class="form-group">
                                                                    <input type="text" class="form-control" id="name" placeholder="Name" autocomplete="off"
                                                                        formControlName="dpName" required
                                                                        [ngClass]="{ 'is-invalid': (submitted || dbCreateForm.controls.dpName.touched) && dbCreateForm.controls.dpName.errors }">
                                                                    <div *ngIf="(submitted || dbCreateForm.controls.dpName.touched) && dbCreateForm.controls.dpName.errors?.required"
                                                                        class="text text-danger">
                                                                        Name is required.
                                                                    </div>
                                                                    <div class="text text-danger" *ngIf="dbCreateForm.controls.dpName.errors?.pattern">Special Charactors Not Allowed!</div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 mb-3">
                                                                <label for="designationOfDb">Designation <span class="manditary-filed">*</span></label>
                                                                <div class="form-group">
                                                                    <input type="text" class="form-control" id="designationOfDb" autocomplete="off"
                                                                        placeholder="Designation" formControlName="dpDesignation" required
                                                                        [ngClass]="{ 'is-invalid': (submitted || dbCreateForm.controls.dpDesignation.touched) && dbCreateForm.controls.dpDesignation.errors }">
                                                                    <div *ngIf="(submitted || dbCreateForm.controls.dpDesignation.touched) && dbCreateForm.controls.dpDesignation.errors?.required"
                                                                        class="text text-danger">
                                                                        Designation is required.
                                                                    </div>
                                                                    <div class="text text-danger" *ngIf="dbCreateForm.controls.dpDesignation.errors?.pattern">Special Charactors Not Allowed!</div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 mb-3">
                                                                <label for="email">Email ID <span class="manditary-filed">*</span></label>
                                                                <div class="form-group">
                                                                    <input type="text" class="form-control" id="email"  placeholder="Email ID" autocomplete="off"
                                                                        formControlName="email" required
                                                                        [ngClass]="{ 'is-invalid': (submitted || dbCreateForm.controls.email.touched) && dbCreateForm.controls.email.errors }">
                                                                    <div *ngIf="(submitted || dbCreateForm.controls.email.touched) && dbCreateForm.controls.email.errors?.required"
                                                                        class="text text-danger">
                                                                        Email ID is required.
                                                                    </div>
                                                                    <div class="text text-danger" *ngIf="dbCreateForm.controls.email.errors?.pattern">
                                                                        Email must be a valid email address
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 mb-3">
                                                                <label for="contactNo">Contact Number <span class="manditary-filed">*</span></label>
                                                                <div class="form-group">
                                                                    <input type="text" class="form-control" id="contactNo" placeholder="Contact Number" autocomplete="off"
                                                                        formControlName="dpContactNo" required
                                                                        [ngClass]="{ 'is-invalid': (submitted || dbCreateForm.controls.dpContactNo.touched) && dbCreateForm.controls.dpContactNo.errors }">
                                                                    <div *ngIf="(submitted || dbCreateForm.controls.dpContactNo.touched) && dbCreateForm.controls.dpContactNo.errors?.required"
                                                                        class="text text-danger">
                                                                        Contact Number is required.
                                                                    </div>
                                                                    <div class="text text-danger" *ngIf="dbCreateForm.controls.dpContactNo.errors?.pattern">Enter the Valid Contact Number.</div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 mb-3">
                                                                <label for="panNo">ID Proof Type <span class="manditary-filed">*</span></label>
                                                                <div class="form-group">
                                                                    <select class="form-select" class="form-control form-select"
                                                                                        required id="DocumentType"
                                                                                        formControlName="DocumentType" (change)="chnageIdentifer()"
                                                                                        [ngClass]="{ 'is-invalid': (submitted || dbCreateForm.controls.DocumentType.touched) && dbCreateForm.controls.DocumentType.errors }">
                                                                                        <option value="" disabled selected>-- Choose One --</option>
                                                                                        <option value="PAN">PAN</option>
                                                                                        <option value="" disabled style="color: grey;">-- PAN Not Available --</option>
                                                                                        <option value="Aadhar card">Aadhar card</option>
                                                                                        <option value="Driving License">Driving License</option>
                                                                                        <option value="Passport">Passport</option>
                                                                                        <option value="Voter ID">Voter ID</option>
                                                                                        <!-- <option value="Others">Others</option> -->
                                                                                    </select>
                                                                    <!-- <input type="text" class="form-control" id="panNo" placeholder="Pan No"
                                                                        formControlName="DocumentType" required
                                                                        [ngClass]="{ 'is-invalid': (submitted || dbCreateForm.controls.DocumentType.touched) && dbCreateForm.controls.DocumentType.errors }"> -->
                                                                    <div *ngIf="(submitted || dbCreateForm.controls.DocumentType.touched) && dbCreateForm.controls.DocumentType.errors?.required"
                                                                        class="text text-danger">
                                                                        ID Proof Type is required.
                                                                    </div>
                                                                    <!-- <div *ngIf="dbCreateForm.controls.DocumentType.errors?.pattern "
                                                                        class="text text-danger">
                                                                        PAN Number is Not Valid.
                                                                    </div> -->
                                                                </div>
                                                                <div class="form-group">
                                                                    <label for="identiferNumber">ID Proof No<span class="manditary-filed">*</span></label>
                                                                    <input type="text" class="form-control" id="panNo" placeholder="ID Proof No" autocomplete="off"
                                                                        formControlName="dpPanNo"  required [ngClass]="{ 'is-invalid': (submitted || dbCreateForm.controls.dpPanNo.touched) && dbCreateForm.controls.dpPanNo.errors }"> 
                                                                        <div *ngIf="(submitted || dbCreateForm.controls.dpPanNo.touched) && dbCreateForm.controls.dpPanNo.errors?.required"
                                                                        class="text text-danger">
                                                                        identifer Number is required.
                                                                    </div>
                                                                    <div class="text text-danger" *ngIf="dbCreateForm.controls.dpPanNo.errors?.pattern">
                                                                        {{requiredValue}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        
        
                                                            <!-- <div class="col-md-6 mb-3">
                                                                <label for="withWhomDb">With Whom DP Shares</label>
                                                                <div class="form-group">
                                                                    <input type="text" class="form-control" id="withWhomDb"
                                                                        placeholder="With Whom DP Shares" formControlName="withWhom" required
                                                                        [ngClass]="{ 'is-invalid': (submitted || dbCreateForm.controls.withWhom.touched) && dbCreateForm.controls.withWhom.errors }">
                                                                    <div *ngIf="(submitted || dbCreateForm.controls.withWhom.touched) && dbCreateForm.controls.withWhom.errors?.required"
                                                                        class="text text-danger">
                                                                        With Whom DP Shares is required.
                                                                    </div>
                                                                </div>
                                                            </div> -->
                                                            <div class="col-md-6 mb-3">
                                                                <label for="address">Address <span class="manditary-filed">*</span></label>
                                                                <div class="form-group">
                                                                    <textarea class="form-control" id="address" rows="3" formControlName="dpAddress"
                                                                        placeholder="Address" required
                                                                        [ngClass]="{ 'is-invalid': (submitted || dbCreateForm.controls.dpAddress.touched) && dbCreateForm.controls.dpAddress.errors }"></textarea>
                                                                    <div *ngIf="(submitted || dbCreateForm.controls.dpAddress.touched) && dbCreateForm.controls.dpAddress.errors?.required"
                                                                        class="text text-danger">
                                                                        Address is required.
                                                                    </div>
                                                                    <div class="text text-danger" *ngIf="dbCreateForm.controls.dpAddress.errors?.pattern">Special Charactors Not Allowed!</div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 mb-3 selectLabel" *ngIf="openDpLabel == true">
                                                                <label for="projectName">Group Label Name</label>
                                                                <div class="form-group">
                                                                    <ng-multiselect-dropdown [placeholder]="'Select Label'"
                                                                    [settings]="dropdownSettings1"
                                                                    [data]="labelDataList"                                                                    
                                                                    [(ngModel)]="selectedLabelDataItems"
                                                                    (onSelect)="onItemSelect1($event, 'label')"
                                                                    (onDeSelect)="onItemDeSelect1($event, 'label')"
                                                                    (onSelectAll)="onSelectAll1($event, 'label')"
                                                                    (onDeSelectAll)="onDeSelectAll1($event, 'label')" formControlName="groupLabelName"
                                                                        >
                                                                        <div class="select-custom-content">
                                                                            <button class="btn-save btn btn-primary btn-sm">Save</button>
                                                                        </div>
                                                                    </ng-multiselect-dropdown>
                                                                </div>
                                                                <div class="plus-icon">
                                                                    <a href="javascript:void(0);" (click)="open()"><img src="assets/images/plus.png" class="img-responsive"></a>
                                                                </div>
                                                            </div>
                                                                </div>
                                                              </div>
                                                            </ng-template>
                                                          </ngb-panel>
                                                          
                                                          <ngb-panel id="static-2">
                                                            <ng-template ngbPanelTitle>
                                                              <span>Historical Details</span>
                                                            </ng-template>
                                                            <ng-template ngbPanelContent>
                                                             <div class="container-fluid">
                                                                <div class="row">
                                                                   
                                                            <div class="col-md-6 mb-3">
                                                                <label for="mobileNo">Educational Institute Name, Last Graduated From <span class="manditary-filed">*</span></label>
                                                                <div class="form-group">
                                                                    <input type="text" class="form-control" id="mobileNo"
                                                                        placeholder="Educational institution Name" formControlName="dpEducationalInstitute" autocomplete="off"
                                                                        required
                                                                        [ngClass]="{ 'is-invalid': (submitted || dbCreateForm.controls.dpEducationalInstitute.touched) && dbCreateForm.controls.dpEducationalInstitute.errors }">
                                                                    <div *ngIf="(submitted || dbCreateForm.controls.dpEducationalInstitute.touched) && dbCreateForm.controls.dpEducationalInstitute.errors?.required"
                                                                        class="text text-danger">
                                                                        Educational Institute Name, Last Graduated From is required.
                                                                    </div>
                                                                    <div class="text text-danger" *ngIf="dbCreateForm.controls.dpEducationalInstitute.errors?.pattern">Special Charactors Not Allowed!</div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 mb-3">
                                                                <label for="employersDp">Company Name of Previous Job <span class="manditary-filed">*</span></label>
                                                                <div class="form-group">
                                                                    <input type="text" class="form-control" id="employersDp"
                                                                        placeholder="Company Name of Previous Job" formControlName="dpPastEmp" required autocomplete="off"
                                                                        [ngClass]="{ 'is-invalid': (submitted || dbCreateForm.controls.dpPastEmp.touched) && dbCreateForm.controls.dpPastEmp.errors }">
                                                                    <div *ngIf="(submitted || dbCreateForm.controls.dpPastEmp.touched) && dbCreateForm.controls.dpPastEmp.errors?.required"
                                                                        class="text text-danger">
                                                                        Company Name of Previous Job is required.
                                                                    </div>
                                                                    <div class="text text-danger" *ngIf="dbCreateForm.controls.dpPastEmp.errors?.pattern">Special Charactors Not Allowed!</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                             </div>
                                                            </ng-template>
                                                          </ngb-panel>
                                                          <ngb-panel id="static-3" >
                                                            <ng-template ngbPanelTitle>
                                                                <span>Remarks</span>
                                                              </ng-template>
                                                            <ng-template ngbPanelContent>
                                                              <div class="container-fluid">
                                                                <div class="row">
                                                                    <div class="col-md-6 mb-3">
                                                                        <label for="remarks">Additional Remarks/Comments, if any</label>
                                                                        <div class="form-group">
                                                                            <textarea class="form-control" id="remarks" rows="3" formControlName="remarks"
                                                                                placeholder="Additional Remarks/Comments" ></textarea>
                                                                                <div class="text text-danger" *ngIf="dbCreateForm.controls.remarks.errors?.pattern">Special Charactors Not Allowed!</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                              </div>
                                                            </ng-template>
                                                          </ngb-panel>
                                                          <ngb-panel id="static-4" >
                                                            <ng-template ngbPanelTitle>
                                                                <span>Confidentiality Agreement Entered</span>
                                                              </ng-template>
                                                            <ng-template ngbPanelContent>
                                                              <div class="container-fluid">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-md-12 mb-3 confidentiality-btn">
                                                                        <div class="cols check-line1">
                                                                           
                                                                            <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                                                                <div class="radio radio-primary">
                                                                                    <input type="radio" name="radio3" id="radioinline5" value="Yes"
                                                                                        formControlName="confidentiality_agreement_entered" (click)="radioSelect2('Yes')"
                                                                                        [ngClass]="{ 'is-invalid': (submitted || dbCreateForm.controls.confidentiality_agreement_entered.touched) && dbCreateForm.controls.confidentiality_agreement_entered.errors }">
                                                                                    <label for="radioinline5" class="mb-0">Yes</label>
                                                                                </div>
                                                                                <div class="radio radio-primary">
                                                                                    <input type="radio" name="radio3" id="radioinline6" value="No"
                                                                                        formControlName="confidentiality_agreement_entered" (click)="radioSelect2('No')"
                                                                                        [ngClass]="{ 'is-invalid': (submitted || dbCreateForm.controls.confidentiality_agreement_entered.touched) && dbCreateForm.controls.confidentiality_agreement_entered.errors }">
                                                                                    <label for="radioinline6" class="mb-0">No</label>
                                                                                </div>
                                                                            </div>
                                                                            <div *ngIf="(submitted || dbCreateForm.controls.confidentiality_agreement_entered.touched) && dbCreateForm.controls.confidentiality_agreement_entered.errors?.required"
                                                                                class="text text-danger">
                                                                                Confidentiality Agreement entered is required.
                                                                            </div>
                                                                        </div>
                                                                        <div class="sellected-option">
                                                                            <div class="bottom-field" *ngIf="isSelected2('Yes')">
                                                                                <div class="row">
                                                                                    <div class="col-md-6 mb-3">
                                                                                        <label for="fromDate">Confidentiality Agreement Date</label>
                                                                                        <div class="form-group ">
                                                                                            <input class="form-control" placeholder="yyyy-mm-dd" type="date" autocomplete="off"
                                                                                                formControlName="confidentiality_agreement_date">                                                                                           
                                                                                        </div>
                                                                                    </div> 

                                                                                    <div class="col-md-6 mb-3">
                                                                                        <label class="custom-file-label" for="attachment">Confidentiality Agreement Copy</label>
                                                                                        <div class="form-group">
                                                                                            <input #userPhoto type="file" class="custom-file-input form-control" accept=".pdf"
                                                                                                id="attachment" formControlName="confidentiality_agreement_attachment"
                                                                                                (change)="changeListener($event)"
                                                                                                >
                                                                                        <p class="text text-danger">*Upload Files: .pdf</p>

                                                                                        </div>
                
                                                                                        <div *ngIf="attachmentPath">
                                                                                            <p class="attachmentPath">{{path}}</p>
                                                                                            <img src="assets/images/close.png" class="right-image" alt="Close"
                                                                                                (click)="removeFile()">
                                                                                        </div>
                
                                                                                        <div class="form-group" style="display:none;">
                                                                                            <input type="text" class="form-control" id="filePath" [(ngModel)]="path" 
                                                                                                placeholder="File Path" formControlName="filePath" >
                                                                                        </div>
                                                                                        <div class="form-group" style="display:none;">                                
                                                                                            <textarea rows="3" class="form-control" id="filePath" [(ngModel)]="pathImage" placeholder="File Path Link" formControlName="image" ></textarea>  
                                                                                        </div>
                                                                                    </div>
                
                                                                                                    
                                                                                </div>
                                                                            </div>
                                                                            <div class="bottom-field" *ngIf="isSelected2('No')">
                                                                                <div class="row"></div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                    </div>
                
                
                                                                </div>
                                                              </div>
                                                            </ng-template>
                                                          </ngb-panel>
                                                          <ngb-panel id="static53" >
                                                            <ng-template ngbPanelTitle>
                                                                <span>Name of Immediate Relative / Person Sharing Financial Material Relationship With DP</span>
                                                              </ng-template>
                                                            <ng-template ngbPanelContent>
                                                                <div class="dprelative-details">
                                                                   
                                                                    <div formArrayName="immediateDpRelative">
                                                                        <div *ngFor="let hobby of dbCreateForm.get('immediateDpRelative')['controls']; first as isFirst; index as idx">
                                                                            <div [formGroupName]="idx" class="input-row">
                                                                                <div class="inline-box">
                                                                                    <label for="name">Name</label>
                                                                                    <div class="form-group">
                                                                                        <input type="text" class="form-control" id="{{'name' + idx}}" autocomplete="off"
                                                                                            placeholder="name" formControlName="name" >
                                                                                        <!-- <div class="text text-danger"
                                                                                            *ngIf="(submitted || hobby['controls'].name?.touched) && hobby['controls'].name?.errors?.required">
                                                                                            Name is required</div> -->
                                                                                    </div> 
                                                                                </div>
                                                                                <div class="inline-box">
                                                                                    <label for="meetingType">Relationship</label>
                                                                                    <div class="form-group">
                                                                                        <select class="form-control form-select"
                                                                                             id="{{'relationship' + idx}}"
                                                                                            formControlName="relationship" >
                                                                                            <option value="" disabled selected>-- Choose One --</option>
                                                                                            <option value="Father">Father</option>
                                                                                            <option value="Mother">Mother</option>
                                                                                            <option value="Brother">Brother</option>
                                                                                            <option value="Sister">Sister</option>
                                                                                            <option value="Spouse">Spouse</option>
                                                                                            <option value="Father In Law">Father In Law</option>
                                                                                            <option value="Mother in Law">Mother in Law</option>
                                                                                            <option value="Son">Son</option>
                                                                                            <option value="Daughter">Daughter</option>
                                                                                            <option value="Step-son">Step-son</option>
                                                                                            <option value="Step-daughter">Step-daughter</option>
                                                                                            <option value="Material Relationship">Material Relationship</option>
                                                                                            <option value="Brother in Law">Brother in Law</option>
                                                                                            <option value="Sister in Law">Sister in Law</option>
                                                                                            <option value="Son in law">Son in law</option>
                                                                                            <option value="Daughter in law">Daughter in law</option>
                                                                                        </select>
                                                                                        <!-- <div class="text text-danger"
                                                                                            *ngIf="(submitted || hobby['controls'].relationship?.touched) && hobby['controls'].relationship?.errors?.required">
                                                                                            RelationShip is required</div> -->
                                    
                                                                                    </div>
                                                                                </div>
                                                                                <div class="inline-box">
                                                                                    <label for="mobileNo">Contact Number</label>
                                                                                    <div class="form-group">
                                                                                        <input type="phone" class="form-control" id="{{'phone_no' + idx}}" autocomplete="off"
                                                                                            placeholder="Mobile No" formControlName="phone_no"  >
                                                                                            <!-- <div class="text text-danger"
                                                                                            *ngIf="(submitted || hobby['controls'].phone_no?.touched) && hobby['controls'].phone_no?.errors?.required">
                                                                                            Mobile Number is required</div>
                                                                                            <div class="text text-danger" *ngIf="hobby['controls'].phone_no?.errors?.pattern">Please, Enter 10 digit Mobile Number.</div> -->
                                                                                            <div class="text text-danger" *ngIf="hobby['controls'].phone_no?.errors?.pattern">Enter the Valid Contact Number.</div>

                                                                                    </div>
                                                                                </div>
                                                                                <div class="inline-box">
                                                                                    <label for="email">ID Proof Type</label>
                                                                                    <div class="form-group">
                                                                                        <select class="form-control form-select"  id="panNo" formControlName="DocumentType" >
                                                                                        <option value="" disabled selected>-- Choose One --</option>
                                                                                        <option value="PAN">PAN</option>
                                                                                        <option value="" disabled style="color: grey;">-- PAN Not Available --</option>
                                                                                        <option value="Aadhar card">Aadhar card</option>
                                                                                        <option value="Driving License">Driving License</option>
                                                                                        <option value="Passport">Passport</option>
                                                                                        <option value="Voter ID">Voter ID</option>
                                                                                        <!-- <option value="Others">Others</option> -->
                                                                                    </select>                                                                                        
                                                                                        <!-- <div class="text text-danger"
                                                                                            *ngIf="(submitted || hobby['controls'].DocumentType?.touched) && hobby['controls'].DocumentType?.errors?.required">
                                                                                            Document Type is required</div> -->
                                                                                    </div>
                                                                                </div>
                                                                                <div class="inline-box">
                                                                                    <label for="email">ID Proof No</label>
                                                                                    <div class="form-group">
                                                                                        <input type="text" class="form-control" id="{{'pan + idx'}}" autocomplete="off"
                                                                                            placeholder="ID Proof No" formControlName="pan"  >
                                                                                        <!-- <div class="text text-danger"
                                                                                            *ngIf="(submitted || hobby['controls'].pan?.touched) && hobby['controls'].pan?.errors?.required">
                                                                                            Document Number is required</div> -->
                                                                                    </div>
                                                                                </div>
                                                                                <div class="inline-box">
                                                                                    <label for="email">Email ID.</label>
                                                                                    <div class="form-group">
                                                                                        <input type="text" class="form-control" id="{{'relativeemail + idx'}}" autocomplete="off"
                                                                                            placeholder="Email ID" formControlName="relativeemail"  >
                                                                                        <!-- <div class="text text-danger"
                                                                                            *ngIf="(submitted || hobby['controls'].pan?.touched) && hobby['controls'].pan?.errors?.required">
                                                                                            Document Number is required</div> -->
                                                                                    </div>
                                                                                </div>
                                    
                                                                                <!-- <span style="min-width:28px;"><span (click)="addInputControls()"
                                                                                        *ngIf="idx === 0"><i class="fa fa-plus-circle"
                                                                                            aria-hidden="true"></i></span></span>
                                                                                <span (click)="removeInputControls(idx)"
                                                                                    *ngIf="dbCreateForm.get('immediateDpRelative').length > 1"><i
                                                                                        class="fa fa-minus-circle" aria-hidden="true"></i></span> -->
                                                                                <span style="min-width:28px;"><span (click)="addInputControls()"
                                                                                       *ngIf="idx === 0" ><i class="fa fa-plus-circle"
                                                                                            aria-hidden="true"></i></span></span>
                                                                                <span (click)="removeInputControls(idx)" ><i
                                                                                        class="fa fa-minus-circle" aria-hidden="true"></i></span>
                                    
                                    
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-template>
                                                          </ngb-panel>
                                                          <ngb-panel id="static-6" *ngIf="(preclearanceAccess == true && is_access_write == true)">
                                                            <ng-template ngbPanelTitle>
                                                              <span>Additional Details</span>
                                                            </ng-template>
                                                            <ng-template ngbPanelContent>
                                                             <div class="container-fluid">
                                                                <div class="row">
                                                                
                                                                   
                                                            <div class="col-md-6 mb-3">
                                                                <label for="dpId">Dp Id </label>
                                                                <div class="form-group">
                                                                    <input type="text" class="form-control" id="dpId" placeholder="Enter your Dp Id" autocomplete="off"
                                                                        formControlName="dpIndexId" >
                                                                        <div class="text text-danger" *ngIf="dbCreateForm.controls.dpIndexId.errors?.maxlength">Please, Enter DP ID 8 Digits Charactors.</div>
                                                                        <div class="text text-danger" *ngIf="dbCreateForm.controls.dpIndexId.errors?.minlength">Please, Enter DP ID 8 Digits Charactors.</div>
                                                                        <div class="text text-danger" *ngIf="dbCreateForm.controls.dpIndexId.errors?.pattern">Special Charactors Not Allowed!</div>
                                                                </div>
                                                            </div>
                                                          <div class="col-md-6 mb-3">
                                                                <label for="dpClientId">Client Id </label>
                                                                <div class="form-group">
                                                                    <input type="text" class="form-control" id="dpClientId" placeholder="Enter your Client Id" autocomplete="off"
                                                                        formControlName="dpClientId" >
                                                                        <div class="text text-danger" *ngIf="dbCreateForm.controls.dpClientId.errors?.maxlength">Please, Enter Client ID 8 Digits Charactors.</div>
                                                                        <div class="text text-danger" *ngIf="dbCreateForm.controls.dpClientId.errors?.minlength">Please, Enter Client ID 8 Digits Charactors.</div>
                                                                        <div class="text text-danger" *ngIf="dbCreateForm.controls.dpClientId.errors?.pattern">Special Charactors Not Allowed!</div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 mb-3">
                                                                <div class="row mb-3 row1">
                                                                    <label for="categoryPerson">Category of Person</label>                                                                   
                                                                        <div class="form-group">                    
                                                                            <select class="form-select" class="form-control form-select" id="categoryPerson" formControlName="categoryofperson" (click)="otherCategoryOfPersonFormC()"  [ngClass]="{ 'is-invalid': (submitted || dbCreateForm?.controls.categoryofperson.touched) && dbCreateForm.controls.categoryofperson.errors }">
                                                                                <option value="" disabled selected>--Choose One --</option>
                                                                                <option value="Directors">Directors</option>                                                                               
                                                                                <option value="Employees designed based on their role">Employees designed based on their role</option>                                    
                                                                                <option value="Employees having UPSI">Employees having UPSI</option>                                    
                                                                                <option value="Employees of material subsidiaries having UPSI">Employees of material subsidiaries having UPSI</option>                                    
                                                                                <option value="Promoter">Promoter</option>
                                                                                <option value="Chief Executive Officer">Chief Executive Officer</option>                                    
                                                                                <option value="2 levels below CEO">2 levels below CEO</option>
                                                                                <option value="2 levels below CEO of material subsidiary company">2 levels below CEO of material subsidiary company</option>
                                                                                <option value="Head of the organization for intermediary or fiduciary">Head of the organization for intermediary or fiduciary</option>
                                                                                <option value="Support staff">Support staff</option>
                                                                                <!-- <option value="Immediate relative of">Immediate relative of</option>                                     -->
                                                                                <option value="Others">Others</option>                                    
                                                                            </select>
                                                                            <div *ngIf="(submitted || dbCreateForm.controls.categoryofperson.touched) && dbCreateForm.controls.categoryofperson.errors?.required"
                                                                            class="text text-danger">Category of Person is required. </div>
                                                                        </div>
                                                                    
                                                                </div>
                                                                <div class="col-md-12" *ngIf="otherscategoryPersonFormC">                                                                     
                                                                    <label for="typeOfSecuritiesValue">Others Category of Person: </label>                                                                        
                                                                    <div class="form-group">                    
                                                                        <input type="text" class="form-control" id="otherstransactiontype" placeholder="Enter the Others Category of Person" autocomplete="off" formControlName="otherscategoryofperson"> 
                                                                        <div class="text text-danger" *ngIf="dbCreateForm.controls.otherscategoryofperson.errors?.pattern">Special Charactors Not Allowed!</div>

                                                                    </div>                           
                                                                </div> 
                                                            </div>                          
                                                            
                                                            
                                                                </div>
                                                             </div>
                                                            </ng-template>
                                                          </ngb-panel>
                                                        </ngb-accordion>
                                                      </div>
                                                    </div>
                                                  </div>

                        
                                         </div> 
                            
                                                
                                                
                                                </div>
                                            </div>
                                      
                                        </ng-template>
                                      </li>
                                      <!-- <li [ngbNavItem]="2">
                                        <a ngbNavLink>Annual Confirmation</a>
                                        <ng-template ngbNavContent>
                                          <div class="row">
                                            <div class="col-sm-12">
                                              <div class="tab-pane fade show active" id="list-home" role="tabpanel"
                                                aria-labelledby="list-home-list">
                                                <div class="card-body">
                                                <div class="dprelative-details annual-conformation">                                                  
                                                    <div formArrayName="annualConfirmation">
                                                        <div
                                                            *ngFor="let hobby of dbCreateForm.get('annualConfirmation')['controls']; first as isFirst; index as idx">
                                                            <div [formGroupName]="idx" class="input-row">
                                                                <div class="inline-box">
                                                                    <label for="name">Financial Year</label>
                                                                    <div class="form-group" style="display: flex;">
                                                                        <input type="text" class="form-control fy" id="{{'financialYearSymbol' + idx}}"  autocomplete="off"
                                                                            placeholder="FY" formControlName="financialYearSymbol" disabled>
                                                                        <input type="text" class="form-control fyear" id="{{'financialYear' + idx}}"  autocomplete="off"
                                                                            placeholder="Financial Year. ex: 1999-2000" formControlName="financialYear" >                                                                       
                                                                    </div>
                                                                </div>
                                                                <div class="inline-box">
                                                                    <label for="lastConformedDate">Last Confirmed Date</label>
                                                                    <div class="form-group">
                                                                        <input class="form-control" id="{{'lastConformedDate' + idx}}" placeholder="yyyy-mm-dd" type="date" formControlName="lastConformedDate" >                                                                       
                                                                    </div>
                                                                </div>       
                    
                                                                <span style="min-width:28px;"><span (click)="addInputControlsAnnual()"
                                                                        *ngIf="idx === 0"><i class="fa fa-plus-circle"
                                                                            aria-hidden="true"></i></span></span>
                                                                <span (click)="removeInputControlsAnnual(idx)"
                                                                    *ngIf="dbCreateForm.get('annualConfirmation').length > 1"><i
                                                                        class="fa fa-minus-circle" aria-hidden="true"></i></span>
                    
                    
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                                </div>
                                            </div>
                                          </div>
                                        </ng-template>
                                      </li> -->
                                      <li [ngbNavItem]="2" >
                                        <a ngbNavLink >Access to SDD</a>
                                        <ng-template ngbNavContent>
                                          <div class="row">
                                            <div class="col-sm-12">
                                              <div class="tab-pane fade show active" id="list-home" role="tabpanel"
                                                aria-labelledby="list-home-list">
                                                    <div class="card-body">
                                                        <div class="access-details">
                                                            <h4>Access to SDD <span class="manditary-filed">*</span></h4>
                                                            <div class="row">
                                                                <div class="col-md-12 mb-3">
                                                                    <div class="cols check-line">
                                                                        <!-- <label for="nationality">Access to SDD</label> -->
                                                                        <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                                                            <div class="radio radio-primary">
                                                                                <input type="radio" name="radio2" id="radioinline3" value="Active"
                                                                                    formControlName="accessToSdd" (click)="radioSelect('Yes')">
                                                                                <label for="radioinline3" class="mb-0">Yes</label>
                                                                            </div>
                                                                            <div class="radio radio-primary">
                                                                                <input type="radio" name="radio2" id="radioinline4" value="Inactive" formControlName="accessToSdd" (click)="radioSelect('No')">
                                                                                <label for="radioinline4" class="mb-0">No</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                    
                                                                    <div class="sellected-option">
                                                                        <div class="bottom-field" *ngIf="isSelected('Yes')">
                                                                            <div class="row">
                                                                                <div class="col-md-6 mb-3">
                                                                                    <label for="meetingType">User Type</label>
                                                                                    <div class="form-group lstRole">
                                                                                        <!-- <select class="form-select" class="form-control form-select" #teams
                                                                                             id="role"
                                                                                            formControlName="role" (change)="onSelected()">
                                                                                            <option value="" disabled selected>-- Choose One --</option>
                                                                                            <option *ngFor="let role of listroles.data" value="{{role.role_name}}">{{role.role_name}}</option>
                                                                                          
                                                                                        </select> -->
                                                                                        <ng-select [items]="rolesNameFilter" #teams
                                                                                                bindLaberolesNameFilterl=""
                                                                                                placeholder="Enter the Role Name"
                                                                                                [(ngModel)]="selectedRoleName" formControlName="role" (change)="onSelected($event)">
                                                                                        </ng-select>
                                                                                        <!-- <div class="ng-autocomplete">
                                                                                            <ng-autocomplete 
                                                                                              [data]="rolesNameFilter"
                                                                                              [searchKeyword]="keyword"
                                                                                              placeholder="Enter the Role Name" 
                                                                                              (selected)='selectEvent($event)'
                                                                                              (inputChanged)='onChangeSearch($event)'
                                                                                              (inputFocused)='onFocused($event)'                                         
                                                                                              [itemTemplate]="itemTemplate"
                                                                                              [notFoundTemplate]="notFoundTemplate" #teams formControlName="role" (selected)="onSelected($event)">
                                                                                            </ng-autocomplete>
                                                                                           
                                                                                            <ng-template #itemTemplate let-item>
                                                                                              <div class="item">
                                                                                                <span [innerHTML]="item"></span> 
                                                                                              </div>
                                                                                            </ng-template>
                                                                                          
                                                                                            <ng-template #notFoundTemplate let-notFound>
                                                                                              <div [innerHTML]="notFound"></div>
                                                                                            </ng-template>
                                                                                          </div> -->
                                                                                    </div>
                                                                                    
                                                                                </div>
                                                                                <div class="col-md-6 mb-3">
                                                                                    <div class="sellected-option">
                                                                                        <label for="projectAccess" class="mb-0">Project Access</label>
                                                                                        <div class="bottom-field" >
                                                                                            <div class="form-group">
                                                                                                <angular2-multiselect [data]="dropdownList"
                                                                                                    [(ngModel)]="selectedItems"
                                                                                                    [settings]="dropdownSettings"
                                                                                                    (onSelect)="onItemSelect($event)"
                                                                                                    (onDeSelect)="OnItemDeSelect($event)"
                                                                                                    (onSelectAll)="onSelectAll($event)"
                                                                                                    (onDeSelectAll)="onDeSelectAll($event)"
                                                                                                    formControlName="access_project" id="access_project"
                                                                                                    class="multi-select-int" formControlName="project">
                                                                                                </angular2-multiselect>
                                                                                                
                                                                                            </div>
                                                                                            <div class="form-group m-checkbox-inline mb-0 ms-1">
                                                                                                <div class="checkbox checkbox-primary">
                                                                                                <input id="inline-form-1" type="checkbox" formControlName="self_records" name="self">
                                                                                                <label class="m-b-0" for="inline-form-1">Show only records where DP is participant</label>
                                                                                                </div>
                                                                                            
                                                                                            </div>
                                                                                        </div>
                                    
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="bottom-field" *ngIf="isSelected('No')">
                                                                            <div class="row" style="display: none;"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                            
                            
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                          </div>
                                        </ng-template>
                                      </li>
                                    </ul>
                                    <div [ngbNavOutlet]="nav" class="mt-2"></div>
                                  </div>
                                </div>
                              </div>
                            
                              <!-- <span (click)="open()">Add Label</span>  
                              <div class="col-md-12 offsaet-md-1">
                                <ol>
                                <li *ngFor="let t of labelData; let i = index"> {{ t }}<a href="#" (click) = "removeTask(i)">X</a></li>
                                </ol>
                              </div> -->

                            <div class="submit-btn">
                                <button class="btn btn-primary" [disabled]="isLoading" type="submit"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>
                                <button class="btn btn-warning" type="submit" (click)="cancelDpList()">Cancel</button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>


    
</div>
<!-- Container-fluid Ends -->
