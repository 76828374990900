import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from '../../master.service';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { EditReasonComponent } from '../../project/edit-reason/edit-reason.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-edit-actemplate',
  templateUrl: './edit-actemplate.component.html',
  styleUrls: ['./edit-actemplate.component.scss']
})
export class EditActemplateComponent implements OnInit {

  mailEditForm: FormGroup;
  mailOldEditForm: FormGroup;
  mailTemplateId: any;
  mailTemplateEditId: any;
  isLoading = false;
  button = 'Update';
  submitted: boolean = false; 
  editor = ClassicEditor;
  data: any;
  showButton: boolean;
  editReason: any;
  selectedFile = [];
  pathImage: any[];
  selectedFileName: any[] = [];
  mailTemplateaceid: any;
  removeList: any = [];
  allergy = '';
  variablename = [];
  constructor(private fb: FormBuilder, private router:Router, private modalService: NgbModal, private toastr: ToastrService, private masterService: MasterService, private activateRouter:ActivatedRoute) { }

  ngOnInit(): void {
    this.activateRouter.params.subscribe(data =>{
      this.mailTemplateId = {'id' : data['id']};      
    })
    if(this.mailTemplateId['id'] !==''){
      
      this.masterService.acmailTemplateEdit(this.mailTemplateId)
      .toPromise().then(result =>{ 
        this.mailTemplateEditId = result.data[0].id;
        this.mailTemplateaceid = result.data[0].aceid;
        this.selectedFileName = result.data[0].aggreement_attachment;

        

        this.mailEditForm?.patchValue(result.data[0]); 
        this.mailOldEditForm?.patchValue(result.data[0]); 
        //this.mailOldEditForm.value.attachmentfilename = result.data[0].aggreement_attachment;

        this.mailEditForm.statusChanges.subscribe(status => {
          const isEqual = this.areObjectsEqual(this.mailEditForm.value, this.mailOldEditForm.value);
          if(!isEqual){
            this.showButton = true;
          }else{               
            this.showButton = false;
          }
        });
  
      })
      
    this.mailEditForm = this.fb.group({
      templatename: new FormControl('',[Validators.required,Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')]),
      subject: new FormControl('',[Validators.required,Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')]),
      htmlcontents: new FormControl ('',[Validators.required,Validators.pattern(/^(\\'|[^'])*$/)]),
      agreement_attachment_file: new FormControl(),
      aggreement_attachment: new FormControl(),
      attachmentfilename: new FormControl(),
      attachmentremovelist: new FormControl(),
    });
    this.mailOldEditForm = this.fb.group({
      templatename: new FormControl('',[Validators.required,Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')]),
      subject: new FormControl('',[Validators.required,Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')]),
      htmlcontents: new FormControl ('',[Validators.required,Validators.pattern(/^(\\'|[^'])*$/)]),
      agreement_attachment_file: new FormControl(),
      aggreement_attachment: new FormControl(),
      attachmentfilename: new FormControl(),
      attachmentremovelist: new FormControl(),
    });
  }
  this.onFileChanged(event);

  this.variablename = [
    {name: 'Name' , value :'$dpName'},
    {name: 'Designation' , value :'$dpDesignation'},
    {name: 'Email' , value :'$email'},
    {name: 'Identifier Type' , value :'$DocumentType'},
    {name: 'Identifier No' , value :'$dpPanNo'},
    {name: 'Address' , value :'$dpAddress'},
    {name: 'Educational Insitution of Dp' , value :'$dpEducationalInstitute'},
    {name: 'Past Employers of DP' , value :'$dpPastEmp'},
  ];
  }
   //attachement file
   onFileChanged(event: any) {
    
    if (event?.target.files && event?.target.files[0]) {
      var filesAmount = event?.target.files.length;

      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        //this.selectedFileName.push(event?.target.files[i].name)
        this.selectedFileName.push(event?.target.files[i].name);
        console.log("selectedFileName", this.selectedFileName)
        //this.fileList.push(event?.target.files[i].name);

        reader.onload = (event: any) => {
          this.selectedFile?.push(event.target.result);

        };

        reader.readAsDataURL(event.target.files[i]);
      }
    }
    this.mailEditForm.value.aggreement_attachment = this.selectedFile;
    this.mailEditForm.value.attachmentfilename = this.selectedFileName;

  }
  removeSelectedFile(index,item) {
    // Delete the item from fileNames list
    this.removeList.push(item);
    // delete file from FileList
    this.selectedFileName?.splice(index, 1);
    this.mailEditForm.value.aggreement_attachment = this.selectedFile;
    this.mailEditForm.value.attachmentfilename = this.selectedFileName;
    this.mailEditForm.value.attachmentremovelist = this.removeList;
  }
  cancelMailTemplate(){
    this.router.navigate(['/setup/list-ac-template']);
    this.mailEditForm.reset();
  }
  areObjectsEqual(a: any, b: any): boolean {
    const aString = JSON.stringify(a);
    const bString = JSON.stringify(b);
  
    return aString === bString;
  }
  findChangedKeyValues(objA: any, objB: any): any {
    const resultA: any = {};
    const resultB: any = {};
  
    for (const key in objA) {
      if(key != 'id'){
        if (objA.hasOwnProperty(key) && objB.hasOwnProperty(key)) {
          if (JSON.stringify(objA[key]) !== JSON.stringify(objB[key])) {
            resultA[key] = objA[key];
            resultB[key] = objB[key];
          }
        } else {
          
            resultA[key] = objA[key];
          
        }
      }
    }
  
    for (const key in objB) {
      if (objB.hasOwnProperty(key) && !objA.hasOwnProperty(key)) {
        resultB[key] = objB[key];
      }
    }
  
    return {
      "oldData": resultA,
      "newData": resultB
    };
  }
  openModalEditReason() {
      const emptyFileds = Object.keys(this.mailEditForm.controls).filter(key =>this.mailEditForm.controls[key].errors !== null)
      if(emptyFileds.length > 1){
        this.toastr.warning("Please fill all Mandatory fields.");
        return false;
      } else{
        if(this.mailEditForm.get('templatename').value == ''){
          this.toastr.warning("Please fill the Template Name.");
          return false;
        }else if(this.mailEditForm.get('subject').value == ''){
          this.toastr.warning("Please fill the Subject.");
          return false;
        }else if(this.mailEditForm.get('htmlcontents').value == ''){
          this.toastr.warning("Please fill the Body Content.");
          return false;
        }
      }
    const isEqual = this.areObjectsEqual(this.mailEditForm.value, this.mailOldEditForm.value);
    if(!isEqual){
      const modalRef = this.modalService.open(EditReasonComponent,
        {
          scrollable: true,
          windowClass: 'myCustomModalClass',
        });
      modalRef.result.then((result) => {
        // console.log("result", result);
        this.editReason=result;
        if(this.editReason != 'dismiss'){
          this.onSubmit();
        }
      }, (reason) => {
      });
    }
    
  }
 
  onSubmit(){
    console.log(this.mailEditForm.value);

    const changedKeyValues = this.findChangedKeyValues(this.mailOldEditForm.value,this.mailEditForm.value);
    this.mailEditForm.value.id = this.mailTemplateEditId;

      let reqObj = {
        data:changedKeyValues,
        id:this.mailEditForm.value.id,
        aceid:this.mailTemplateaceid,
        editReason:this.editReason
      }
     
      this.submitted = true;  
    const emptyFileds = Object.keys(this.mailEditForm.controls).filter(key =>this.mailEditForm.controls[key].errors !== null)
    if(this.mailEditForm.status !== 'INVALID'){
      this.isLoading = true;
    this.button = 'Processing';
    this.masterService.acmailTemplateUpdate(reqObj).subscribe(data =>{   
      this.toastr.success("Annual Confirmation Initiate Template successfully Updated");
      this.isLoading = false;
      this.button = 'Update';
        this.router.navigate(['/setup/list-ac-template']);
     
    })
  }else{
    this.toastr.warning("Invalid Field....");
  }
  }


  //Angular Editor Setting
  htmlContent = '';

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      // ['bold']
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

}
