import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Item } from 'angular2-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { DpModuleService } from 'src/app/dp-module/dp-module.service';

@Component({
  selector: 'app-entrymessage',
  templateUrl: './entrymessage.component.html',
  styleUrls: ['./entrymessage.component.scss']
})
export class EntrymessageComponent implements OnInit {
  @Input() name;
  public active1 = 1;
  revetDpForm: FormGroup;
  getIdDp: any;
  getEmailDp: any;
  financialyear: string;
  listDpModule: any;
  accessUser: string;
  annualConformationData: any;
  listDpannualReport: any[];
  button = 'Confirm';
  isLoading = false;
  newDate = new Date();
  currentDate: any;
  last: any;
  hideButton:boolean = false;
  isValid: boolean;
  financialYearEntry: any;
  constructor(private modalService: NgbModal,
    public activeModal: NgbActiveModal, private dpService:DpModuleService, private router:Router, private fb: FormBuilder, private toastr: ToastrService ) {
      this.accessUser = sessionStorage.getItem('access_user');
     }

  ngOnInit() {
    let accessUser = sessionStorage.getItem('access_user');
    let d = new Date();
    this.financialYearEntry = d.getMonth() < 3 ? d.getFullYear() - 1 : d.getFullYear();
    var fiscalyear_ = "";
    var today = new Date();
    if ((today.getMonth() + 1) <= 3) {
      fiscalyear_ = ((today.getFullYear() - 1) + "") + "-" + (today.getFullYear() + "").slice(-2)
    } else {
      fiscalyear_ = (today.getFullYear() + "") + "-" + ((today.getFullYear() + 1) + "").slice(-2)
    }
    this.financialYearEntry = fiscalyear_
  }

  closeModal(sendData) {
    if(sendData == ''){
      this.isValid=false;
    }else{
      this.activeModal.close(sendData);
    }
    
  }

}
