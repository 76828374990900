import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { masterRoutingModule } from '../master/master-routing.module'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AddProjectComponent } from './project/add-project/add-project.component';
import { EditProjectComponent } from './project/edit-project/edit-project.component';
import { ListProjectComponent } from './project/list-project/list-project.component';
import { DeleteProjectComponent } from './project/delete-project/delete-project.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { CompanyListComponent } from './company/company-list/company-list.component';
import { AddRolesComponent } from './role-permission/add-roles/add-roles.component';
import { EditRolesComponent } from './role-permission/edit-roles/edit-roles.component';
import { DeleteRolesComponent } from './role-permission/delete-roles/delete-roles.component';
import { PermissionListComponent } from './role-permission/permission-list/permission-list.component';
import { RolePermissionComponent } from './role-permission/role-permission/role-permission.component';
import { CompanyViewComponent } from './company/company-view/company-view.component';
 import { ActivityLogComponent } from './activity-log/activity-log.component';
import { ViewProjectComponent } from './project/view-project/view-project.component';
import { AddCompanyComponent } from './company/add-company/add-company.component';
import { DeleteCompanyComponent } from './company/delete-company/delete-company.component';
import { EditCompanyComponent } from './company/edit-company/edit-company.component';
import { UpsiActivityLogComponent } from './view-activity-logs/upsi-activity-log/upsi-activity-log.component';
import { InsiderActivityLogComponent } from './view-activity-logs/insider-activity-log/insider-activity-log.component';
import { DpActivityLogComponent } from './view-activity-logs/dp-activity-log/dp-activity-log.component';
import { PopupModelComponentComponent } from './project/popup-model-component/popup-model-component.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AddMailTemplateComponent } from './mail-template/add-mail-template/add-mail-template.component';
import { ListMailTemplateComponent } from './mail-template/list-mail-template/list-mail-template.component';
import { EditMailTemplateComponent } from './mail-template/edit-mail-template/edit-mail-template.component';
import { DeleteMailTempateComponent } from './mail-template/delete-mail-tempate/delete-mail-tempate.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ViewMailTemplateComponent } from './mail-template/view-mail-template/view-mail-template.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { RevertProjectComponent } from './project/revert-project/revert-project.component';
import { EditReasonComponent } from './project/edit-reason/edit-reason.component';
import { DeletedProjectModuleComponent } from './project/deleted-project-module/deleted-project-module.component';
import { ProjectActivityLogsComponent } from './view-activity-logs/project-activity-logs/project-activity-logs.component';
import { AddCompanyrulesComponent } from './company-rules/add-companyrules/add-companyrules.component';
import { ListedCompanyComponent } from './list-company/listed-company/listed-company.component';
import { AddCompanyListComponent } from './list-company/add-company-list/add-company-list.component';
import { AddRestrictedCompanyComponent } from './list-company/add-restricted-company/add-restricted-company.component';
import { ListCompanyrulesComponent } from './company-rules/list-companyrules/list-companyrules.component';
import { ViewCompanyrulesComponent } from './company-rules/view-companyrules/view-companyrules.component';
import { EditCompanyRulesComponent } from './company-rules/edit-company-rules/edit-company-rules.component';
import { DeleteCompanyRulesComponent } from './company-rules/delete-company-rules/delete-company-rules.component';
import { DeleteCompanyListComponent } from './list-company/delete-company-list/delete-company-list.component';
import { EditCompanyListComponent } from './list-company/edit-company-list/edit-company-list.component';
import { DeleteRuleComponent } from './company-rules/delete-rule/delete-rule.component';
import { AddMailSettingComponent } from './mail-setting/add-mail-setting/add-mail-setting.component';
import { NonUpsiActivityLogComponent } from './view-activity-logs/non-upsi-activity-log/non-upsi-activity-log.component';
import { UserLogComponent } from './user-log/user-log/user-log.component';
import { SystemInfoComponent } from './system-info/system-info/system-info.component';
import { SysteminfoViewComponent } from './system-info/systeminfo-view/systeminfo-view.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DisclaimerexcelComponent } from './popup/disclaimerexcel/disclaimerexcel.component';
import { RestorereasonComponent } from './popup/restorereason/restorereason.component';
import { CustomerActivityLogComponent } from './view-activity-logs/customer-activity-log/customer-activity-log.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { UsersListComponent } from './users/users-list/users-list.component';
import { CronAnnualConfiramationComponent } from './cron-ac-msg/cron-annual-confiramation/cron-annual-confiramation.component';
import { EntrymessageComponent } from './popup/entrymessage/entrymessage.component';
import { EditActemplateComponent } from './annualconformationtemplate/edit-actemplate/edit-actemplate.component';
import { ViewActemplateComponent } from './annualconformationtemplate/view-actemplate/view-actemplate.component';
import { ListActemplateComponent } from './annualconformationtemplate/list-actemplate/list-actemplate.component';
import { DeleteActemplateComponent } from './annualconformationtemplate/delete-actemplate/delete-actemplate.component';
import { PreclearanceDesclaimerComponent } from './popup/preclearance-desclaimer/preclearance-desclaimer.component';

@NgModule({
  declarations: [
   
    AddRolesComponent,
    EditRolesComponent,
    DeleteRolesComponent,
    PermissionListComponent,
    RolePermissionComponent,
    AddProjectComponent,
    DeleteProjectComponent,
    EditProjectComponent,
    ListProjectComponent,
    ViewProjectComponent,
    ActivityLogComponent,
    AddCompanyComponent,
    CompanyListComponent,
    CompanyViewComponent,
    DeleteCompanyComponent,
    EditCompanyComponent,
    AddRolesComponent,
    DeleteRolesComponent,
    PermissionListComponent,
    RolePermissionComponent,
    UpsiActivityLogComponent,
    InsiderActivityLogComponent,
    DpActivityLogComponent,
    PopupModelComponentComponent,
    AddMailTemplateComponent,
    ListMailTemplateComponent,
    EditMailTemplateComponent,
    DeleteMailTempateComponent,
    ViewMailTemplateComponent,
    RevertProjectComponent,
    DeletedProjectModuleComponent,
    ProjectActivityLogsComponent,
    AddCompanyrulesComponent,
    ListedCompanyComponent,
    AddCompanyListComponent,
    AddRestrictedCompanyComponent,
    ListCompanyrulesComponent,
    ViewCompanyrulesComponent,
    EditCompanyRulesComponent,
    DeleteCompanyRulesComponent,
    DeleteCompanyListComponent,
    EditCompanyListComponent,
    DeleteRuleComponent,
    AddMailSettingComponent,
    NonUpsiActivityLogComponent,
    UserLogComponent,
    EditReasonComponent,
    SystemInfoComponent,
    SysteminfoViewComponent,
    DisclaimerexcelComponent,
    RestorereasonComponent,
    CustomerActivityLogComponent,
    UsersListComponent,
    CronAnnualConfiramationComponent,
    EntrymessageComponent,
    EditActemplateComponent,
    ViewActemplateComponent,
    ListActemplateComponent,
    DeleteActemplateComponent,
    PreclearanceDesclaimerComponent
  ],
  imports: [
    CommonModule,
    masterRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    AngularMultiSelectModule,
    NgMultiSelectDropDownModule,
    AngularEditorModule,
    AutocompleteLibModule,
    NgSelectModule,
    CKEditorModule
  ],
  providers: [
    DatePipe,
  
  ],
  entryComponents:[
    PopupModelComponentComponent
  ],
})
export class MasterModule { }
