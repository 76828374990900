import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DpModuleService } from '../dp-module.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterService } from 'src/app/master/master.service';
import { distinct, from, Observable, ReplaySubject, tap } from 'rxjs';
import { NgbModal, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { LabelPopupComponent } from '../label-popup/label-popup.component';
import { environment } from 'src/environments/environment';
import { DatePipe, formatDate } from '@angular/common';
import { SharedDataService } from '../shared-data.service';
import { DpMailTemplateComponent } from '../dp-mail-template/dp-mail-template.component';
import { EditReasonComponent } from '../../master/project/edit-reason/edit-reason.component';
import { UpsiService } from 'src/app/upsi-transfer/upsi.service';

@Component({
  selector: 'app-db-module-edit',
  templateUrl: './db-module-edit.component.html',
  styleUrls: ['./db-module-edit.component.scss']
})
export class DbModuleEditComponent implements OnInit {
  selectedRoleName = [];
  isLoading = false;
  showButton = false;
  button = 'Update';
  editReason = '';
  submitted:boolean = false;
  dbEditForm: FormGroup;
  dbOldEditForm: FormGroup;
  immediatRelativesPerson:any;
  dbRelativeId: number;
  dpId: any;
  count: any;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  attachName: any;
  path: any;
  attachmentPath:boolean = false;
  image: string | ArrayBuffer;
  regex = "[A-Z]{5}[0-9]{4}[A-Z]{1}";
  //tab set
currentJustify = 'start';
currentOrientation = 'horizontal';

public active1 = 1;
disabled = true;
  annualYear: any;

//tab set
financialYears = [];
  year: string;
  accessSuccess: string;
  urlLink: any;
  accessSdd: any;
  listroles: any;
  annualConformationData: any;
  pathImage: any;

  dropdownSettings1 = {};
  dropdownListSelf = [];
  selectedItemsSelf  = [];
  dropdownSettingsSelf  = {};
  labelDataList = [];
  labelDataList1 = [];
  selectedLabelDataItems = [];
  selectedLabelitem = [];
  selected_label = [];
  dpDetailsId: any;
  hrefUrl: string;
  mailUrl: string;
  companyname: string;
  selectedItemRole: any;
  @ViewChild('teams') teams!: ElementRef;
  @ViewChild('dbemail') dbemail!: ElementRef;
  roleType: any;
  myDate: any;
  currentDate = new Date();
  confidencialUrl: string;
  rolesNameFilter = [];
  keyword = "role_name"
  rolesNameFilters = [];
  d = [
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    [1, 2, 3, 4, 0, 6, 7, 8, 9, 5],
    [2, 3, 4, 0, 1, 7, 8, 9, 5, 6],
    [3, 4, 0, 1, 2, 8, 9, 5, 6, 7],
    [4, 0, 1, 2, 3, 9, 5, 6, 7, 8],
    [5, 9, 8, 7, 6, 0, 4, 3, 2, 1],
    [6, 5, 9, 8, 7, 1, 0, 4, 3, 2],
    [7, 6, 5, 9, 8, 2, 1, 0, 4, 3],
    [8, 7, 6, 5, 9, 3, 2, 1, 0, 4],
    [9, 8, 7, 6, 5, 4, 3, 2, 1, 0]
  ];
  
  p = [
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    [1, 5, 7, 6, 2, 8, 3, 0, 9, 4],
    [5, 8, 0, 3, 7, 9, 6, 1, 4, 2],
    [8, 9, 1, 6, 0, 4, 3, 5, 2, 7],
    [9, 4, 5, 3, 1, 2, 6, 8, 7, 0],
    [4, 2, 8, 6, 5, 7, 3, 9, 0, 1],
    [2, 7, 9, 3, 8, 0, 6, 4, 1, 5],
    [7, 0, 4, 6, 9, 1, 3, 2, 5, 8]
  ];
  modelAccessControl = {};
  otherscategoryPersonFormC:boolean = false;
  selectedcategoryPersonFormC: any;
  openDpLabel = false;
  labelOldDataList: any;
  selectedLabelDataItems2: any[];
  mailPopupModel = environment.mailPopupModel;
  selfRecord: boolean;
  confirmdatecheck = false;
  preclearanceAccess: boolean;
  user_roles: any;
  is_access_write: boolean;
  is_access_read: boolean;
  is_access_no: boolean;
  accessUser: string;
  emailChangeItem: any;
  constructor(private datePipe: DatePipe,private fb: FormBuilder, private modalService: NgbModal, private dpService:DpModuleService, private toastr: ToastrService, private activateRouter:ActivatedRoute, public router: Router, private masterService: MasterService,
    private service: SharedDataService, private upsiService: UpsiService) {
    this.accessSuccess = sessionStorage.getItem('access_success');
    // console.log("accessSuccess", this.accessSuccess)
    if(this.accessSuccess !== '1'){
      this.router.navigate(['auth/login']);      
    }
    this.myDate = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd');
    this.confidencialUrl = environment.url;
    this.accessUser = sessionStorage.getItem('access_user');

   }
  createImmediateRelative(){
    return this.fb.group({
      imr_id: new FormControl(''),
      name: new FormControl(''),
      relationship: new FormControl(''),
      phone_no: new FormControl('', Validators.pattern("^((\\+?)|0)?[0-9\ \-]+$")),
      pan: new FormControl(''),
      DocumentType: new FormControl(''),
      relativeemail: new FormControl('')

    })
  }

  i:number;
  addInputControls(){
    // this.procedureCont = true;
    const add = this.dbEditForm.get('immediateDpRelative') as FormArray;
   
    add.push(this.createImmediateRelative()) 
    this.count = this.dbEditForm.get('immediateDpRelative');
   

  }
  addOldInputControls(){
    // this.procedureCont = true;
    const add = this.dbOldEditForm.get('immediateDpRelative') as FormArray;
   
    add.push(this.createImmediateRelative()) 
    this.count = this.dbOldEditForm.get('immediateDpRelative');
   

  }
 
  removeInputControls(idx: number){
    if(this.dbEditForm.get('immediateDpRelative').value.length <= 1){
      const add = this.dbEditForm.get('immediateDpRelative') as FormArray;  
      add.reset();
    }else{
    const add = this.dbEditForm.get('immediateDpRelative') as FormArray;  
    add.removeAt(idx); 
    this.count = this.dbEditForm.get('immediateDpRelative').value.length;
    }
}

//Annual Confiremation
createAnnualConfirmation(){
  return this.fb.group({
    id: new FormControl(''),
    financialYearSymbol: new FormControl('FY'),
    financialYear: new FormControl(''),
    lastConformedDate: new FormControl(''),
  })
}
addInputControlsAnnual(){
  // this.procedureCont = true;
  const add = this.dbEditForm.get('annualConfirmation') as FormArray;
  add.push(this.createAnnualConfirmation())
  for(let i=2019; i<2100; i++){
    this.year = '{' + i + '-' + (i+1) + '}' 
    this.financialYears.push (this.year);
 
}
const listprocedureremove = this.financialYears.map(({}) =>({linkedName: this.year}));
  listprocedureremove.forEach(data =>{
    this.annualYear = data;
    
 })

 
}
addOldInputControlsAnnual(){
  // this.procedureCont = true;
  const add = this.dbOldEditForm.get('annualConfirmation') as FormArray;
  add.push(this.createAnnualConfirmation())
  for(let i=2019; i<2100; i++){
    this.year = '{' + i + '-' + (i+1) + '}' 
    this.financialYears.push (this.year);
 
}
const listprocedureremove = this.financialYears.map(({}) =>({linkedName: this.year}));
  listprocedureremove.forEach(data =>{
    this.annualYear = data;
    
 })

 
}

getFinancialYear(){ 


}
removeInputControlsAnnual(idx: number){
  const add = this.dbEditForm.get('annualConfirmation') as FormArray;  
  add.removeAt(idx);   
}
getProjectList(projNames = []) {
  let requestType = {'requestType' : 'DPProjects'}

  this.upsiService.projectList(requestType).subscribe(
    res => {
      
      this.dropdownList = [];
      const listProject = res.data;

      let selectedItems = [];
      let selectedOldItems = [];

      //const procedureList = listProject?.map(({id,project_name,durationfrom,durationto}) =>({id,itemName: project_name,durationfrom,durationto}));
      const procedureList = listProject?.map(({id,project_name}) =>({id,itemName: project_name})); 

            // procedureList.map((projectItem) => {
       
      //   let completedProjectItems = [];
      //   if(projectItem.durationto < this.myDate){            
      //     completedProjectItems.push(projectItem); 
                     
      //   }else{
      //     this.dropdownList.push(projectItem); 
     
      //   } 
      // });
      procedureList.map(({id,itemName}) =>{

        this.dropdownList.push({id,itemName: itemName}); 
        if(projNames && projNames.length > 0) {
          projNames.map(resStr => {
            this.selectedItems = []
            
            if(itemName == resStr){ 
              selectedItems.push({id,itemName: itemName});
              selectedOldItems.push({id,itemName: itemName});
           
            }
          });
        }
      });
      this.selectedItems = selectedItems;

   this.dbOldEditForm.get('project').setValue(selectedOldItems);
   this.dbEditForm.get('project').setValue(selectedItems);

    });
}

getLabelNameDp(labelNames = []){
  this.selectedLabelDataItems = [];
  this.dpService.listDpLabelModule(null).subscribe(
    res => {
      this.labelDataList = [];
      this.labelOldDataList = [];
      let labelDataList1 = [];
      let selectedLabelDataItems = [];
      
     
      let labelItemData = res.body['data'];
      labelDataList1 = labelItemData?.map(({id,dp_labelname}) =>({item_id:id,item_text: dp_labelname}));
   

      labelItemData?.map(({id,dp_labelname}) =>{
      this.labelDataList.push({item_id:id,item_text: dp_labelname});
      
    if(labelNames && labelNames.length > 0) {
      labelNames?.map(resStr => {
      
        if(dp_labelname == resStr){ 
        
          if(resStr != ''){
          selectedLabelDataItems.push({item_id:id,item_text: dp_labelname});
          }
        }
      });
    }
  });
  this.selectedLabelDataItems = selectedLabelDataItems;
  this.selectedLabelDataItems2 = selectedLabelDataItems;
  this.dbOldEditForm.get('groupLabelName').setValue(this.selectedLabelDataItems);
  this.dbEditForm.get('groupLabelName').setValue(this.selectedLabelDataItems);
});


}

  ngOnInit(): void {
    this.user_roles = JSON.parse(atob(sessionStorage.getItem('sdd_access_roles')));
    if (this.user_roles.preclearance_read == '2') {
      this.is_access_write = true;
      this.is_access_read = true;
      this.is_access_no = true;
    } else if (this.user_roles.preclearance_read == '1') {
      this.is_access_write = false;
      this.is_access_read = true;
      this.is_access_no = true;
    }

    let accessData = sessionStorage.getItem('model_access_controller');    
    let accessModelController = JSON.parse(accessData);
    if(accessModelController.dplabel_access == true || accessModelController.dplabel_access == 1){
       this.openDpLabel = true;
    }else{
       this.openDpLabel = false;
    }
    if(accessModelController.preclearance_access == true || accessModelController.preclearance_access == 1){
      this.preclearanceAccess = true;
    }else{
      this.preclearanceAccess = true;
    }

    

    this.hrefUrl = environment.href;
    this.mailUrl = environment.mail;
    this.companyname = environment.companyName;
    
    this.activateRouter.params.subscribe(data =>{
      this.dpId = {'id' : data['id']}
      
    })
    if(this.dpId['id'] !==''){
      this.dpService.editDpModule(this.dpId)
      .toPromise().then(result =>{  
       
        let x= null;
        // if(x !== result.data[0].accessToSdd){        
        //   this.selectedButton = result.data[0].accessToSdd ;
        // }else{         
        //   this.selectedButton = 'Inactive';
        // }
        let self_records = result.data[0].self_records;
        if(self_records == 0){
          result.data[0].self_records = false;
        }else if(self_records == 1){
          result.data[0].self_records = true;

        }
        let accessToSdd = result.data[0].accessToSdd;
        if(accessToSdd == 'Active' || accessToSdd == 'Yes'){
            this.selectedButton = result.data[0].accessToSdd;
        }else{
          this.selectedButton = 'Inactive';

        }
        this.dpDetailsId = result.data[0].id ;
        
        // let selfrecoder = result.data[0].accessToSdd;
        let selfrecoder = result.data[0].self_records;

        if(selfrecoder == 1 || selfrecoder == true){
              this.selfRecord = true;
        }else{
            this.selfRecord = false;

        }
        this.selectedButton2 = result.data[0].confidentiality_agreement_entered
        this.immediatRelativesPerson = result.data[0].immediateDpRelative ;
        result.data[0].immediateDpRelative = result.data[0].immediateDpRelative;
        if( this.immediatRelativesPerson &&  this.immediatRelativesPerson.length > 1){
          for(let i = 1;i<  this.immediatRelativesPerson.length; i++ ) {
            this.addInputControls();

          }
        }
        if( this.immediatRelativesPerson &&  this.immediatRelativesPerson.length > 1){
          for(let i = 1;i<  this.immediatRelativesPerson.length; i++ ) {
            this.addOldInputControls();

          }
        }
        //annualConformation data
        this.annualConformationData = result.data[0].annualConfirmation ;
        result.data[0].annualConfirmation = result.data[0].annualConfirmation;
        if( this.annualConformationData &&  this.annualConformationData.length > 1){
          for(let i = 1;i<  this.annualConformationData.length; i++ ) {
            this.addInputControlsAnnual();
          }
        }
        if( this.annualConformationData &&  this.annualConformationData.length > 1){
          for(let i = 1;i<  this.annualConformationData.length; i++ ) {
            this.addOldInputControlsAnnual();
          }
        }
        let othercategory = result.data[0].categoryofperson;    
        if(othercategory == "Others"){
          this.otherscategoryPersonFormC = true;
        }else{
          this.otherscategoryPersonFormC = false;
        }

        this.getProjectList(result.data[0].project);
        this.getLabelNameDp(result.data[0].groupLabelName);
        //this.attachmentPath = true;
        // this.path = result.data[0].filePath;
        this.path = result.data[0].confidentiality_agreement_attachment;

        if(this.path ==''){
          this.attachmentPath = false;
        }else if(this.path == 'NULL'){
          this.attachmentPath = false;
        }else{
          this.attachmentPath = true;
        }
      
       // this.clickFn();
      //  if(this.accessUser == 'sdd@legalitysimplified.com'){         
      //   var eleman2 = document.getElementById('email');
      //   eleman2?.removeAttribute("disabled");   
      // }else{      
      //   var eleman2 = document.getElementById('email');
      //   eleman2.setAttribute("disabled", '');
      // }
        this.dbEditForm.patchValue(result.data[0]);
        this.dbOldEditForm.patchValue(result.data[0]);

         this.dbEditForm.statusChanges.subscribe(status => {
          const isEqual = this.areObjectsEqual(this.dbEditForm.value, this.dbOldEditForm.value);
          if(!isEqual){
            this.showButton = true;
          }else{
            this.showButton = false;
          }
        });
        // this?.changeListener(event);
        // this.filePath();
        this.selectedRoleName = result.data[0].role;
       

        

      })

    this.dbEditForm = this.fb.group({
      id : new FormControl(''),
      dpName: new FormControl('',  [Validators.required,Validators.pattern("[a-zA-Z _.,-]*")]),
      dpDesignation: new FormControl ('',  [Validators.required,Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')]), 
      email: new FormControl ('', [Validators.required,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-zA-Z/\| ]{2,}$')]),
      dpPanNo: new FormControl ('',  [Validators.required]),
      DocumentType: new FormControl ('', [Validators.required]),
      dpContactNo: new FormControl ('',  [Validators.required, Validators.pattern("^((\\+?)|0)?[0-9\ \-]+$")]),
      // withWhom: new FormControl ('',  Validators.required),
      dpEducationalInstitute: new FormControl ('',  [Validators.required,Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')]),
      dpAddress: new FormControl ('',  [Validators.required,Validators.pattern('^[a-zA-Z\n0-9 _.,/()#&-]+$')]),
      dpClientId: new FormControl('', [Validators.minLength(8),Validators.maxLength(8),Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')]),
      dpIndexId: new FormControl('', [Validators.minLength(8),Validators.maxLength(8),Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')]),
      groupLabelName: new FormControl(''),
      dpPastEmp: new FormControl ('',  [Validators.required,Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')]),
      remarks: new FormControl ('',Validators.pattern('^[a-zA-Z\n0-9 _.,/()#&-]+$')),
      confidentiality_agreement_attachment: new FormControl (''),
      filePath: new FormControl (''),
      image:new FormControl(''),
      immediateDpRelative : new FormArray([this.createImmediateRelative()]),
      annualConfirmation : new FormArray([this.createAnnualConfirmation()]),
      confidentiality_agreement_entered: new FormControl ('Yes',  Validators.required),
      confidentiality_agreement_date: new FormControl (''),
      accessToSdd: new FormControl ('Inactive'),
      access_select_project: new FormControl ('Project'),
      project:new FormControl (''),
      self_records: new FormControl (false),
      role: new FormControl(''),
      categoryofperson: new FormControl (''),
      otherscategoryofperson: new FormControl ('',Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$'))
    });

    
    this.dbOldEditForm = this.fb.group({
      id : new FormControl(''),
      dpName: new FormControl('',  [Validators.required,Validators.pattern("[a-zA-Z _.,-]*")]),
      dpDesignation: new FormControl ('',  [Validators.required,Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')]), 
      email: new FormControl ('', [Validators.required,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-zA-Z/\| ]{2,}$')]),
      dpPanNo: new FormControl ('',  [Validators.required]),
      DocumentType: new FormControl ('', [Validators.required]),
      dpContactNo: new FormControl ('',  [Validators.required, Validators.pattern("^((\\+?)|0)?[0-9\ \-]+$")]),
      // withWhom: new FormControl ('',  Validators.required),
      dpEducationalInstitute: new FormControl ('',  [Validators.required,Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')]),
      dpAddress: new FormControl ('',  [Validators.required,Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')]),
      dpClientId: new FormControl('', [Validators.minLength(8),Validators.maxLength(8),Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')]),
      dpIndexId: new FormControl('', [Validators.minLength(8),Validators.maxLength(8),Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')]),
      groupLabelName: new FormControl(''),
      dpPastEmp: new FormControl ('',  [Validators.required,Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')]),
      remarks: new FormControl ('',Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')),
      confidentiality_agreement_attachment: new FormControl (''),
      filePath: new FormControl (''),
      image:new FormControl(''),
      immediateDpRelative : new FormArray([this.createImmediateRelative()]),
      annualConfirmation : new FormArray([this.createAnnualConfirmation()]),
      confidentiality_agreement_entered: new FormControl ('Yes',  Validators.required),
      confidentiality_agreement_date: new FormControl (''),
      accessToSdd: new FormControl ('Inactive'),
      access_select_project: new FormControl ('Project'),
      project:new FormControl (''),
      self_records: new FormControl (false),
      role: new FormControl(''),
      categoryofperson: new FormControl (''),
      otherscategoryofperson: new FormControl ('',Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$'))
    });
    

    this.modelAccessControl = {"upsi_name":null,"upsi_access":true,"dp_access":true,"dp_name":null,"insider_access":true,"insider_name":null,"project_access":true,"project_name":null,"company_access":true,"company_name":null,"roles_access":true,"roles_name":null,"activity_access":true,"activity_name":null,"reports_access":true,"reports_name":null,"twc_access":true,"twc_name":null,"preclearance_access":true,"preclearance_name":null,"benpos_access":true,"benpos_name":null,"formcord_access":false,"formcord_name":null,"mailtemplate_access":true,"mailtemplate_name":null,"rules_access":true,"rules":null,"companys_lists_access":false,"companys_lists":"Company's Lists","customer_access":true,"customer_lists":"Customers","upsiattachment_access":false,"upsiattachment_lists":"UPSI Attachment"}

    this.masterService.roleNamesList(null).subscribe(
      res => {
      this.listroles = res.body['data'];
      
      let rolesName = this.listroles?.map(res =>res.role_name)
      this.rolesNameFilters = [];
      from(rolesName)
      .pipe(
      tap(item => item),
      distinct(d => d)
      )
      .subscribe(r => this.rolesNameFilters.push(r));
    
        },
        err => {
            //console.log(err);
        }
    );
  }



//Multi select box
  // this.dropdownList = [
  //   // { id: 1, item_text: 'Project 1' },
  //   // { id: 2, item_text: 'Project 2' },
  //   // { id: 3, item_text: 'Project 3' },
  //   // { id: 4, item_text: 'Project 4' },
  //   // { id: 5, item_text: 'Project 5' }
  // ];
  this.selectedItems = [
    // { id: 2, item_text: 'Singapore' },
    // { id: 3, item_text: 'Australia' },
    // { id: 4, item_text: 'Canada' },
    // { id: 5, item_text: 'South Korea' }
  ];
  this.dropdownSettings = {
    singleSelection: false,
    text: 'Select the Project Name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    classes: 'myclass custom-class'
  };
  
  //Multi select box
  this.dropdownSettings1 = {
    // singleSelection: false,
    // idField: 'item_id',
    // textField: 'item_text',
    // selectAllText: 'Select All',
    // unSelectAllText: 'UnSelect All',
    // itemsShowLimit: 2,
    // allowSearchFilter: true,
    singleSelection: false,
        idField: 'item_id',
        textField: 'item_text',
        enableCheckAll: true,
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        allowSearchFilter: true,
        limitSelection: -1,
        clearSearchFilter: true,
        maxHeight: 197,
        itemsShowLimit: 3,
        closeDropDownOnSelection: false,
        showSelectedItemsAtTop: false,
        defaultOpen: false,
  
  };



  }

  clickFn(){
    
  }
 
  // onSelected(){
  //   this.selectedItemRole = {role: this.teams.nativeElement.value}   
    
  //   this.dpService.onChangeRoleModule(this.selectedItemRole).subscribe(data =>{
     
  //     const listRoleItem = data;
  //     let item1 = this.listroles.data.find(i => i.role_name === this.teams.nativeElement.value);
  //    const procedureList = listRoleItem.data.map(({project_id,project_name}) =>({id: project_id ,item_text: project_name}));
  //    const splitData = listRoleItem.data.map(({project_id,project_name}) =>({id: project_id ,project_name: project_name}));
    
  //    let v = null;
  //    if(v != splitData[0].project_name ){
  //      if(item1.role_name === this.teams.nativeElement.value){
  //        const procedureList = listRoleItem.data;   
  //        this.selectedItems = procedureList?.map(({project_id,project_name}) =>({id: project_id ,item_text: project_name}));  
  //        return false;
  //      }else{    console.log("Error");
  //      }
         
  //      }else{
  //        this.selectedItems = [];
         
  //      }
  //   });
  
  // }
  onSelected(items){
    this.selectedItemRole = {role: items}

      
    this.dpService.onChangeRoleModule(this.selectedItemRole).subscribe(data =>{
     
      const listRoleItem = data;
      let item1 = this.listroles.find(i => i.role_name === items);
  
    const procedureList = listRoleItem.data.map(({project_id,project_name}) =>({id: project_id ,itemName: project_name}));
    
    const splitData = listRoleItem.data.map(({project_id,project_name}) =>({id: project_id ,project_name: project_name}));
    
let v = null;
if(v !== splitData[0].project_name ){
  if(item1.role_name === items){
    const procedureList = listRoleItem.data;   
    this.selectedItems = procedureList?.map(({project_id,project_name}) =>({id: project_id ,itemName: project_name}));  
    return false;
  }else{    
    //console.log("Error");
  }
    
  }else{
    this.selectedItems = [];
    
  }

    });
  
  }
  base64Output : string;

  filePath(name){
    if(this.dbEditForm.get('confidentiality_agreement_attachment').value != ''){
    this.attachName = this.dbEditForm.get('confidentiality_agreement_attachment').value;
    //this.path =  this.attachName;
    this.path =  name;
    // const Path = this.dbEditForm.get('filePath').value;
    // Path.push(this.path)
    
    }
    this.attachmentPath = true;
  }
  removeFile(){
    this.dbEditForm.get('confidentiality_agreement_attachment').setValue("");
    
    // this.attachName = "";
    // this.userPhoto.nativeElement.value = null;
    this.attachmentPath = false;
    this.path = "";
    
  }
  onFileSelected(event) {
    this.filePath(event);
      this.convertFile(event.target.files[0]).subscribe(base64 => {
      this.base64Output = base64;     
    });
  }

  convertFile(file : File) : Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;
  }
  //Checkbox Button
  selectedButtonChecked: string;        

  radioSelect1(e: string): void {
      this.selectedButtonChecked = e;  
  }  
  
  isSelected1(name: string): boolean {  
  
      if (!this.selectedButtonChecked) {   
          return false;  
      }    
  
      return (this.selectedButtonChecked === name); 
  } 
//Checkbox Button
  changeListener($event) : void {   
    this?.readThis($event.target);
    
    this.dbEditForm.get('confidentiality_agreement_attachment')?.setValue(File);
  //  this.filePath(event);
  }

  readThis(inputValue: any): void {
    var file:File = inputValue?.files[0];   
    this.filePath(inputValue?.files[0].name);

    var myReader:FileReader = new FileReader();   
    myReader.readAsDataURL(file);
    myReader.onloadend = (e) => {
      this.image = btoa(myReader.result.toString());   
      this.dbEditForm.value.image=myReader.result;
      this.pathImage = this.dbEditForm.value.image;
    }
  }
 
  //Multi select box
onItemSelect(item: any) {
  //console.log(item);
  // console.log(this.selectedItems);
  
}
OnItemDeSelect(item: any) {
 // console.log(item);
  // console.log(this.selectedItems);
}
onSelectAll(items: any) {
  //console.log(items);
}
onDeSelectAll(items: any) {
 // console.log(items);
}

//multi select box
// Multi Select
onItemSelect1(item: any) {
  
//   if(page == "label"){
//    if(!this.selected_label.includes(item)){
//      this.selected_label.push(item);
//    }
//  }
}

onItemDeSelect1(item: any) {

//  if(page == "label"){
//    if(this.selected_label.includes(item)){
//      this.selected_label.splice(this.selected_label.indexOf(item), 1);
//    }
//  }
}
onSelectAll1(items: any) {
// if(page == "label"){
//    this.selected_label=items;
//  }
 
}
onDeSelectAll1(items: any) {
// if(page == "label"){
//      this.selected_label=[];
//    }
}

//radio Button
selectedButton: string;        

radioSelect(e: string): void {
    this.selectedButton = e; 
    if(this.selectedButton !== "Active"){
      this.dbEditForm.get('role').setValue("");
      this.dbEditForm.get('project').setValue("");
      this.dbEditForm.get('self_records').setValue("0");  
    }
}  

isSelected(name: string): boolean {  

    if (!this.selectedButton) {   
        return false;  
    }    

    return (this.selectedButton === name); 
} 
//radio Button
//Checkbox Button
// selectedButtonChecked: string="project";        

// radioSelect1(e: string): void {
//     this.selectedButtonChecked = e;  
// }  

// isSelected1(name: string): boolean {  

//     if (!this.selectedButtonChecked) {   
//         return false;  
//     }    

//     return (this.selectedButtonChecked === name); 
// } 
//Checkbox Button
//radio Button
selectedButton2: string;        

radioSelect2(e: string): void {
  this.selectedButton2 = e; 
  if(this.selectedButton2 !== "Yes"){
    this.dbEditForm.get('confidentiality_agreement_attachment').setValue("");
    this.dbEditForm.get('confidentiality_agreement_date').setValue("");
    this.dbEditForm.get('filePath').setValue("");    
    this.path = "";
    this.attachmentPath = false;
  }
}  

isSelected2(name: string): boolean {  

  if (!this.selectedButton2) {   
      return false;  
  }    

  return (this.selectedButton2 === name); 
} 
//radio Button

//tab set
onNavChange1(changeEvent: NgbNavChangeEvent) {
if (changeEvent.nextId === 3) {
  changeEvent.preventDefault();
}
}

onNavChange(changeEvent: NgbNavChangeEvent) {
if (changeEvent.nextId === 3) {
  changeEvent.preventDefault();
}
}
//tab set
//Accordion
public beforeChange(e) { }
//Accordion
checkCheckBoxvalue(event){
   //console.log(event.target.checked)
}
accessFunction(){
  if(this.dbEditForm.get('accessToSdd').value == 'Inactive'){
    this.dbEditForm.get('role').value.reset();
    this.dbEditForm.get('project').value.reset();

  }
}
//roles
onBlur(): void {
  // console.log('Focus Is Lost for this Element');
}
//role
selectEvent(item) {
}

onChangeSearch(search: string) {
}

onFocused(e) {
  // do something
}

//AAdharValidation
validate(addharNumber) {
  let c = 0;
  let invertedArray = addharNumber
    .split('')
    .map(Number)
    .reverse();

  invertedArray.forEach((val, i) => {
    c = this.d[c][this.p[i % 8][val]];
  });

  return c === 0;
}

otherCategoryOfPersonFormC() {
  this.selectedcategoryPersonFormC = this.dbEditForm.get('categoryofperson').value;    
  if(this.selectedcategoryPersonFormC == "Others"){
    this.otherscategoryPersonFormC = true;
  }else{
    this.otherscategoryPersonFormC = false;
    this.dbEditForm.get('otherscategoryofperson').setValue('');
  }
}
cancelDpList(){
  this.router.navigate(['/dp/dp-list']);
  this.dbEditForm.reset();
  this.submitted = false;
  
}
areObjectsEqual(a: any, b: any): boolean {
  const aString = JSON.stringify(a);
  const bString = JSON.stringify(b);

  return aString === bString;
}
findChangedKeyValues(objA: any, objB: any): any {
  const resultA: any = {};
  const resultB: any = {};

  for (const key in objA) {
    if(key != 'id'){
      if (objA.hasOwnProperty(key) && objB.hasOwnProperty(key)) {
        if (JSON.stringify(objA[key]) !== JSON.stringify(objB[key])) {          
          resultA[key] = objA[key];
          resultB[key] = objB[key];          
        }
      } else {
        
          resultA[key] = objA[key];
        
      }
    }
  }

  for (const key in objB) {
    if (objB.hasOwnProperty(key) && !objA.hasOwnProperty(key)) {
      resultB[key] = objB[key];
    }
  }

  return {
    "oldData": resultA,
    "newData": resultB
  };
}
openModalEditReason() {
  if(this.dbEditForm.controls.dpName.errors?.pattern != undefined){
    this.toastr.warning('Enter Name accept text format only')
    return false;
  }else if(this.dbEditForm.controls.email.errors?.pattern != undefined){
    this.toastr.warning('Your Email Id is InValid')
    return false;
  }else if(this.dbEditForm.controls.dpContactNo.errors?.pattern != undefined){
    this.toastr.warning('Enter Contact Number accept number format only')
    return false;
  }
  if(this.dbEditForm.get('accessToSdd').value == 'Active'){
    if(this.dbEditForm.get('role').value == ''){   
      this.dbEditForm.get('role').value.error ;
      alert( "Please provide your Role Type!" );
      return false;
    }
  
  }else if(this.dbEditForm.get('accessToSdd').value == null){
    this.dbEditForm.value.accessToSdd = 'Inactive';
  }
  this.dbEditForm.value.id = this.dpDetailsId;
  const emptyFileds = Object.keys(this.dbEditForm.controls).filter(key =>this.dbEditForm.controls[key].errors !== null)

let currdate = new Date();
let curr_date = formatDate(currdate, 'yyyy-MM-dd', 'en-US');
if(this.dbEditForm.get('annualConfirmation')['controls'].length > 0){
  for(let i=0; i<this.dbEditForm.get('annualConfirmation')['controls'].length; i++){
    if(this.dbEditForm.get('annualConfirmation')['controls'][i]['controls'].lastConformedDate.value != ''){
      if(this.dbEditForm.get('annualConfirmation')['controls'][i]['controls'].lastConformedDate.value > curr_date ){
        this.toastr.warning( 'Enter the ' + [i + 1] + ' row  Valid Last Confirmed Date.')
          return false;  
      }
    }
  }

}
let idendiferCard = this.dbEditForm.get('dpPanNo').value;
      if(this.dbEditForm.get('DocumentType').value == 'PAN'){
        
        let regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
        if (!regex.test(idendiferCard)) {
          this.toastr.warning("Please Enter the Valid PAN.");
         // this.messageValid = 'Please Enter the Valid PAN.';
         return false;
        }
      }else if(this.dbEditForm.get('DocumentType').value == 'Aadhar card'){
        
        let regex = new RegExp(/^[A-Z]{0}[0-9]{12}[A-Z]{0}$/);
        if(!regex.test(idendiferCard)){
          this.toastr.warning('Please Enter Valid Aadhar Number');
          return false;
        }else{
        if (this.validate(idendiferCard) && idendiferCard) {         
        } else {
          this.toastr.warning('Your Aadhar Number is InValid')
          return false;      
        }

      }
      }else if(this.dbEditForm.get('DocumentType').value == 'Voter ID'){
        let regex = new RegExp(/^([a-zA-Z]){3}([0-9]){7}?$/);
        if(!regex.test(idendiferCard)){
          this.toastr.warning('Please Enter Valid Voter ID');
          return false;
        }
    }else if(this.dbEditForm.get('DocumentType').value == 'Driving License'){
      let regex = new RegExp(/^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/);
        if(!regex.test(idendiferCard)){
          this.toastr.warning('Please Enter Valid Driving License');
          return false;
        }
    }
    if(this.dbEditForm.get('confidentiality_agreement_entered').value == 'Yes'){
      if(this.dbEditForm.get('confidentiality_agreement_date').value == null || this.dbEditForm.get('confidentiality_agreement_date').value == ''){
        this.toastr.warning('Please Enter the Confidentiality Agreement Date');
        return false;
      }
    }
    //Immidiate relative
    if(this.dbEditForm.get('immediateDpRelative')['controls'].length > 0){
      for(let i=0; i<this.dbEditForm.get('immediateDpRelative')['controls'].length; i++){
        if(this.dbEditForm.get('immediateDpRelative')['controls'][i]['controls'].pan.value != '' && this.dbEditForm.get('immediateDpRelative')['controls'][i]['controls'].DocumentType.value ==''){
          this.toastr.warning( 'Enter the ' + [i + 1] + ' row ID Proof No.')
            return false;  
        }
        let idendiferCard = this.dbEditForm.get('immediateDpRelative')['controls'][i]['controls'].pan.value;
        if(this.dbEditForm.get('immediateDpRelative')['controls'][i]['controls'].DocumentType.value == 'PAN'){
          
          let regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
          if (!regex.test(idendiferCard)) {
            this.toastr.warning("Please Enter the "+ [i + 1 ] + " row Valid PAN.");
           // this.messageValid = 'Please Enter the Valid PAN.';
           return false;
          }
        }else if(this.dbEditForm.get('immediateDpRelative')['controls'][i]['controls'].DocumentType.value == 'Aadhar card'){
          let regex = new RegExp(/^[A-Z]{0}[0-9]{12}[A-Z]{0}$/);
          if(!regex.test(idendiferCard)){
            this.toastr.warning('Please Enter ' + [i + 1] + ' row Valid Aadhar Number');
            return false;
          }else{
          if (this.validate(idendiferCard) && idendiferCard) {         
          } else {
            this.toastr.warning( [i + 1] + ' row Your Aadhar Number is InValid')
            return false;      
          }
        }
      }else if(this.dbEditForm.get('immediateDpRelative')['controls'][i]['controls'].DocumentType.value == 'Voter ID'){
        let regex = new RegExp(/^([a-zA-Z]){3}([0-9]){7}?$/);
        if(!regex.test(idendiferCard)){
          this.toastr.warning('Please Enter ' + [i + 1] + ' row Valid Voter ID');
          return false;
        }
    }else if(this.dbEditForm.get('immediateDpRelative')['controls'][i]['controls'].DocumentType.value == 'Driving License'){
      let regex = new RegExp(/^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/);
        if(!regex.test(idendiferCard)){
          this.toastr.warning('Please Enter ' + [i + 1] + ' row Valid Driving License ');
          return false;
        }
    }else if(this.dbEditForm.get('immediateDpRelative')['controls'][i]['controls'].phone_no.errors?.pattern != undefined){
      this.toastr.warning('Enter ' + [i + 1] + ' row Contact Number accept number format only')
      return false;
    }
    
      }
  
    }

    if(emptyFileds.length > 1){
      this.toastr.warning("Please fill all Mandatory fields.");
      return false;
    }else{
      if(this.dbEditForm.get('dpName').value == ''){
        this.toastr.warning("Please fill the Name.");
        return false;
      }else if(this.dbEditForm.get('dpDesignation').value == ''){
        this.toastr.warning("Please fill the Designation.");
        return false;
      }
      else if(this.dbEditForm.get('email').value == ''){
        this.toastr.warning("Please fill the Email ID.");
        return false;
      }
      else if(this.dbEditForm.get('dpContactNo').value == ''){
        this.toastr.warning("Please fill the Contact Number.");
        return false;
      }
      else if(this.dbEditForm.get('dpPanNo').value == ''){
        this.toastr.warning("Please fill the ID Proof Type.");
        return false;
      }
      else if(this.dbEditForm.get('DocumentType').value == ''){
        this.toastr.warning("Please fill the ID Proof No.");
        return false;
      }
      else if(this.dbEditForm.get('dpAddress').value == ''){
        this.toastr.warning("Please fill the Address.");
        return false;
      }
      else if(this.dbEditForm.get('dpEducationalInstitute').value == ''){
        this.toastr.warning("Please fill the Educational Institute Name, Last Graduated From.");
        return false;
      }
      else if(this.dbEditForm.get('dpPastEmp').value == ''){
        this.toastr.warning("Please fill the Company Name of Previous Job.");
        return false;
      }
      // else if(this.dbEditForm.get('categoryofperson').value == ''){
      //   this.toastr.warning("Please fill the Category of Person.");
      // }
    }

  const isEqual = this.areObjectsEqual(this.dbEditForm.value, this.dbOldEditForm.value);
  if(!isEqual){
    const modalRef = this.modalService.open(EditReasonComponent,
      {
        scrollable: true,
        windowClass: 'myCustomModalClass',
      });
    modalRef.result.then((result) => {
      // console.log("result", result);
      this.editReason=result;
      if(this.editReason != 'dismiss'){
        this.onSubmit();
      }
    }, (reason) => {
    });
  }
  
}
    onSubmit(){
      if(this.dbEditForm.value.self_records == true){
        this.dbEditForm.value.self_records = 1;
      }else{
        this.dbEditForm.value.self_records = 0;
      }
      this.dbEditForm.value.confidentiality_agreement_attachment = this.path;

      

      const changedKeyValues = this.findChangedKeyValues(this.dbOldEditForm.value,this.dbEditForm.value);
        
      let reqObj = {
        data:changedKeyValues,
        id:this.dbEditForm.value.id,
        editReason:this.editReason
      }
      this.dbEditForm.value.confidentiality_agreement_attachment = this.path;
      this.dbEditForm.value.confidentiality_agreement_attachment = this.image;

         
     
      this.submitted = true;
      // this.dbEditForm.value.hrefLink = this.hrefUrl; 
      // this.dbEditForm.value.mailId  = this.mailUrl;
      // this.dbEditForm.value.companyName = this.companyname;
    
      if(this.dbEditForm.status !== 'INVALID'){
        this.isLoading = true;
        this.button = 'Processing';
      this.dpService.updateDpModule(reqObj).subscribe(data =>{
        if (data?.success == '1') {
        this.toastr.success("Dp Successfully Updated");
        this.isLoading = false;
      this.button = 'Update';
      if(this.mailPopupModel == 'LSTEST'){
        if(data.mailTemplate !=''){
          const modalRef = this.modalService.open(DpMailTemplateComponent,
            {
              scrollable: true,
              windowClass: 'myCustomModalClass',
              // keyboard: false,
              // backdrop: 'static'
            });
          modalRef.componentInstance.viewDbmailTemplate = data.mailTemplate;
          modalRef.result.then(
            (result) => {
              //console.log(result);
            }, (reason) => {
              //console.log(reason);
            });
        }
      }
        this.router.navigate(['/dp/dp-list']);
        // if (this.agendaCreateForm.valid) {
        //   this.toastr.success("Agent Items details created", "Success!");
        // } else {
        //   this.toastr.warning("Agent Items details not created", "Alert!");
        // }
    }else{
          this.toastr.warning(data?.message);
          this.isLoading = false;
          this.button = 'Save';
    }
      })   
    }else{
      this.toastr.warning("Invalid Field....");
    }

    }
   
    open() {
      
      const modalRef = this.modalService.open(LabelPopupComponent,
      {
        scrollable: true,
        windowClass: 'myCustomModalClass',
        // keyboard: false,
        // backdrop: 'static'
      });
      modalRef.componentInstance.name = 'Label';
      modalRef.result.then((result) => {
        
      }, (reason) => {
      });
      
      modalRef.componentInstance.onSubmitEntry.subscribe((receivedEntry) => {
     
        this.labelDataList = receivedEntry?.map(({id,dp_labelname}) =>({id,dp_labelname,item_id:id,item_text: dp_labelname}));
     
      })
      
    }

}
