<div class="modal-header">
    <h5 class="modal-title">Alert!.. </h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
               
                <!-- <div class="card-body custom-datatable noscroll" *ngIf="emptyMessage == true">
                    <p class="tradealert"><b>Reminder:</b> It has been {{predaycounts}} trading days since your last preclearance approval. Please ensure that your preclearance records are updated to remain compliant with trading policies.</p>
                    <h5><b>Action Required:</b></h5>
                    <ul>
                        <li>Review your preclearance records.</li>
                        <li>Update your transaction status if applicable.</li>
                    </ul>
                    <br><br>
                    <p><b>Note:</b> If you are not trading the transaction click to No Trade option in the list Page</p>
                </div> -->
                <div class="card-body custom-datatable noscroll" >
                    <p class="tradealert"><b>Attention:</b> Before applying for new preclearance, please update the status of your previous preclearance trade records to stay compliant with trading policies.</p>
                    <h5><b>Action Required:</b></h5>
                    <ul>
                        <li>Review and update your pending trade records to proceed with a new preclearance request.</li>
                    </ul>
                    <div class="btn-bottom" *ngIf="countMessage == true">
                        <button class="btn btn-primary me-1" (click)="closeModal('dismiss')">Continue</button>
                        <button class="btn btn-primary me-1" (click)="closeModal('list')" >Cancel</button>
                    </div>
                </div>
               
            </div>
        </div>
    </div>
 </div>
</div>
<div class="modal-footer" *ngIf="emptyMessage == true">
    <p><b>Note:</b> If you are not trading the transaction click to No Trade option in the list Page</p>
    <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button> -->
</div>
