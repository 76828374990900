<div class="modal-header">
    <h5 class="modal-title">VIEW INSIDER DETAILS</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
               
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable">
                        <table class="table">                            
                                <tbody *ngIf="viewInsiderList">
                                    <!-- <tr>
                                        <td scope="row">Project Name</td>
                                        <td scope="row">{{viewInsiderList?.project}}</td>                                         
                                     </tr>  -->
                                     <tr>
                                        <td scope="row">Name</td>
                                        <td scope="row">{{viewInsiderList?.name}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Insider Type</td>
                                        <td scope="row">{{viewInsiderList?.insiderType}}</td>                                         
                                     </tr> 
                                     <tr *ngIf="viewInsiderList.insiderType == 'Company' || viewInsiderList.insiderType == 'LLP'">
                                        <td scope="row">CIN Number</td>
                                        <td scope="row">{{viewInsiderList?.cin}}</td>                                         
                                     </tr> 
                                     <tr *ngIf="viewInsiderList.insiderType == 'Individual'">
                                        <td scope="row">Designation</td>
                                        <td scope="row">{{viewInsiderList?.designation}}</td>                                         
                                     </tr> 
                                     <tr *ngIf="viewInsiderList.insiderType == 'Individual'">
                                        <td scope="row">Nationality</td>
                                        <td scope="row">{{viewInsiderList?.nationality}}</td>                                         
                                     </tr> 
                                     <tr *ngIf="viewInsiderList.nationality == 'Others'">
                                        <td scope="row">Others Nationality</td>
                                        <td scope="row">{{viewInsiderList?.others_nationality}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Relationship with Company</td>
                                        <td scope="row">{{viewInsiderList?.relationship_with_company}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Email ID</td>
                                        <td scope="row">{{viewInsiderList?.email}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Contact Number</td>
                                        <td scope="row">{{viewInsiderList?.mobileNo}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">ID Proof Type - ID Proof No.</td>
                                        <td scope="row">{{viewInsiderList?.DocumentType}} - {{viewInsiderList?.panNo}}</td>                                         
                                     </tr>
                                       
                                     <tr>
                                        <td scope="row">Address</td>
                                        <td scope="row">{{viewInsiderList?.address}}</td>                                         
                                     </tr> 
                                     <!-- <tr>
                                        <td scope="row">Designated Person</td>
                                        <td scope="row">{{viewInsiderList?.designated_person}}</td>                                         
                                     </tr>  -->
                                     <tr>
                                        <td scope="row">Confidentiality Agreement entered</td>
                                        <td scope="row">{{viewInsiderList?.confidentiality_agreement_entered}}</td>                                         
                                     </tr> 
                                     <tr *ngIf="viewInsiderList?.confidentiality_agreement_entered == 'Yes'">
                                       <td scope="row">Confidentiality Agreement Copy</td>
                                       <td scope="row"><a href="{{baseUrl}}/public/uploads/insider/{{getId}}/{{viewInsiderList?.filePath}}" target="_blank">{{viewInsiderList?.filePath}}</a></td>                                         
                                    </tr> 
                                    <tr *ngIf="viewInsiderList?.confidentiality_agreement_entered == 'Yes'">
                                       <td scope="row">Confidentiality Agreement Date</td>
                                       <td scope="row">{{viewInsiderList.confidentiality_agreement_date | date:'dd-MM-yyyy'}}</td>                                         
                                    </tr> 
                                    <tr *ngIf="viewInsiderList?.company_name">
                                       <td scope="row" colspan="2" style="font-weight: 700;
                                       text-decoration: underline;">Company Details:</td>
                                    </tr>
                                    <tr *ngIf="viewInsiderList?.company_name">
                                       <td scope="row">Entity Name</td>
                                       <td scope="row">{{viewInsiderList?.company_name}}</td>                                         
                                    </tr> 
                                    <tr *ngIf="viewInsiderList?.company_email">
                                       <td scope="row">Entity Email ID</td>
                                       <td scope="row">{{viewInsiderList?.company_email}}</td>                                         
                                    </tr>                                 
                                    <tr *ngIf="viewInsiderList?.isinnumber">
                                       <td scope="row">Entity ISIN Number</td>
                                       <td scope="row">{{viewInsiderList?.isinnumber}}</td>                                         
                                    </tr>                                 
                                    <tr *ngIf="viewInsiderList?.company_cin">
                                       <td scope="row">Entity CIN Number</td>
                                       <td scope="row">{{viewInsiderList?.company_cin}}</td>                                         
                                    </tr>                                 
                                    <tr *ngIf="viewInsiderList?.company_pan">
                                       <td scope="row">Entity PAN No.</td>
                                       <td scope="row">{{viewInsiderList?.company_pan}}</td>                                         
                                    </tr>                                 
                                    <tr *ngIf="viewInsiderList?.company_contact_no">
                                       <td scope="row">Entity Contact Number</td>
                                       <td scope="row">{{viewInsiderList?.company_contact_no}}</td>                                         
                                    </tr>                                 
                                    <tr *ngIf="viewInsiderList?.company_address">
                                       <td scope="row">Entity Address</td>
                                       <td scope="row">{{viewInsiderList?.company_address}}</td>                                         
                                    </tr>   
                                    
                                                                       
                               
                            </tbody>
                        </table>

                        <div class="checkbox">
                           
                           <div class="col-sm-12 col-xl-12">
                              <div class="card">                                                     
                                <div class="card-body timeline">
                                  <ngb-accordion [closeOthers]="true">
                                    <ngb-panel id="static-1">
                                      <ng-template ngbPanelTitle>
                                          <span>Timeline of Changes</span>
                                        </ng-template>
                                      <ng-template ngbPanelContent>
                                       <div class="custom-datatable">
                                           <div class="timeline-wrapper"> 
                                               <ul class="StepProgress" *ngFor = "let items of timelineActivity"> 
                                                      <li class="StepProgress-item is-done">
                                                          <div class="bold time">{{items.ActionOn | date:'dd-MM-yyyy HH:mm:ss'}}</div> 
                                                          <div ><p><span class="bold">Record:</span> {{items.Record}}</p></div>
                                                          <div ><p><span class="bold">Activity:</span> {{items.Activity}}</p></div>
                                                          <div ><p><span class="bold">Reason for Change:</span> {{items.reason}}</p></div>
                                                          <div ><p><span class="bold">Action By (Username):</span> {{items.ActionBy}}</p></div>
                                                          <div ><p><span class="bold">Change Details:</span> {{items.changedetails}}</p></div>
                                                      </li>                                       
                                               </ul>
                                           </div>    
                                           
                                           <div class="list-btn2" *ngIf="timelineActivity?.length =='' ">
                                               <p>No Data Found</p>
                                           </div>
                                        
                                      </div>

                                      </ng-template>
                                    </ngb-panel>
                                                                        
                                  </ngb-accordion>
                                </div>
                              </div>
                            </div>
                          
                        </div>
                        
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
 </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div>
