import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MasterService } from '../../master.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewActemplateComponent } from '../view-actemplate/view-actemplate.component';
import { DeleteActemplateComponent } from '../delete-actemplate/delete-actemplate.component';
import { DpModuleService } from 'src/app/dp-module/dp-module.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-list-actemplate',
  templateUrl: './list-actemplate.component.html',
  styleUrls: ['./list-actemplate.component.scss']
})
export class ListActemplateComponent implements OnInit {
  listACMailTemplate: any;
  filtered: any;
  page = 1;
  pageSize = 10;
  filterString = "";
  @ViewChild('label') label!: ElementRef;
  isSelected: boolean = false;
  isLoading = false;
  button = 'Send';
  submitted: boolean = false;
  financialYearEntry: any;
  constructor(private masterService: MasterService, private modalService: NgbModal,private dpService:DpModuleService,private toastr: ToastrService) { }

  ngOnInit(): void {

    this.masterService.acTemplateList(null).subscribe(
      res => {
     
      this.listACMailTemplate = res;

      this.filtered = this.listACMailTemplate?.data;
        },
        err => {
            console.log(err);
        }
    );

      //Financial Year Start
      let d = new Date();
      this.financialYearEntry = d.getMonth() < 3 ? d.getFullYear() - 1 : d.getFullYear();
      var fiscalyear_ = "";
      var today = new Date();
      if ((today.getMonth() + 1) <= 3) {
        fiscalyear_ = ((today.getFullYear() - 1) + "") + "-" + (today.getFullYear() + "").slice(-2)
      } else {
        fiscalyear_ = (today.getFullYear() + "") + "-" + ((today.getFullYear() + 1) + "").slice(-2)
      }
      this.financialYearEntry = fiscalyear_
      //Financial Year End
  }
//Filter
onFilterChange() {
  this.filtered = this.listACMailTemplate.data?.filter(res =>{    
   return res.subject.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || res.templatename.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase());
  }) 
 
}

isMatch(item) {
  if (item instanceof Object) {
    return Object.keys(item).some((k) => this.isMatch(item[k]));
  } else {
    return item?.toString().indexOf(this.filterString) > -1
  }
}
get result() {     
  return this.listACMailTemplate?.data?.filter(item => item.checked);      
}
isSelectLabel(e,curItem){
  let checkedItemsArr = []
  this.filtered.map((item) => {
    if(e.target.checked){
      checkedItemsArr.push(curItem);
    } else if(item && item.checked && item.id !== curItem.id){
      checkedItemsArr.push(item);
    }
  });
  if(checkedItemsArr.length > 0){
    this.isSelected = true;
  }else{
    this.isSelected = false;
  }
 
}
onSubmitLabel(userObj:any){
  this.submitted = true;
if(this.result.length > 1){
  this.toastr.warning("Please Select the one Template.");
  return false;
}else{
  this.result[0]['type'] = 'View';
  this.result[0]['financialyear'] = this.financialYearEntry;
   this.isLoading = true;
    this.button = 'Processing'
  this.dpService.annualconfirmpopupentryupdate(this.result[0]).subscribe(data =>{ 
        this.isLoading = false;
        this.button = 'Send';  
        this.toastr?.success("Annual Confirmation Mail Successfully Send");        
         window.location.reload();
  });
}
// if(this.dbLabelCreateForm.status !== 'INVALID'){
//     this.isLoading = true;
//       this.button = 'Processing';
//       this.dpService.annualconfirmpopupentryupdate(this.result).subscribe(data =>{ 
//     this.isLoading = false;
//   this.button = 'Apply';  
//     this.toastr?.success("Label grouping Successfully");        
//      window.location.reload();
   
//   }) 
// }else{
//   if(this.dbLabelCreateForm.get('groupLabelName').value == ''){
//     this.toastr.warning("Please fill the DP Label.");
//     return false;
//   }
// }
}
  openModal(id:any) {
    if (id) {
      let mailId = {'id' : id};
      this.masterService.mailTemplateView(mailId).subscribe(
        ({data}) =>{
		const modalRef = this.modalService.open(ViewActemplateComponent,
    {
      scrollable: true,
      windowClass: 'myCustomModalClass',
      // keyboard: false,
      // backdrop: 'static'
    });		
    modalRef.componentInstance.viewMailTemplateList = data[0];
    modalRef.result.then((result) => {
      console.log(result);
    }, (reason) => {
    });
  })
}
	}
  openModalDelete(id:any) {
    if (id) {
      let mailId = {'id' : id};
      this.masterService.mailTemplateView(mailId).subscribe(
        ({data}) =>{
		const modalRef = this.modalService.open(DeleteActemplateComponent,
    {
      scrollable: true,
      windowClass: 'myCustomModalClass',
      // keyboard: false,
      // backdrop: 'static'
    });		
    modalRef.componentInstance.viewMailTemplateList = data[0];
    modalRef.result.then((result) => {
      console.log(result);
    }, (reason) => {
    });
  })
}
	}
}
