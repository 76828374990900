<div class="modal-header">
    <h5 class="modal-title">VIEW DP DETAILS</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body dp">
    <!-- Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
        <div class="card col-sm-12 ">
            <div class="card-body">
                <ul ngbNav #nav="ngbNav" [(activeId)]="active1"  class="nav-tabs">
                  <li [ngbNavItem]="1">
                    <a ngbNavLink>DP Details</a>
                    <ng-template ngbNavContent>
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="tab-pane fade show " id="list-home" role="tabpanel"
                            aria-labelledby="list-home-list">

                            <div class="col-sm-12 col-xl-12">
                                <div class="card">                                                     
                                  <div class="card-body">
                                    <ngb-accordion [closeOthers]="true" activeIds="static-1">
                                      <ngb-panel id="static-1">
                                        <ng-template ngbPanelTitle>
                                            <span>DP Basic Details</span>
                                          </ng-template>
                                        <ng-template ngbPanelContent>
                                         <div class="custom-datatable">
                                            <table class="table">                            
                                                    <tbody *ngIf="viewDbList">
                                                        <tr>
                                                            <td scope="row">Name</td>
                                                            <td scope="row">{{viewDbList.dpName}}</td>                                         
                                                         </tr> 
                                                         <tr>
                                                            <td scope="row">Designation</td>
                                                            <td scope="row">{{viewDbList.dpDesignation}}</td>                                         
                                                         </tr> 
                                                         <tr>
                                                            <td scope="row">Email ID</td>
                                                            <td scope="row">{{viewDbList.email}}</td>                                         
                                                         </tr> 
                                                         <tr>
                                                            <td scope="row">Contact Number</td>
                                                            <td scope="row">{{viewDbList.dpContactNo}}</td>                                         
                                                         </tr> 
                                                         <tr>
                                                            <td scope="row">ID Proof Type -  ID Proof No.</td>
                                                            <td scope="row">{{viewDbList.DocumentType}} - {{viewDbList.dpPanNo}}</td>                                         
                                                         </tr> 
                                                         <tr>
                                                            <td scope="row">Address</td>
                                                            <td scope="row">{{viewDbList.dpAddress}}</td>                                         
                                                         </tr> 
                                                         <tr *ngIf="(viewDbList.groupLabelName?.length > 0 && openDpLabel == true)">
                                                          <td scope="row">Group Label Name</td>
                                                          <td scope="row">{{viewDbList.groupLabelName}}</td>                                         
                                                       </tr> 
                                                                                                             
                                                </tbody>
                                            </table>
                                        </div>

                                        </ng-template>
                                      </ngb-panel>
                                      <ngb-panel id="static-2">
                                        <ng-template ngbPanelTitle>
                                          <span>Historical Details</span>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                         <div class="custom-datatable">
                                            <table class="table">                            
                                                    <tbody *ngIf="viewDbList">
                                                        <tr>
                                                            <td scope="row">Educational Institute Name, Last Graduated From</td>
                                                            <td scope="row">{{viewDbList.dpEducationalInstitute}}</td>                                         
                                                         </tr> 
                                                         <tr>
                                                            <td scope="row">Company Name of Previous Job</td>
                                                            <td scope="row">{{viewDbList.dpPastEmp}}</td>                                         
                                                         </tr> 
                                                        
                                                      
                                                </tbody>
                                            </table>
                                        </div>

                                        </ng-template>
                                      </ngb-panel>
                                      <ngb-panel id="static-3" >
                                        <ng-template ngbPanelTitle>
                                            <span>Remarks</span>
                                          </ng-template>
                                        <ng-template ngbPanelContent>
                                         <div class="custom-datatable">
                                            <table class="table">                            
                                                    <tbody *ngIf="viewDbList">
                                                        <tr>
                                                            <td scope="row">Remarks</td>
                                                            <td scope="row">{{viewDbList.remarks}}</td>                                         
                                                         </tr> 
                                                        
                                                      
                                                </tbody>
                                            </table>
                                        </div>

                                        </ng-template>
                                      </ngb-panel>
                                      <ngb-panel id="static-4" >
                                        <ng-template ngbPanelTitle>
                                            <span>Confidentiality Agreement Entered</span>
                                          </ng-template>
                                        <ng-template ngbPanelContent>
                                         <div class="custom-datatable">
                                            <table class="table">                            
                                                    <tbody *ngIf="viewDbList">
                                                        <tr>
                                                            <td scope="row">Confidentiality Agreement entered</td>
                                                            <td scope="row">{{viewDbList.confidentiality_agreement_entered}}</td>                                         
                                                         </tr> 
                                                         <tr *ngIf="viewDbList.confidentiality_agreement_entered == 'Yes' ">
                                                            <td scope="row">Confidentiality Agreement Copy</td>
                                                            <td scope="row"><a href="{{confidencialUrl}}/public/uploads/dp/{{dpId}}/{{viewDbList?.confidentiality_agreement_attachment}}" target="_blank">{{viewDbList.confidentiality_agreement_attachment}}</a></td>                                         
                                                         </tr> 
                                                         <tr *ngIf="viewDbList.confidentiality_agreement_entered == 'Yes' ">
                                                           <td scope="row">Confidentiality Agreement Date</td>
                                                           <td scope="row">{{viewDbList.confidentiality_agreement_date | date:'dd-MM-yyyy'}}</td>                                         
                                                        </tr> 
                                                        <tr *ngIf="viewDbList.confidentiality_agreement_entered == 'No' ">
                                                         <td scope="row">Confidentiality Agreement Copy</td>
                                                         <td scope="row">Not Provided</td>                                         
                                                      </tr> 
                                                      <tr *ngIf="viewDbList.confidentiality_agreement_entered == 'No' ">
                                                        <td scope="row">Confidentiality Agreement Date</td>
                                                        <td scope="row">Not Provided</td>                                         
                                                     </tr>
                                                      
                                                </tbody>
                                            </table>
                                        </div>
                                        </ng-template>
                                      </ngb-panel>
                                      <ngb-panel id="static53" >
                                        <ng-template ngbPanelTitle>
                                            <span>Name of Immediate Relative / Person Sharing Financial Material Relationship With DP</span>
                                          </ng-template>
                                        <ng-template ngbPanelContent>
                                         <div class="custom-datatable">
                                            <table class="table">                            
                                                    <tbody *ngFor="let items of viewDbList.immediateDpRelative">
                                                        <tr>
                                                            <td scope="row">Name</td>
                                                            <td scope="row">{{items.name}}</td>                                         
                                                         </tr> 
                                                         <tr>
                                                            <td scope="row">Relationship</td>
                                                            <td scope="row">{{items.relationship}}</td>                                         
                                                         </tr> 
                                                         <tr>
                                                           <td scope="row">Contact Number</td>
                                                           <td scope="row">{{items.phone_no}}</td>                                         
                                                        </tr> 
                                                        <tr>
                                                           <td scope="row">ID Proof Type</td>
                                                           <td scope="row">{{items.DocumentType}}</td>                                         
                                                        </tr> 
                                                        <tr>
                                                           <td scope="row">ID Proof No.</td>
                                                           <td scope="row">{{items.pan}}</td>                                         
                                                        </tr> 
                                                        <tr>
                                                           <td scope="row">Email ID</td>
                                                           <td scope="row">{{items.relativeemail}}</td>                                         
                                                        </tr> 
                                                        
                                                      
                                                </tbody>
                                            </table>
                                            <table class="table">                            
                                                    <tbody *ngIf="viewDbList.immediateDpRelative == '' ">
                                                        <tr>
                                                            <td scope="row">Name</td>
                                                            <td scope="row">--</td>                                         
                                                         </tr> 
                                                         <tr>
                                                            <td scope="row">Relationship</td>
                                                            <td scope="row">--</td>                                         
                                                         </tr> 
                                                         <tr>
                                                           <td scope="row">Contact Number</td>
                                                           <td scope="row">--</td>                                         
                                                        </tr> 
                                                        <tr>
                                                           <td scope="row">ID Proof Type</td>
                                                           <td scope="row">--</td>                                         
                                                        </tr> 
                                                        <tr>
                                                           <td scope="row">ID Proof No.</td>
                                                           <td scope="row">--</td>                                         
                                                        </tr> 
                                                        <tr>
                                                           <td scope="row">Email ID</td>
                                                           <td scope="row">--</td>                                         
                                                        </tr> 
                                                        
                                                      
                                                </tbody>
                                            </table>
                                        </div>
                                        </ng-template>
                                      </ngb-panel>
                                      <ngb-panel id="static-6" *ngIf="(preclearanceAccess == true && is_access_write == true)">
                                       <ng-template ngbPanelTitle>
                                         <span>Additional Details</span>
                                       </ng-template>
                                       <ng-template ngbPanelContent>
                                        <div class="custom-datatable">
                                           <table class="table">                            
                                                   <tbody *ngIf="viewDbList">
                                                       
                                                       <tr>
                                                         <td scope="row">DP ID</td>
                                                         <td scope="row">{{viewDbList.dpIndexId}}</td>                                         
                                                      </tr> 
                                                      <tr>
                                                       <td scope="row">Client ID</td>
                                                       <td scope="row">{{viewDbList.dpClientId}}</td>                                         
                                                    </tr> 
                                                      <tr *ngIf="viewDbList.categoryofperson != ''">
                                                       <td scope="row">Category of Person</td>
                                                       <td scope="row">{{viewDbList.categoryofperson}}</td>                                         
                                                    </tr> 
                                                      <tr *ngIf="viewDbList.otherscategoryofperson != ''">
                                                       <td scope="row">Others Category of Person</td>
                                                       <td scope="row">{{viewDbList.otherscategoryofperson}}</td>                                         
                                                    </tr> 
                                                       
                                                     
                                               </tbody>
                                           </table>
                                       </div>

                                       </ng-template>
                                     </ngb-panel>
                                    </ngb-accordion>
                                  </div>
                                </div>
                              </div>

    
                     </div> 
        
                            
                            
                            </div>
                        </div>
                  
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="2">
                    <a ngbNavLink>Annual Confirmation</a>
                    <ng-template ngbNavContent>
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="tab-pane fade show active" id="list-home" role="tabpanel"
                            aria-labelledby="list-home-list">
                            <div class="card-body">
                             <div class="custom-datatable">
                                <table class="table">                            
                                        <tbody *ngFor="let item of viewDbList.annualConfirmation">
                                            <tr>
                                                <td scope="row">Financial Year</td>
                                                <td scope="row">{{item.financialYear}}</td>                                         
                                             </tr> 
                                             <tr>
                                                <td scope="row">Last Confirmed Date</td>
                                                <td scope="row">{{item.lastConformedDate}}</td>                                         
                                             </tr> 
                                          
                                    </tbody>
                                </table>
                                <table class="table" *ngIf="viewDbList.annualConfirmation == '' ">                            
                                  <tbody >
                                      <tr>
                                          <td scope="row">Financial Year</td>
                                          <td scope="row">--</td>                                         
                                       </tr> 
                                       <tr>
                                          <td scope="row">Last Confirmed Date</td>
                                          <td scope="row">--</td>                                         
                                       </tr> 
                              </tbody>
                          </table>
                            </div>

                            </div>
                            </div>
                        </div>
                      </div>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="3" >
                    <a ngbNavLink >Access to SDD</a>
                    <ng-template ngbNavContent>
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="tab-pane fade show active" id="list-home" role="tabpanel"
                            aria-labelledby="list-home-list">
                                <div class="card-body">
                                   <div class="custom-datatable">
                                    <table class="table">                            
                                      <tbody *ngIf="viewDbList">
                                          <tr *ngIf="viewDbList.accessToSdd == 'Active' ">
                                              <td scope="row">Access to SDD</td>
                                              <td scope="row" >Yes</td>                                        
                                           </tr> 
                                           <tr *ngIf="viewDbList.accessToSdd == 'Inactive' ">
                                            <td scope="row">Access to SDD</td>
                                            <td scope="row" >No</td>                                        
                                         </tr> 
                                         <tr *ngIf="viewDbList.accessToSdd == null ">
                                          <td scope="row">Access to SDD</td>
                                          <td scope="row" >No</td>                                        
                                       </tr>
                                       <tr *ngIf="viewDbList.accessToSdd == '' ">
                                        <td scope="row">Access to SDD</td>
                                        <td scope="row" >No</td>                                        
                                     </tr> 
                                           <tr *ngIf="viewDbList.accessToSdd == 'Active' ">
                                              <td scope="row">User Type</td>
                                              <td scope="row">{{viewDbList.role}}</td>                                         
                                           </tr> 
                                           <tr *ngIf="viewDbList.accessToSdd == 'Active' ">
                                             <td scope="row">Project Access</td>
                                             <td scope="row">{{viewDbList.project}}</td>                                         
                                          </tr> 

                                          <!-- <tr *ngIf="viewDbList.createddpemail">
                                            <td scope="row">Username (Action By)</td>
                                            <td scope="row">{{viewDbList.createddpname}} - {{viewDbList.createddpemail}} - {{viewDbList.createddppanno}}</td>                                         
                                         </tr>
                                          <tr *ngIf="!viewDbList.createddpemail">
                                            <td scope="row">Username (Action By)</td>
                                            <td scope="row">System (Default)</td>                                         
                                         </tr>
                                         <tr>
                                            <td scope="row">Activity Date</td>
                                            <td scope="row">{{viewDbList.created_on}}</td>                                         
                                         </tr> -->
                                          
                                        
                                  </tbody>
                              </table>
                                  </div>
                                </div>
                            </div>
                        </div>
                      </div>
                    </ng-template>
                  </li>
                </ul>
                <div [ngbNavOutlet]="nav" class="mt-2"></div>

                <div class="checkbox">
                           
                  <div class="col-sm-12 col-xl-12">
                     <div class="card">                                                     
                       <div class="card-body timeline">
                         <ngb-accordion [closeOthers]="true">
                           <ngb-panel id="static-1">
                             <ng-template ngbPanelTitle>
                                 <span>Timeline of Changes</span>
                               </ng-template>
                             <ng-template ngbPanelContent>
                              <div class="custom-datatable">
                                  <div class="timeline-wrapper"> 
                                      <ul class="StepProgress" *ngFor = "let items of timelineActivity"> 
                                             <li class="StepProgress-item is-done">
                                                 <div class="bold time">{{items.ActionOn | date:'dd-MM-yyyy HH:mm:ss'}}</div> 
                                                 <div ><p><span class="bold">Record:</span> {{items.Record}}</p></div>
                                                 <div ><p><span class="bold">Activity:</span> {{items.Activity}}</p></div>
                                                 <div ><p><span class="bold">Reason for Change:</span> {{items.reason}}</p></div>
                                                 <div ><p><span class="bold">Action By (Username):</span> {{items.ActionBy}}</p></div>
                                                 <div ><p><span class="bold">Change Details:</span> {{items.changedetails}}</p></div>
                                             </li>                                       
                                      </ul>
                                  </div>    
                                  
                                  <div class="list-btn2" *ngIf="timelineActivity?.length =='' ">
                                      <p>No Data Found</p>
                                  </div>
                               
                             </div>

                             </ng-template>
                           </ngb-panel>
                                                               
                         </ngb-accordion>
                       </div>
                     </div>
                   </div>
                 
               </div>
             
               
              </div>
        </div>
    </div>
 </div>
 <!-- Container-fluid Ends -->
 

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div>
