<!--Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 ">
            <div class="card">
                <div class="card-header">
                    <h5>Edit Mail Template</h5>
                    <a routerLink="/setup/list-mail-template"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                </div>
                <div class="card-body">
                    <form [formGroup]="mailEditForm" class="needs-validation" (ngSubmit)="openModalEditReason()">
                        <div class="row">
                            <div class="col-md-12 mb-3">
                                <label for="subject">Template Name</label>
                                <div class="form-group">                    
                                    <input type="text" class="form-control" id="templatename" placeholder="Enter the Template Name" autocomplete="off" formControlName="templatename" required
                                      [ngClass]="{ 'is-invalid': (submitted || mailEditForm.controls.templatename.touched) && mailEditForm.controls.templatename.errors }"> 
                                    <div *ngIf="(submitted || mailEditForm.controls.templatename.touched) && mailEditForm.controls.templatename.errors?.required" class="text text-danger">
                                        Template Name is required.
                                    </div>
                                    <div class="text text-danger" *ngIf="mailEditForm.controls.templatename.errors?.pattern">Special Charactors Not Allowed!</div>

                                </div>
                            </div>
                            <div class="col-md-12 mb-3">
                                <label for="subject">Subject</label>
                                <div class="form-group">                    
                                    <input type="text" class="form-control" id="subject" placeholder="Enter the Subject" autocomplete="off" formControlName="subject" required
                                    [ngClass]="{ 'is-invalid': (submitted || mailEditForm.controls.subject.touched) && mailEditForm.controls.subject.errors }"> 
                                  <div *ngIf="(submitted || mailEditForm.controls.subject.touched) && mailEditForm.controls.subject.errors?.required" class="text text-danger">
                                    Subject is required.
                                  </div>
                                  <div class="text text-danger" *ngIf="mailEditForm.controls.subject.errors?.pattern">Special Charactors Not Allowed!</div>

                                </div>
                            </div>
                            <div class="col-md-12 mb-3">
                                <label for="contents">Body Content</label>
                                <div class="form-group">                    
                                    <angular-editor [(ngModel)]="htmlContent" [config]="config" formControlName="contents" required>
                                        <ng-template #customButtons let-executeCommandFn="executeCommandFn">
                                            <ae-toolbar-set>
                                              <select class="form-select" class="form-control form-select" (change)="executeCommandFn('insertText', $event.target.value, this.value)">
                                                <option value="" disabled selected>--Variable Name --</option>
                                                <option *ngFor ="let item of variablename" value="{{item.value}}" >{{item.name}}</option>
                                            </select>
                                            </ae-toolbar-set>
                                        </ng-template>
                                    </angular-editor>
                                    <!-- <ckeditor [editor]="editor" [data]="htmlContent" formControlName="contents" required></ckeditor> -->
                                    <div *ngIf="(submitted || mailEditForm.controls.contents.touched) && mailEditForm.controls.contents.errors?.required" class="text text-danger">
                                        Body Content is required.
                                      </div>
                                      <div class="text text-danger" *ngIf="mailEditForm.controls.contents.errors?.pattern">Special Charactors Not Allowed!</div>
    
                                </div>
                            </div>

                            <div class="submit-btn">
                                <button class="btn btn-primary" *ngIf="showButton" type="submit" [disabled]="isLoading"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>   
                                <button class="btn btn-warning" type="btn" (click)="cancelMailTemplate()" >Cancel</button>
                             </div>
                        </div>
                    </form>

                </div>
            </div>                       
        </div>
    </div>
</div>
<!-- Container-fluid Ends -->